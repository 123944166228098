import React from 'react';
import Container from 'components/BlocksUI/Container';

const CartCheckoutItem = ({ item }) => {
  return (
    <li className="item-checkout">
      <div className="left-side">
        <div className="left-side__inner">
          <Container>
            <img src={item.img} alt={item.title} />
          </Container>
        </div>
      </div>
      <div className="right-side">
        <div className="item__description main-font main-line-height">
          <div>
            <h4>{item.title}</h4>
            <span className="item__txt">{item.hash.h} {item.hash.option}</span>
          </div>
          <div className="item__price">
            ${item.hash.price}.00
            </div>
        </div>
        <div className="item__controls">
          <div className="counter">x{item.quantity ? item.quantity : 1}</div>
        </div>
      </div>
    </li>
  );
};

export default CartCheckoutItem;
