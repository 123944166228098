import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCloseCrossLeft, setCarouselData, setSidebarState, setPageTopContent } from 'store/actions';
import MenuItems from 'mod/MainMenu/MenuItems';
import AccountMenu from 'pages/Account/modules/AccountMenu';
import { ButtonMain } from 'components/BlocksUI/Buttons/Buttons';
import { BoxDecor } from 'components/Parts/BoxDecor';

const CartCheckoutMB = ({ items }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const content = React.createRef();
  const invoice = useSelector((state) => state.itemsInCart.totalPrice);
  const productsQuantity = useSelector((state) => state.itemsInCart.items);
  const totalQuantity = productsQuantity.reduce((quantity, item) => quantity + (item.quantity ? item.quantity : 1), 0)

  const isSidebar = useSelector((state) => state.sidebarState);

  useEffect(() => {
    async function checkSidebar() {
      if (isSidebar) await dispatch(setSidebarState(false));
      dispatch(setCloseCrossLeft(true));
    }
    dispatch(setPageTopContent(null));
    checkSidebar();
    dispatch(setCarouselData(CartMenu));
    return () => {
      dispatch(setCloseCrossLeft(false));
      const currentPath = history.location.pathname;
      if (currentPath === '/log-in' || currentPath === '/sign-up') dispatch(setCarouselData(AccountMenu));
      else dispatch(setCarouselData(MenuItems));
    }
  }, []);

  return (
    <div className="cart-checkout-mob">
      <div className="cart-checkout-mob__inner"
        ref={content}>
        <div
          className="cart__content">
          <ul className="item-list">
            {items}
            <p className="quantity">Total <span>{totalQuantity}</span> items for</p>
            <p className="invoce">${invoice}.00</p>
            <BoxDecor />
          </ul>
        </div>
        <div className="cart-checkout-mob__footer">
          <p>Thank you for using our marketplace.
         Your order number is <span>#27</span></p>
          <div className="buttons-container">
            <ButtonMain text='Support' />
            <ButtonMain text='My orders' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartCheckoutMB;

const CartMenu = React.forwardRef((props, ref) => (
  <nav>
    <ul className="main-menu__items" ref={ref}>
      <li>payment success</li>
    </ul>
  </nav>
));
