import {
  BtcProfitGraph,
  BtcLineGraph,
  BtcChartGraph,
  BtcColumnGraph,
  BtcYearGraph,
  LtcProfitGraph,
  LtcLineGraph,
  LtcChartGraph,
  LtcColumnGraph,
  LtcYearGraph
} from 'svg/svgCoinDetails';

export const coinsData = [
  {
    title: 'Bitcoin',
    value: 'btc',
    'Price': '9,154.01',
    'Market Cap': '$168,119,730',
    'Trading Volume': '$5,337,362,20',
    '24h Low': '$9,095.31',
    '24h High': '$9,632.38',
    'Market Cap Rank': '#1',
    'All-Time High': '$19,665.39',
    'Since All-Time High': -81,
    'All-Time High Date': 'Dec 16, 2017',
    '1 Hour': 0.010,
    '24 Hours': -2.0,
    '7 Days': 5.6,
    '14 Days': 2.6,
    '30 Days': -8.1,
    '60 Days': 6.5,
    '1 year': -55,
    dynamics: 4.0,
    ratio: 3.3,
    graphicScaleMaxVal: 9280,
    scaleStep: 10,
    graphProfit: BtcProfitGraph,
    graphLine: BtcLineGraph,
    graphChart: BtcChartGraph,
    graphColumn: BtcColumnGraph,
    graphYearDynamics: BtcYearGraph,
    description: 'Bitcoin (BTC) is a cryptocurrency or a form of digital asset. Bitcoin (BTC) price for today is $9,250.09 with a 24-hour trading volume of $31,954,381,605. Price is up 6.3% in the last 24 hours. It has a circulating supply of 17.5 Million coins and a max supply of 21 Million coins. The most active exchange that is trading Bitcoin is LATOKEN. Explore the address and transactions of Bitcoin on block explorers such as blockchair.com, blockchain.info, live.blockcypher.com, bitcoinblockexplorers.com, and btc.tokenview.com. Additional information about Bitcoin coin can be found at http://www.bitcoin.org.',
  },
  {
    title: 'Litecoin',
    value: 'ltc',
    'Price': '43.72',
    'Market Cap': '$2,834,152,11',
    'Trading Volume': '$2,658,251,71',
    '24h Low': '$42.01',
    '24h High': '$46.98',
    'Market Cap Rank': '#7',
    'All-Time High': '$375.29',
    'Since All-Time High': -98,
    'All-Time High Date': 'Dec 19, 2017',
    '1 Hour': 0.410,
    '24 Hours': -4.2,
    '7 Days': -2.0,
    '14 Days': 3.2,
    '30 Days': -8.1,
    '60 Days': 8.4,
    '1 year': 2.5,
    dynamics: -1.19,
    ratio: 4.7,
    graphicScaleMaxVal: 44,
    scaleStep: 0.1,
    graphProfit: LtcProfitGraph,
    graphLine: LtcLineGraph,
    graphChart: LtcChartGraph,
    graphColumn: LtcColumnGraph,
    graphYearDynamics: LtcYearGraph,
    description: 'Litecoin (LTC) is a cryptocurrency or a form of digital asset. Litecoin (LTC) price for today is $43.72 with a 24-hour trading volume of $2,658,251,712. Price is up 2.1% in the last 24 hours. It has a circulating supply of 2.4 Million coins and a max supply of 5 Million coins. The most active exchange that is trading Litecoin is MONERO. Explore the address and transactions of Litecoin on block explorers such as blockchair.com, blockchain.info, live.blockcypher.com, bitcoinblockexplorers.com, and btc.tokenview.com. Additional information about Litecoin coin can be found at http://www.litecoin.org.',
  },
];
