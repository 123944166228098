import React, { useEffect, useRef, useState } from 'react';
import { LogoIcon } from 'svg/svgHeader';
import useInterval from '@use-it/interval';
import CountUp from 'react-countup';

const LoaderModal = ({ closeLoaderModal }) => {
  const [DOMLoaded, setDOMLoaded] = useState(false)
  const [delay, setDelay] = useState(750)
  const [loadingEnd, setLoadingEnd] = useState(0)

  const loadingRef = useRef()
  const loadingContainer = useRef()
  const countRef = useRef()
  const maskRef = useRef()

  window.addEventListener('load', () => setDOMLoaded(true))
  useInterval(() => loadingProcces(), delay)

  useEffect(() => {
    if (DOMLoaded && !delay) {
      setLoadingEnd(100)
      loadingRef.current.style.width = '100%'
      setTimeout(() => closeLoaderModal(), 900)
    }
  }, [DOMLoaded, delay, closeLoaderModal])

  const loadingProcces = () => {
    maskRef.current.style.right = '-15px'
    countRef.current.style.display = 'block'
    const maxWidth = parseFloat(getComputedStyle(loadingContainer.current).width)
    const currentWidth = parseFloat(getComputedStyle(loadingRef.current).width)
    const currentWidthPercent = (currentWidth / maxWidth) * 100
    let incrementWidth = getNextIncrementWidth(maxWidth) + currentWidthPercent

    if (incrementWidth > 100) {
      incrementWidth = 100
      setTimeout(() => closeLoaderModal(), 900)
    }
    if (incrementWidth > 80 && !DOMLoaded) {
      setDelay(null)
    }
    setLoadingEnd(parseInt(incrementWidth))
    loadingRef.current.style.width = `${incrementWidth}%`;
  }

  const getNextIncrementWidth = (maxWidth) => {
    const min = Math.ceil(maxWidth / 4);
    const max = Math.floor(maxWidth / 3);
    const num = Math.floor(Math.random() * (max - min + 1)) + min;
    return (num / maxWidth) * 100
  }

  return (
    <div className="loader-modal">
      <div className="logo">{LogoIcon}</div>
      <div className="loading-container" ref={loadingContainer}>
        <div className="loading-already" ref={loadingRef}>
          <span className="mask" ref={maskRef} />
          <div className="count-up" ref={countRef}>
            <CountUp
              suffix="%"
              duration={1.3}
              delay={0}
              preserveValue={true}
              start={0}
              end={loadingEnd} />
          </div>
        </div>
        <div className="loading-left" />
      </div>
      <p>This MVP is for demo purposes only.</p>
    </div>
  )
}

export default LoaderModal
