import React from 'react';
import { useSelector } from 'react-redux';
import CartMB from './components/CartMB';
import CartDT from './components/CartDT';
import CardCheckoutDT from './components/CartChechoutDT';
import CartCheckoutMB from './components/CartCheckoutMB';
import CartItem from './components/CartItem';
import CartCheckoutItem from './components/CartCheckoutItem';

const Cart = ({ isMobile }) => {
  const cartStore = useSelector((state) => state.itemsInCart);
  const isOpenCheckout = useSelector((state) => state.isOpenCheckout);
  let items = []

  if (isOpenCheckout) {
    items = cartStore.items.map((el, idx) => {
      return <CartCheckoutItem
        key={idx}
        item={el} />
    });
    if (isMobile) {
      return <CartCheckoutMB
        items={items} />
    } else {
      return (
        <CardCheckoutDT
          items={items} />
      )
    }
  }

  items = cartStore.items.map((el, idx) => {
    return <CartItem
      key={idx}
      item={el} />
  });
  if (isMobile) {
    return (
      <CartMB
        items={items} />
    )
  } else {
    return (
      <CartDT
        items={items} />
    )
  }
};

export default Cart;