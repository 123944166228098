export const algorithmsSpecies = [['Blake (2b)', 0], ['SHA256', 0], ['X11', 0], ['Scrypt', 0], ['Quark', 0], ['CryptoNight', 0], ['Qubit', 0], ['Myr-Groestl', 0], ['LBRY', 0], ['Skein', 0], ['Blake256R14', 0], ['Pascal', 0], ['Ethash', 0], ['Znash', 0], ['CNHeavy', 0], ['Equihash', 0], ['Lyra2REv2', 0], ['NeoScrypt', 0], ['TimeTravel10', 0], ['Lyra2z', 0], ['PHI2', 0], ['Xevan', 0], ['CryptoNightV7', 0]];
export const manufacturerSpecies = [['AMD', 0], ['AsRock', 0], ['Bitmain', 0], ['Innosilicon', 0], ['Canaan', 0], ['Baikal', 0], ['Boundary Electric', 0], ['Sunbelt', 0], ['Whatsminer', 0], ['iBeLink', 0], ['Nvidia', 0], ['Pandaminer', 0], ['Ebit', 0], ['Dayun', 0], ['Bitfury', 0]];
export const equipmentSpecies = [['ASIC miners', 0], ['Video Cards', 0], ['GPU Enclosures', 0], ['Used rigs', 0], ['Power Supplies', 0], ['Hardware wallets', 0], ['Motherboards', 0], ['Containers', 0], ['Accessories', 0]];
export const coinsSpecies = [['bch', 0], ['bsd', 0], ['btc', 0], ['pac', 0], ['pirl', 0], ['ppc', 0], ['ryo', 0], ['start', 0], ['tzc', 0], ['ubq', 0], ['vivo', 0], ['vtc', 0], ['xdn', 0], ['xmcc', 0], ['xmr', 0], ['xmy', 0], ['xvg', 0], ['xzc', 0], ['zcl', 0], ['zec', 0], ['zen', 0], ['nlg', 0], ['music', 0], ['mona', 0], ['ltc', 0], ['kmd', 0], ['hush', 0], ['generic', 0], ['gbx', 0], ['ftc', 0], ['flo', 0], ['exp', 0], ['etp', 0], ['eth', 0], ['etc', 0], ['ella', 0], ['dcr', 0], ['crw', 0], ['btx', 0], ['btg', 0], ['btcp', 0]];

export const productsObj = [
  {
    id: 1,
    title: 'Bitfury S9',
    hash: {
      option: 'TH/s',
      value: [{ id: 1, h: 18.5, price: '181' }, { id: 2, h: 11.5, price: '145' }, { id: 3, h: 14.1, price: '136.6'}]
    },
    star: 'full',
    psu: true,
    img: '/data/products/img/1.png',
    release: 'Mar 2019',
    model: 'S9',
    noise: '76db',
    power: 2450,
    algorithm: 'PHI2',
    efficiency: '8.00',
    manufacturer: 'Bitfury',
    equipment: 'Used rigs',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 2,
    title: 'Bitfury DR5 Extra Strong Power',
    hash: {
      option: 'MH/s',
      value: [{ id: 1, h: 18.5, price: '181' }, { id: 2, h: 11.5, price: '145' }, { id: 3, h: 14.1, price: '136.6' }],
    },
    star: 'half',
    psu: false,
    img: '/data/products/img/2.png',
    release: 'Apr 2019',
    model: 'DR5',
    noise: '76db',
    power: 1240,
    algorithm: 'Myr-Groestl',
    efficiency: '7.00',
    manufacturer: 'Bitfury',
    equipment: 'Video Cards',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 3,
    title: 'GPU Enclosure',
    hash: {
      option: 'Hashs/s',
      value: [{ id: 1, h: 22, price: '371' }],
    },
    star: 'half',
    psu: false,
    img: '/data/products/img/3.png',
    release: 'Apr 2019',
    model: 'Enclosure',
    noise: '76db',
    power: 3149,
    algorithm: 'SHA-256',
    efficiency: '17.00',
    manufacturer: 'GPU',
    equipment: 'ASIC miners',
    coins: ['dcr', 'eth', 'lcc'],
  },
  {
    id: 4,
    title: 'Canaan A6',
    hash: {
      option: 'PH/s',
      value: [{ id: 1, h: 89, price: '989' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/4.png',
    release: 'Jun 2017',
    model: 'A6',
    noise: '76db',
    power: 1850,
    algorithm: 'NeoScrypt',
    efficiency: '2.50',
    manufacturer: 'Canaan',
    equipment: 'Video Cards',
    coins: ['dcr', 'eth', 'lcc'],
  },
  {
    id: 5,
    title: 'Sunbelt B3 Pro 4g',
    hash: {
      option: 'KH/s',
      value: [{ id: 1, h: 12, price: '2442' }, { id: 2, h: 16.1, price: '1800' }, { id: 3, h: 19, price: '1989' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/5.png',
    release: 'Jan 2020',
    model: 'B3',
    noise: '76db',
    power: 1100,
    algorithm: 'CNHeavy',
    efficiency: '7.10',
    manufacturer: 'Sunbelt',
    equipment: 'Video Cards',
    coins: ['dcr',],
  },
  {
    id: 6,
    title: 'Nvidia Pro',
    hash: {
      option: 'Ksol/s',
      value: [{ id: 1, h: 1.9, price: '376' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/6.png',
    release: 'Jan 2020',
    model: 'Pro',
    noise: '76db',
    power: 1200,
    algorithm: 'Blake (2b)',
    efficiency: '10.70',
    manufacturer: 'Nvidia',
    equipment: 'Motherboards',
    coins: ['music',],
  },
  {
    id: 7,
    title: 'Bitmain Enclosure',
    hash: {
      option: 'GH/s',
      value: [{ id: 1, h: 1.2, price: '672' }, { id: 2, h: 5.0, price: '840' }, { id: 3, h: 1.9, price: '376' }, { id: 4, h: 2.3, price: '290' },],
    },
    star: 'full',
    psu: false,
    img: '/data/products/img/7.png',
    release: 'Dec 2018',
    model: 'Enclosure',
    noise: '76db',
    power: 1350,
    algorithm: 'X11',
    efficiency: '10.70',
    manufacturer: 'Bitmain',
    equipment: 'Motherboards',
    coins: ['dcr', 'eth', 'lcc'],
  },
  {
    id: 8,
    title: 'AsRock Roll',
    hash: {
      option: 'H/s',
      value: [{ id: 1, h: 913, price: '5376' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/8.png',
    release: 'Feb 2016',
    model: 'Roll',
    noise: '76db',
    power: 1950,
    algorithm: 'NeoScrypt',
    efficiency: '4.70',
    manufacturer: 'AsRock',
    equipment: 'ASIC miners',
    coins: ['bsd', 'btc', 'pac', 'pirl', 'ppc', 'ryo', 'start', 'tzc', 'ubq', 'vivo', 'vtc', 'xdn', 'xmcc', 'xmr', 'xmy', 'xvg', 'xzc', 'zcl', 'zec', 'zen', 'nlg', 'music', 'mona', 'ltc', 'kmd', 'hush', 'generic', 'gbx', 'ftc', 'flo', 'exp', 'etp', 'eth', 'etc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 9,
    title: 'Baikal Z9 Mini',
    hash: {
      option: 'MH/s',
      value: [{ id: 1, h: 230, price: '2209' }],
    },
    star: 'half',
    psu: false,
    img: '/data/products/img/9.png',
    release: 'Feb 2020',
    model: 'Z9',
    noise: '76db',
    power: 1950,
    algorithm: 'TimeTravel10',
    efficiency: '11.00',
    manufacturer: 'Baikal',
    equipment: 'ASIC miners',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 10,
    title: 'Canaan T3',
    hash: {
      option: 'Hashs/s',
      value: [{ id: 1, h: 19.5, price: '103' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/10.png',
    release: 'Apr 2019',
    model: 'T3',
    noise: '76db',
    power: 1110,
    algorithm: 'CryptoNightV7',
    efficiency: '7.90',
    manufacturer: 'Canaan',
    equipment: 'Motherboards',
    coins: ['bsd', 'btc', 'pac', 'pirl', 'ppc', 'ryo', 'start', 'tzc', 'ubq', 'vivo', 'vtc', 'xdn', 'xmcc', 'xmr', 'xmy', 'xvg',],
  },
  {
    id: 11,
    title: 'CryptoNight A11',
    hash: {
      option: 'GH/s',
      value: [{ id: 1, h: 1.23, price: '3240' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/11.png',
    release: 'Aug 2016',
    model: 'A11',
    noise: '76db',
    power: 1550,
    algorithm: 'CryptoNight',
    efficiency: '23.70',
    manufacturer: 'Ebit',
    equipment: 'ASIC miners',
    coins: ['xzc', 'zcl', 'zec', 'zen', 'ltc', 'kmd', 'hush', 'generic', 'gbx', 'ftc', 'exp', 'etp', 'eth', 'etc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 12,
    title: 'Ebit Zig Z1+',
    hash: {
      option: 'PH/s',
      value: [{ id: 1, h: 7.2, price: '1242' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/12.png',
    release: 'Okt 2020',
    model: 'Zig Z1+',
    noise: '76db',
    power: 1150,
    algorithm: 'LBRY',
    efficiency: '12.00',
    manufacturer: 'Ebit',
    equipment: 'Video Cards',
    coins: ['dcr', 'eth', 'lcc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 13,
    title: 'Innosilicon S5',
    hash: {
      option: 'TH/s',
      value: [{ id: 1, h: 55, price: '535' }, { id: 2, h: 59, price: '700' }],
    },
    star: 'half',
    psu: true,
    img: '/data/products/img/14.png',
    release: 'Aug 2019',
    model: 'S5',
    noise: '76db',
    power: 950,
    algorithm: 'Lyra2REv2',
    efficiency: '9.00',
    manufacturer: 'Innosilicon',
    equipment: 'Used rigs',
    coins: ['btc', 'eth', 'etc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 14,
    title: 'Bitmain Enclosure',
    hash: {
      option: 'Ksol/s',
      value: [{ id: 1, h: 5, price: '1535' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/15.png',
    release: 'Feb 2016',
    model: 'Enclosure',
    noise: '76db',
    power: 1200,
    algorithm: 'Quark',
    efficiency: '10.00',
    manufacturer: 'Bitmain',
    equipment: 'GPU Enclosures',
    coins: ['eth', 'lcc', 'tzc', 'ubq', 'vivo', 'vtc', 'xdn', 'xmcc', 'xmr', 'xmy', 'xvg', 'xzc', 'zcl', 'zec', 'zen', 'nlg', 'music', 'mona', 'ltc',],
  },
  {
    id: 15,
    title: 'AMD S9',
    hash: {
      option: 'TH/s',
      value: [{ id: 1, h: 18.5, price: '181' }, { id: 2, h: 11.5, price: '145' }, { id: 3, h: 14.1, price: '136.6' }],
    },
    star: 'full',
    psu: true,
    img: '/data/products/img/1.png',
    release: 'Mar 2019',
    model: 'S9',
    noise: '76db',
    power: 2450,
    algorithm: 'SHA256',
    efficiency: '8.00',
    manufacturer: 'AMD',
    equipment: 'ASIC miners',
    coins: ['eth', 'lcc'],
  },
  {
    id: 16,
    title: 'AsRock DR5 Extra Power',
    hash: {
      option: 'MH/s',
      value: [{ id: 1, h: 23, price: '1045' }, { id: 2, h: 41, price: '1800' }, { id: 3, h: 39, price: '1560' }, { id: 4, h: 27, price: '1153' }, { id: 5, h: 33, price: '977' }, ],
    },
    star: 'half',
    psu: false,
    img: '/data/products/img/2.png',
    release: 'Apr 2019',
    model: 'DR5',
    noise: '76db',
    power: 1240,
    algorithm: 'SHA256',
    efficiency: '7.00',
    manufacturer: 'AsRock',
    equipment: 'Video Cards',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 17,
    title: 'Bitmain HT5-U',
    hash: {
      option: 'Hashs/s',
      value: [{ id: 1, h: 22, price: '371' }],
    },
    star: 'half',
    psu: false,
    img: '/data/products/img/3.png',
    release: 'Apr 2019',
    model: 'Enclosure',
    noise: '76db',
    power: 3149,
    algorithm: 'Equihash',
    efficiency: '17.00',
    manufacturer: 'Bitmain',
    equipment: 'Accessories',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 18,
    title: 'Canaan A6s',
    hash: {
      option: 'PH/s',
      value: [{ id: 1, h: 89, price: '491' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/4.png',
    release: 'Jun 2017',
    model: 'A6s',
    noise: '76db',
    power: 1850,
    algorithm: 'X11',
    efficiency: '2.50',
    manufacturer: 'Canaan',
    equipment: 'Video Cards',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 19,
    title: 'AMD B3',
    hash: {
      option: 'KH/s',
      value: [{ id: 1, h: 12, price: '2442' }, { id: 2, h: 16.1, price: '2315' }, { id: 3, h: 19, price: '1989' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/5.png',
    release: 'Jan 2020',
    model: 'B3',
    noise: '76db',
    power: 1100,
    algorithm: 'Ethash',
    efficiency: '7.10',
    manufacturer: 'AMD',
    equipment: 'Video Cards',
    coins: ['btc', 'dcr',],
  },
  {
    id: 20,
    title: 'Dayun Enio',
    hash: {
      option: 'Ksol/s',
      value: [{ id: 1, h: 1.9, price: '376' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/6.png',
    release: 'Jan 2020',
    model: 'Enio',
    noise: '76db',
    power: 1200,
    algorithm: 'Lyra2REv2',
    efficiency: '10.70',
    manufacturer: 'Dayun',
    equipment: 'Motherboards',
    coins: ['btc', 'music',],
  },
  {
    id: 21,
    title: 'Ebit BBN-3',
    hash: {
      option: 'GH/s',
      value: [{ id: 1, h: 1.2, price: '672' }, { id: 2, h: 5.0, price: '840' }, { id: 3, h: 1.9, price: '376' }, { id: 4, h: 2.3, price: '290' },],
    },
    star: 'full',
    psu: false,
    img: '/data/products/img/7.png',
    release: 'Dec 2018',
    model: 'BBN-3',
    noise: '76db',
    power: 1350,
    algorithm: 'X11',
    efficiency: '10.70',
    manufacturer: 'Ebit',
    equipment: 'Motherboards',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 22,
    title: 'AsRock X3',
    hash: {
      option: 'H/s',
      value: [{ id: 1, h: 913, price: '5376' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/8.png',
    release: 'Feb 2016',
    model: 'X3',
    noise: '76db',
    power: 1950,
    algorithm: 'Quark',
    efficiency: '4.70',
    manufacturer: 'AsRock',
    equipment: 'ASIC miners',
    coins: ['bch', 'bsd', 'btc', 'pac', 'pirl', 'ppc', 'ryo', 'start', 'tzc', 'ubq', 'vivo', 'vtc', 'xdn', 'xmcc', 'xmr', 'xmy', 'xvg', 'xzc', 'zcl', 'zec', 'zen', 'nlg', 'music', 'mona', 'ltc', 'kmd', 'hush', 'generic', 'gbx', 'ftc', 'flo', 'exp', 'etp', 'eth', 'etc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 23,
    title: 'Baikal 0001',
    hash: {
      option: 'MH/s',
      value: [{ id: 1, h: 230, price: '2209' }],
    },
    star: 'half',
    psu: false,
    img: '/data/products/img/9.png',
    release: 'Feb 2020',
    model: '0001',
    noise: '76db',
    power: 1950,
    algorithm: 'Pascal',
    efficiency: '11.00',
    manufacturer: 'Baikal',
    equipment: 'ASIC miners',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 24,
    title: 'Canaan +++',
    hash: {
      option: 'Hashs/s',
      value: [{ id: 1, h: 19.5, price: '103' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/10.png',
    release: 'Apr 2019',
    model: '+++',
    noise: '76db',
    power: 1110,
    algorithm: 'CryptoNightV7',
    efficiency: '7.90',
    manufacturer: 'Canaan',
    equipment: 'Motherboards',
    coins: ['bch', 'bsd', 'btc', 'pac', 'pirl', 'ppc', 'ryo', 'start', 'tzc', 'ubq', 'vivo', 'vtc', 'xdn', 'xmcc', 'xmr', 'xmy', 'xvg',],
  },
  {
    id: 25,
    title: 'AsRock A11',
    hash: {
      option: 'GH/s',
      value: [{ id: 1, h: 1.23, price: '3240' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/11.png',
    release: 'Aug 2016',
    model: 'A11',
    noise: '76db',
    power: 1550,
    algorithm: 'PHI2',
    efficiency: '23.70',
    manufacturer: 'AsRock',
    equipment: 'Containers',
    coins: ['zec', 'zen', 'nlg', 'music', 'mona', 'ltc', 'generic', 'gbx', 'ftc', 'flo', 'exp', 'etp', 'eth', 'etc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 26,
    title: 'AMD Zig Z1+',
    hash: {
      option: 'PH/s',
      value: [{ id: 1, h: 7.2, price: '1242' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/12.png',
    release: 'Okt 2020',
    model: 'Zig Z1+',
    noise: '76db',
    power: 1150,
    algorithm: 'CryptoNight',
    efficiency: '12.00',
    manufacturer: 'AMD',
    equipment: 'Used rigs',
    coins: ['dcr', 'eth', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 27,
    title: 'Innosilicon S5',
    hash: {
      option: 'TH/s',
      value: [{ id: 1, h: 55, price: '590' }, { id: 2, h: 59, price: '725' }],
    },
    star: 'half',
    psu: true,
    img: '/data/products/img/14.png',
    release: 'Aug 2019',
    model: 'S5',
    noise: '76db',
    power: 950,
    algorithm: 'Qubit',
    efficiency: '9.00',
    manufacturer: 'Innosilicon',
    equipment: 'Used rigs',
    coins: ['btc', 'eth', 'etc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 28,
    title: 'Bitmain Closure',
    hash: {
      option: 'Ksol/s',
      value: [{ id: 1, h: 5, price: '1535' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/15.png',
    release: 'Feb 2016',
    model: 'Closure',
    noise: '76db',
    power: 1200,
    algorithm: 'Quark',
    efficiency: '10.00',
    manufacturer: 'Bitmain',
    equipment: 'GPU Enclosures',
    coins: ['btc', 'dcr', 'eth', 'lcc', 'tzc', 'ubq', 'vivo', 'vtc', 'xdn', 'xmcc', 'xmr', 'xmy', 'xvg', 'xzc', 'zcl', 'zec', 'zen', 'nlg', 'music', 'mona', 'ltc',],
  },
  {
    id: 29,
    title: 'AMD Loop00',
    hash: {
      option: 'TH/s',
      value: [{ id: 1, h: 18.5, price: '181' }, { id: 2, h: 11.5, price: '145' }, { id: 3, h: 14.1, price: '136.6' }],
    },
    star: 'full',
    psu: true,
    img: '/data/products/img/1.png',
    release: 'Mar 2019',
    model: 'Loop00',
    noise: '76db',
    power: 2450,
    algorithm: 'Blake (2b)',
    efficiency: '8.00',
    manufacturer: 'AMD',
    equipment: 'ASIC miners',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 30,
    title: 'AsRock Power',
    hash: {
      option: 'MH/s',
      value: [{ id: 1, h: 23, price: '1045' }, { id: 2, h: 41, price: '1800' }, { id: 3, h: 39, price: '1560' }, { id: 4, h: 27, price: '1153' }, { id: 5, h: 33, price: '977' }, { id: 6, h: 35, price: '1500' }, { id: 7, h: 31, price: '775' }, { id: 8, h: 41, price: '1960' }, { id: 9, h: 30, price: '1010' }],
    },
    star: 'half',
    psu: false,
    img: '/data/products/img/2.png',
    release: 'Apr 2019',
    model: 'Power',
    noise: '76db',
    power: 1240,
    algorithm: 'Skein',
    efficiency: '7.00',
    manufacturer: 'AsRock',
    equipment: 'Video Cards',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 31,
    title: 'Nvidia AA+1',
    hash: {
      option: 'Hashs/s',
      value: [{ id: 1, h: 22, price: '371' }],
    },
    star: 'half',
    psu: false,
    img: '/data/products/img/3.png',
    release: 'Apr 2019',
    model: 'AA+1',
    noise: '76db',
    power: 3149,
    algorithm: 'NeoScrypt',
    efficiency: '17.00',
    manufacturer: 'Nvidia',
    equipment: 'ASIC miners',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 32,
    title: 'Canaan High',
    hash: {
      option: 'PH/s',
      value: [{ id: 1, h: 89, price: '989' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/4.png',
    release: 'Jun 2017',
    model: 'High',
    noise: '76db',
    power: 1850,
    algorithm: 'X11',
    efficiency: '2.50',
    manufacturer: 'Canaan',
    equipment: 'Video Cards',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 33,
    title: 'Pandaminer Pro',
    hash: {
      option: 'KH/s',
      value: [{ id: 1, h: 12, price: '2442' }, { id: 2, h: 16.1, price: '2315' }, { id: 3, h: 19, price: '1989' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/5.png',
    release: 'Jan 2020',
    model: 'Pro',
    noise: '76db',
    power: 1100,
    algorithm: 'Pascal',
    efficiency: '7.10',
    manufacturer: 'Pandaminer',
    equipment: 'Video Cards',
    coins: ['btc', 'dcr',],
  },
  {
    id: 34,
    title: 'Whatsminer UI',
    hash: {
      option: 'Ksol/s',
      value: [{ id: 1, h: 1.9, price: '376' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/6.png',
    release: 'Jan 2020',
    model: 'UI',
    noise: '76db',
    power: 1200,
    algorithm: 'Blake (2b)',
    efficiency: '10.70',
    manufacturer: 'Whatsminer',
    equipment: 'Motherboards',
    coins: ['btc', 'music',],
  },
  {
    id: 35,
    title: 'iBeLink USA',
    hash: {
      option: 'GH/s',
      value: [{ id: 1, h: 1.2, price: '672' }, { id: 2, h: 5.0, price: '840' }, { id: 3, h: 1.9, price: '376' }, { id: 4, h: 2.3, price: '290' },],
    },
    star: 'full',
    psu: false,
    img: '/data/products/img/7.png',
    release: 'Dec 2018',
    model: 'USA',
    noise: '76db',
    power: 1350,
    algorithm: 'X11',
    efficiency: '10.70',
    manufacturer: 'iBeLink',
    equipment: 'Motherboards',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 36,
    title: 'AsRock Eco W2',
    hash: {
      option: 'H/s',
      value: [{ id: 1, h: 913, price: '5376' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/8.png',
    release: 'Feb 2016',
    model: 'Eco W2',
    noise: '76db',
    power: 1950,
    algorithm: 'Quark',
    efficiency: '4.70',
    manufacturer: 'AsRock',
    equipment: 'ASIC miners',
    coins: ['bch', 'bsd', 'btc', 'pac', 'pirl', 'ppc', 'ryo', 'start', 'tzc', 'ubq', 'vivo', 'vtc', 'xdn', 'xmcc', 'xmr', 'xmy', 'xvg', 'xzc', 'zcl', 'zec', 'zen', 'nlg', 'music', 'mona', 'ltc', 'kmd', 'hush', 'generic', 'gbx', 'ftc', 'flo', 'exp', 'etp', 'eth', 'etc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 37,
    title: 'Baikal California',
    hash: {
      option: 'MH/s',
      value: [{ id: 1, h: 230, price: '2209' }],
    },
    star: 'half',
    psu: false,
    img: '/data/products/img/9.png',
    release: 'Feb 2020',
    model: 'California',
    noise: '76db',
    power: 1950,
    algorithm: 'Quark',
    efficiency: '11.00',
    manufacturer: 'Baikal',
    equipment: 'Accessories',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 38,
    title: 'Boundary Electric GHu',
    hash: {
      option: 'Hashs/s',
      value: [{ id: 1, h: 19.5, price: '103' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/10.png',
    release: 'Apr 2019',
    model: 'GHu',
    noise: '76db',
    power: 1110,
    algorithm: 'CryptoNightV7',
    efficiency: '7.90',
    manufacturer: 'Boundary Electric',
    equipment: 'Motherboards',
    coins: ['bch', 'bsd', 'btc', 'pac', 'pirl', 'ppc', 'ryo', 'start', 'tzc', 'ubq', 'vivo', 'vtc', 'xdn', 'xmcc', 'xmr', 'xmy', 'xvg',],
  },
  {
    id: 39,
    title: 'Whatsminer A11',
    hash: {
      option: 'GH/s',
      value: [{ id: 1, h: 1.23, price: '3240' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/11.png',
    release: 'Aug 2016',
    model: 'A11',
    noise: '76db',
    power: 1550,
    algorithm: 'NeoScrypt',
    efficiency: '23.70',
    manufacturer: 'Whatsminer',
    equipment: 'ASIC miners',
    coins: ['xzc', 'zcl', 'zec', 'zen', 'nlg', 'music', 'mona', 'ltc', 'kmd', 'hush', 'generic', 'gbx', 'ftc', 'flo', 'exp', 'etp', 'eth', 'etc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 40,
    title: 'Baikal Lake',
    hash: {
      option: 'PH/s',
      value: [{ id: 1, h: 7.2, price: '1242' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/12.png',
    release: 'Okt 2020',
    model: 'Lake',
    noise: '76db',
    power: 1150,
    algorithm: 'CryptoNight',
    efficiency: '12.00',
    manufacturer: 'Baikal',
    equipment: 'Video Cards',
    coins: ['btc', 'dcr', 'eth', 'lcc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 41,
    title: 'Innosilicon S5r',
    hash: {
      option: 'TH/s',
      value: [{ id: 1, h: 55, price: '611' }, { id: 2, h: 59, price: '802' }],
    },
    star: 'half',
    psu: true,
    img: '/data/products/img/14.png',
    release: 'Aug 2019',
    model: 'S5r',
    noise: '76db',
    power: 950,
    algorithm: 'Qubit',
    efficiency: '9.00',
    manufacturer: 'Innosilicon',
    equipment: 'Used rigs',
    coins: ['btc', 'eth', 'etc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 42,
    title: 'Bitmain 76db',
    hash: {
      option: 'Ksol/s',
      value: [{ id: 1, h: 5, price: '1535' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/15.png',
    release: 'Feb 2016',
    model: '76db',
    noise: '76db',
    power: 1200,
    algorithm: 'Quark',
    efficiency: '10.00',
    manufacturer: 'Bitmain',
    equipment: 'GPU Enclosures',
    coins: ['btc', 'dcr', 'eth', 'lcc', 'tzc', 'ubq', 'vivo', 'vtc', 'xdn', 'xmcc', 'xmr', 'xmy', 'xvg', 'xzc', 'zcl', 'zec', 'zen', 'nlg', 'music', 'mona', 'ltc',],
  },
  {
    id: 43,
    title: 'AMD mdn33',
    hash: {
      option: 'TH/s',
      value: [{ id: 1, h: 18.5, price: '181' }, { id: 2, h: 11.5, price: '145' }, { id: 3, h: 14.1, price: '136.6' }],
    },
    star: 'full',
    psu: true,
    img: '/data/products/img/1.png',
    release: 'Mar 2019',
    model: 'mdn33',
    noise: '76db',
    power: 2450,
    algorithm: 'Blake (2b)',
    efficiency: '8.00',
    manufacturer: 'AMD',
    equipment: 'ASIC miners',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 44,
    title: 'Bitfury 5-1-1-5',
    hash: {
      option: 'MH/s',
      value: [{ id: 1, h: 23, price: '1045' }, { id: 2, h: 41, price: '1800' }, { id: 3, h: 39, price: '1560' }, { id: 4, h: 27, price: '1153' }, { id: 5, h: 33, price: '977' }, { id: 6, h: 35, price: '1500' }, { id: 7, h: 31, price: '775' }, { id: 8, h: 41, price: '1960' }, { id: 9, h: 30, price: '1010' }],
    },
    star: 'half',
    psu: false,
    img: '/data/products/img/2.png',
    release: 'Apr 2019',
    model: '5-1-1-5',
    noise: '76db',
    power: 1240,
    algorithm: 'SHA256',
    efficiency: '7.00',
    manufacturer: 'Bitfury',
    equipment: 'Video Cards',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 45,
    title: 'Whatsminer 3149',
    hash: {
      option: 'Hashs/s',
      value: [{ id: 1, h: 22, price: '371' }],
    },
    star: 'half',
    psu: false,
    img: '/data/products/img/3.png',
    release: 'Apr 2019',
    model: '3149',
    noise: '76db',
    power: 3149,
    algorithm: 'SHA-256',
    efficiency: '17.00',
    manufacturer: 'Whatsminer',
    equipment: 'ASIC miners',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 46,
    title: 'Canaan Extra',
    hash: {
      option: 'PH/s',
      value: [{ id: 1, h: 89, price: '1007' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/4.png',
    release: 'Jun 2017',
    model: 'Extra',
    noise: '76db',
    power: 1850,
    algorithm: 'Scrypt',
    efficiency: '2.50',
    manufacturer: 'Canaan',
    equipment: 'Video Cards',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 47,
    title: 'AMD 5g',
    hash: {
      option: 'KH/s',
      value: [{ id: 1, h: 12, price: '2442' }, { id: 2, h: 16.1, price: '2315' }, { id: 3, h: 19, price: '1989' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/5.png',
    release: 'Jan 2020',
    model: '5g',
    noise: '76db',
    power: 1100,
    algorithm: 'Scrypt',
    efficiency: '7.10',
    manufacturer: 'AMD',
    equipment: 'Video Cards',
    coins: ['btc', 'dcr',],
  },
  {
    id: 48,
    title: 'iBeLink 01',
    hash: {
      option: 'Ksol/s',
      value: [{ id: 1, h: 1.9, price: '376' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/6.png',
    release: 'Jan 2020',
    model: '01',
    noise: '47db',
    power: 1200,
    algorithm: 'Blake (2b)',
    efficiency: '10.70',
    manufacturer: 'iBeLink',
    equipment: 'Motherboards',
    coins: ['btc', 'music',],
  },
  {
    id: 49,
    title: 'Dayun December',
    hash: {
      option: 'GH/s',
      value: [{ id: 1, h: 1.2, price: '672' }, { id: 2, h: 5.0, price: '840' }, { id: 3, h: 1.9, price: '376' }, { id: 4, h: 2.3, price: '290' },],
    },
    star: 'full',
    psu: false,
    img: '/data/products/img/7.png',
    release: 'Dec 2018',
    model: 'December',
    noise: '76db',
    power: 1350,
    algorithm: 'X11',
    efficiency: '10.70',
    manufacturer: 'Dayun',
    equipment: 'Motherboards',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 50,
    title: 'AsRock 2020',
    hash: {
      option: 'H/s',
      value: [{ id: 1, h: 913, price: '5376' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/8.png',
    release: 'Feb 2016',
    model: '2020',
    noise: '76db',
    power: 1950,
    algorithm: 'Quark',
    efficiency: '4.70',
    manufacturer: 'AsRock',
    equipment: 'ASIC miners',
    coins: ['bch', 'bsd', 'btc', 'pac', 'pirl', 'ppc', 'ryo', 'start', 'tzc', 'ubq', 'vivo', 'vtc', 'xdn', 'xmcc', 'xmr', 'xmy', 'xvg', 'xzc', 'zcl', 'zec', 'zen', 'nlg', 'music', 'mona', 'ltc', 'kmd', 'hush', 'generic', 'gbx', 'ftc', 'flo', 'exp', 'etp', 'eth', 'etc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 51,
    title: 'Baikal WW',
    hash: {
      option: 'MH/s',
      value: [{ id: 1, h: 230, price: '2209' }],
    },
    star: 'half',
    psu: false,
    img: '/data/products/img/9.png',
    release: 'Feb 2020',
    model: 'WW',
    noise: '76db',
    power: 1950,
    algorithm: 'Quark',
    efficiency: '11.00',
    manufacturer: 'Baikal',
    equipment: 'ASIC miners',
    coins: ['btc', 'dcr', 'eth', 'lcc'],
  },
  {
    id: 52,
    title: 'Canaan AN-2',
    hash: {
      option: 'Hashs/s',
      value: [{ id: 1, h: 19.5, price: '103' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/10.png',
    release: 'Apr 2019',
    model: 'AN-2',
    noise: '76db',
    power: 1110,
    algorithm: 'CryptoNightV7',
    efficiency: '7.90',
    manufacturer: 'Canaan',
    equipment: 'Motherboards',
    coins: ['bch', 'bsd', 'btc', 'pac', 'pirl', 'ppc', 'ryo', 'start', 'tzc', 'ubq', 'vivo', 'vtc', 'xdn', 'xmcc', 'xmr', 'xmy', 'xvg',],
  },
  {
    id: 53,
    title: 'Boundary Electric Power',
    hash: {
      option: 'GH/s',
      value: [{ id: 1, h: 1.23, price: '3240' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/11.png',
    release: 'Aug 2016',
    model: 'Power',
    noise: '76db',
    power: 1550,
    algorithm: 'CryptoNight',
    efficiency: '23.70',
    manufacturer: 'Boundary Electric',
    equipment: 'Containers',
    coins: ['xzc', 'zcl', 'zec', 'zen', 'nlg', 'music', 'mona', 'ltc', 'kmd', 'hush', 'generic', 'gbx', 'ftc', 'flo', 'exp', 'etp', 'eth', 'etc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 54,
    title: 'iBeLink Zero',
    hash: {
      option: 'PH/s',
      value: [{ id: 1, h: 7.2, price: '10242' }],
    },
    star: false,
    psu: false,
    img: '/data/products/img/12.png',
    release: 'Okt 2020',
    model: 'Zero',
    noise: '176db',
    power: 2150,
    algorithm: 'CryptoNight',
    efficiency: '12.00',
    manufacturer: 'iBeLink',
    equipment: 'Containers',
    coins: ['btc', 'dcr', 'eth', 'lcc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 55,
    title: 'Innosilicon S5-00',
    hash: {
      option: 'TH/s',
      value: [{ id: 1, h: 55, price: '536' }, { id: 2, h: 59, price: '700' }],
    },
    star: 'half',
    psu: true,
    img: '/data/products/img/14.png',
    release: 'Aug 2019',
    model: 'S5-00',
    noise: '76db',
    power: 950,
    algorithm: 'Qubit',
    efficiency: '9.00',
    manufacturer: 'Innosilicon',
    equipment: 'Used rigs',
    coins: ['btc', 'eth', 'etc', 'ella', 'dcr', 'crw', 'btx', 'btg', 'btcp',],
  },
  {
    id: 56,
    title: 'Pandaminer Grizzly',
    hash: {
      option: 'Ksol/s',
      value: [{ id: 1, h: 5, price: '1535' }],
    },
    star: false,
    psu: true,
    img: '/data/products/img/15.png',
    release: 'Feb 2016',
    model: 'Grizzly',
    noise: '76db',
    power: 1200,
    algorithm: 'Quark',
    efficiency: '10.00',
    manufacturer: 'Pandaminer',
    equipment: 'GPU Enclosures',
    coins: ['btc', 'dcr', 'eth', 'lcc', 'tzc', 'ubq', 'vivo', 'vtc', 'xdn', 'xmcc', 'xmr', 'xmy', 'xvg', 'xzc', 'zcl', 'zec', 'zen', 'nlg', 'music', 'mona', 'ltc',],
  },
];
