import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCartState, openCheckout, clearCart } from 'store/actions';
import { Diamond } from 'components/Parts/Parts';
import { CloseCross } from 'svg/svg';
import { ButtonMain } from 'components/BlocksUI/Buttons/Buttons';
import { BoxDecor } from 'components/Parts/BoxDecor';

const CartCheckout = ({ items }) => {
  const dispatch = useDispatch();
  const invoice = useSelector((state) => state.itemsInCart.totalPrice);
  const productsQuantity = useSelector((state) => state.itemsInCart.items);
  const totalQuantity = productsQuantity.reduce((quantity, item) => quantity + (item.quantity ? item.quantity : 1), 0)

  let content = (
    <>
      <div className="cart-checkout__body">
        <div
          className="cart__content">
          <ul className="item-list">
            {items}
            <p className="quantity">Total <span>{totalQuantity}</span> items for</p>
            <p className="invoce">${invoice}.00</p>
            <BoxDecor />
          </ul>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    window.addEventListener('click', closeCartHandler);

    return () => {
      window.removeEventListener('click', closeCartHandler);
    };
  }, []);

  const closeCartHandler = (e) => {
    const target = e.target
    if (target.closest('.close-cross') || !target.closest('.cart-checkout')) {
      dispatch(setCartState(false))
      dispatch(openCheckout(false))
      dispatch(clearCart())
    }
  }

  return (
    <div className="cart-checkout">
      <div className="cart-checkout__inner">
        <div className="cart-checkout__header">
          <h4>Payment success</h4>
          <div
            className="close-cross">
            {CloseCross}
          </div>
        </div>
        {content}

      </div>
      <div className="cart-checkout__footer">
        <p>Thank you for using our marketplace.
         Your order number is <span>#27</span></p>
        <div className="buttons-container">
          <ButtonMain text='Support' />
          <ButtonMain text='My orders' />
        </div>
      </div>
      <Diamond />
    </div>
  );
};

export default CartCheckout;
