import React from 'react';
import ReactDOM from 'react-dom';

const SelectModal = (props) => ReactDOM.createPortal(
    <div
        className={'select-modal'}>
        {props.children}
    </div>
    , document.body)

export default SelectModal;
