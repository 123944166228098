import React from 'react';
import FiltersBlock from 'mod/Filters/FiltersBlock';

export const Filters = () => {
  return (
    <>
    <h2>filters</h2>
    <FiltersBlock />
    </>
  );
};