import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setDeviceType } from 'store/actions';
import Layout from 'layouts';
import Home from 'pages/Home';
import ProductDetails from 'pages/ProductDetails';
import SignUp from 'pages/Account/SignUp';
import LogIn from 'pages/Account/LogIn';
import Terms from 'pages/Account/Terms';
import LoaderModal from './components/LoaderModal/LoaderModal';
import PageNotFound from './pages/PageNotFound/PageNotFound';

const App = () => {
  const dispatch = useDispatch();
  const openLoader = localStorage.getItem('openLoader')
  const [loaderModalOpen, setLoaderModalOpen] = useState(openLoader ? false : true)

  const closeLoaderModal = () => {
    setLoaderModalOpen(false)
    localStorage.setItem('openLoader', false)
  }

  let isUnserviced = false;
  const screenWidth = document.documentElement.clientWidth;
  if (screenWidth >= 769 && screenWidth < 1023) {
    isUnserviced = true;
  };
  let [unserviced, setUnserviced] = useState(isUnserviced);

  let isMobile = (document.documentElement.clientWidth < 769) ? true : false;
  if (isMobile) dispatch(setDeviceType(true));
  else dispatch(setDeviceType(false));

  window.addEventListener('resize', () => {
    setTimeout(() => {
      const screenWidth = document.documentElement.clientWidth;
      const isDesctop = (screenWidth < 769) ? false : true;

      if (isDesctop === isMobile) {
        if (isMobile) {
          dispatch(setDeviceType(false));
          isMobile = false;
        } else {
          dispatch(setDeviceType(true));
          isMobile = true;
        }
      }

      if (screenWidth >= 769 && screenWidth < 1023) {
        if (!unserviced) setUnserviced(true);
      } else {
        if (unserviced) setUnserviced(false);
      }
    }, 100);
  });

  if (unserviced) {
    return (
      <div className="plug"><p>This resolution is not supported.</p></div>
    )
  } else {
    return (
      <Layout isMobile={isMobile}>
        {
          loaderModalOpen ?
            <LoaderModal closeLoaderModal={closeLoaderModal} />
            : null
        }
        <Switch>
          <Route exact path="/"><Home /></Route>
          <Route exact path="/product-details/:item/:id"><ProductDetails /></Route>
          <Route exact path="/sign-up"><SignUp /></Route>
          <Route exact path="/log-in"><LogIn /></Route>
          <Route exact path="/terms"><Terms /></Route>
          <Route exact path="/sell"><Home /></Route>
          <Route exact path="/host"><Home /></Route>
          <Route exact path="/about"><Home /></Route>
          <Route exact path="/support"><Home /></Route>
          <Route><PageNotFound /></Route>
        </Switch>
      </Layout>
    );
  }
};

export default App;
