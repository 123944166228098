import React, { useState } from 'react';
import { productsProcessing } from 'components/Products/renderProducts';
import TitleBlock from 'components/BlocksUI/TitleBlock';
import Select from 'components/Select';
import { ButtonMain } from 'components/BlocksUI/Buttons/Buttons';
import { BoxDecor } from 'components/Parts/BoxDecor';
import { addToCart } from 'mod/Cart/logic';
import {
  ProductStar,
  ProductHalfStar,
  ToggleArrow,
  ShareIcon
} from 'svg/svg';
import SelectModal from '../../../../components/Select/SelectModal/SelectModal';

const OnSaleMB = ({ item}) => {

  const itemProcessed = productsProcessing(item);
  const [activeItemId, setActiveItemId] = useState();
  const [hash, setHash] = useState(itemProcessed.hashArr[0].h);
  const [price, setPrice] = useState(item.hash.value[0].price);
  const [isModal, setIsModal] = useState(false);

  const closeModal = () => {
    setIsModal(false)
    document.body.classList.remove('body-block')
  }

  const onHashListClick = (el) => {
    setHash(el.h)
    setPrice(el.price);
    setIsModal(false)
    setActiveItemId(el.id)
    document.body.classList.remove('body-block')
  }

  const selectDiv = (
    <div className="select-wrapper">
      <Select
        displayHash={hash}
        closeModal={closeModal}
        isModal={isModal}
        item={item}
        hashArr={itemProcessed.hashArr}
        hashOpt={item.hash.option}
        hashClick={onHashListClick}
        activeItemId={activeItemId}
      />
    </div>
  )

  const openModal = () => {
    if (item.hash.value.length <= 1) return;
    document.body.classList.add('body-block')
    setIsModal(true)
  }

  return (
    <>
      <div className="products__item-wrapper">
        <div className="item-holder p-relative">
          <div className="products__item">
            <div className="products__item-inner">
              <div className="products__item-header d-flex justify-content-between">
                <div className="products__item-header-text">
                  <h5 className="poducts__item-title main-font">{item.title}</h5>
                  <span className="main-font">{itemProcessed.hash} {item.hash.option}</span>
                </div>
                <div className="products__item-star">
                  {(!item.star) ? '' : (item.star === 'full') ? ProductStar : ProductHalfStar}
                </div>
              </div>
              <div
                className="products__item-img"
                style={{ backgroundImage: `url("${item.img}")` }}>
              </div>
              <div className="products__item-footer">
                <span className="item-price">{`$${price}`}</span>
                <span className="item-psu main-font">{item.psu && 'psu'}</span>
              </div>
            </div>
          </div>
          <BoxDecor />
        </div>
      </div>
      <TitleBlock text={item.manufacturer} />
      <TitleBlock
        openModal={openModal}
        text={`Hash: ${hash} ${item.hash.option}`}
        icon={(item.hash.value.length > 1) && <span className="toggle-arrow">{ToggleArrow}</span>}
        func={null} />
      {isModal ?
        <SelectModal>
          {selectDiv}
        </SelectModal>
        :
        null
      }
      <div className="grid-template-2fr details__order">
        <div className="d-flex">
          <div className="input-holder main-font details__order-input">
            <div className="input-wrapper">
              <input type="text" defaultValue="1" />
            </div>
            <BoxDecor />
          </div>
          <div className="details__order-share-holder">
            <span className="share-icon">{ShareIcon}</span>
          </div>
        </div>
        <ButtonMain text={'Add to cart'} func={() => addToCart(item)} />
      </div>
    </>
  );
};

export default OnSaleMB;
