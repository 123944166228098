import React from 'react';

export const Illustration = (
  <svg width="185" height="175" viewBox="0 0 185 175" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M167.308 96.909L167.261 86.622L157.213 86.5741L104.486 56.0001C96.6388 51.4546 82.859 51.981 73.7681 57.1962L25.1558 85.1387C24.7731 85.3779 24.3903 85.6172 24.0075 85.8564L17.5961 85.8086V95.3301C17.6439 98.153 19.4142 100.832 22.907 102.89L80.4188 136.239C88.2656 140.784 102.045 140.258 111.136 135.043L159.749 107.1C164.82 104.182 167.356 100.45 167.308 96.909Z" fill="url(#paint0_linear)" />
    <path d="M80.4195 126.765L22.9078 93.4163C15.061 88.8709 16.0658 80.9283 25.1566 75.6652L73.8167 47.7227C82.9076 42.5074 96.6874 41.9333 104.534 46.5265L161.998 79.8278C169.845 84.3733 168.84 92.3158 159.749 97.579L111.137 125.521C102.046 130.785 88.3142 131.311 80.4195 126.765Z" fill="url(#paint1_linear)" />
    <path d="M106.495 65.091V12.1247L66.5431 11.3591V63.9905C66.3995 64.4211 66.5909 64.8996 67.1173 65.1867L85.2511 75.9043C86.2081 76.4785 87.8349 76.4306 88.9353 75.8086L105.586 66.4307C106.256 66.0479 106.543 65.5694 106.495 65.091Z" fill="url(#paint2_linear)" />
    <path d="M85.2512 22.6989L67.1173 11.9812C66.1603 11.4071 66.3039 10.498 67.4043 9.87597L84.055 0.498024C85.1555 -0.123983 86.7822 -0.171832 87.7392 0.402329L105.873 11.12C106.83 11.6941 106.686 12.6032 105.586 13.2252L88.9353 22.6032C87.8349 23.2252 86.1602 23.273 85.2512 22.6989Z" fill="url(#paint3_linear)" />
    <path d="M85.8253 18.1535L74.7249 11.5985C74.1507 11.2636 74.2464 10.6894 74.9163 10.3067L85.1076 4.56505C85.7775 4.18228 86.7823 4.13443 87.3564 4.5172L98.4569 11.0722C99.031 11.4071 98.9353 11.9813 98.2655 12.3641L88.0741 18.1057C87.4043 18.4884 86.3995 18.5363 85.8253 18.1535Z" fill="url(#paint4_linear)" />
    <path d="M82.0933 59.9236C82.0933 59.158 81.6627 58.2968 81.0885 57.9619L67.5958 49.9715C67.3565 49.8279 67.1173 49.8279 66.9259 49.9236C66.8781 49.9236 66.8781 49.9715 66.8302 49.9715L63.481 51.981L78.2177 62.7944L81.6149 60.7848C81.7105 60.7848 81.7584 60.737 81.8062 60.6413C81.9976 60.5456 82.0933 60.3064 82.0933 59.9236Z" fill="url(#paint5_linear)" />
    <path d="M77.6436 62.7464L64.1508 54.756C63.5766 54.4211 63.146 53.5599 63.146 52.7943C63.146 52.0288 63.6245 51.6939 64.1986 52.0288L77.6914 60.0192C78.2655 60.3541 78.6962 61.2153 78.6962 61.9809C78.6483 62.7464 78.1699 63.0814 77.6436 62.7464Z" fill="url(#paint6_linear)" />
    <path d="M82.0455 53.9907C82.0455 53.2252 81.6148 52.3639 81.0407 52.029L67.5479 44.0386C67.3087 43.8951 67.0695 43.8951 66.8781 43.9908C66.8302 43.9908 66.8302 44.0386 66.7824 44.0386L63.4331 46.0482L78.1699 56.8615L81.567 54.852C81.6627 54.852 81.7105 54.8041 81.7584 54.7084C81.9498 54.6127 82.0455 54.3735 82.0455 53.9907Z" fill="url(#paint7_linear)" />
    <path d="M77.5957 56.8136L64.1029 48.8232C63.5288 48.4883 63.0981 47.627 63.0981 46.8615C63.0981 46.0959 63.5766 45.761 64.1508 46.0959L77.6435 54.0863C78.2177 54.4212 78.6483 55.2825 78.6483 56.048C78.6005 56.8136 78.122 57.1485 77.5957 56.8136Z" fill="url(#paint8_linear)" />
    <path d="M82.0932 48.0576C82.0932 47.2921 81.6626 46.4308 81.0884 46.0959L67.5957 38.1534C67.3564 38.0098 67.1172 38.0098 66.9258 38.1055C66.8779 38.1055 66.878 38.1534 66.8301 38.1534L63.4808 40.1629L78.2176 50.9763L81.6147 48.9667C81.7104 48.9667 81.7583 48.9189 81.8061 48.8232C81.9975 48.6796 82.0932 48.4404 82.0932 48.0576Z" fill="url(#paint9_linear)" />
    <path d="M77.5957 50.8805L64.1029 42.8901C63.5288 42.5551 63.0981 41.6939 63.0981 40.9284C63.0981 40.1628 63.5766 39.8279 64.1508 40.1628L77.6435 48.1532C78.2177 48.4881 78.6483 49.3494 78.6483 50.1149C78.6483 50.8805 78.1699 51.2154 77.5957 50.8805Z" fill="url(#paint10_linear)" />
    <path d="M82.0932 42.1248C82.0932 41.3592 81.6626 40.498 81.0884 40.163L67.5957 32.1726C67.3564 32.0291 67.1172 32.0291 66.9258 32.1248C66.8779 32.1248 66.878 32.1726 66.8301 32.1726L63.4808 34.1822L78.2176 44.9956L81.6147 42.986C81.7104 42.986 81.7583 42.9382 81.8061 42.8425C81.9497 42.6989 82.0932 42.4597 82.0932 42.1248Z" fill="url(#paint11_linear)" />
    <path d="M77.5957 44.8998L64.1029 36.9094C63.5288 36.5744 63.0981 35.7132 63.0981 34.9477C63.0981 34.1821 63.5766 33.8472 64.1508 34.1821L77.6435 42.1725C78.2177 42.5074 78.6483 43.3687 78.6483 44.1342C78.6483 44.8998 78.1699 45.2347 77.5957 44.8998Z" fill="url(#paint12_linear)" />
    <path d="M82.1412 36.1916C82.1412 35.4261 81.7105 34.5649 81.1364 34.2299L67.6436 26.2395C67.4044 26.096 67.1651 26.096 66.9738 26.1917C66.9259 26.1917 66.9259 26.2395 66.8781 26.2395L63.5288 28.2491L78.2656 39.0624L81.6627 37.0529C81.7584 37.0529 81.8062 37.005 81.8541 36.9093C81.9976 36.7658 82.0933 36.5266 82.1412 36.1916Z" fill="url(#paint13_linear)" />
    <path d="M77.6436 38.9667L64.1508 30.9763C63.5766 30.6413 63.146 29.7801 63.146 29.0145C63.146 28.249 63.6245 27.9141 64.1986 28.249L77.6914 36.2394C78.2655 36.5743 78.6962 37.4356 78.6962 38.2011C78.6483 38.9667 78.2177 39.3016 77.6436 38.9667Z" fill="url(#paint14_linear)" />
    <path d="M82.1412 30.2585C82.1412 29.493 81.7105 28.6318 81.1364 28.2968L67.6436 20.3064C67.4044 20.1629 67.1651 20.1629 66.9738 20.2586C66.9259 20.2586 66.9259 20.3064 66.8781 20.3064L63.5288 22.316L78.2656 33.1293L81.6627 31.1198C81.7584 31.1198 81.8062 31.0719 81.8541 30.9762C81.9976 30.8327 82.0933 30.5935 82.1412 30.2585Z" fill="url(#paint15_linear)" />
    <path d="M77.6436 33.0336L64.1508 25.0432C63.5766 24.7082 63.146 23.847 63.146 23.0814C63.146 22.3159 63.6245 21.981 64.1986 22.3159L77.6914 30.3063C78.2655 30.6412 78.6962 31.5025 78.6962 32.268C78.6483 33.0335 78.2177 33.3685 77.6436 33.0336Z" fill="url(#paint16_linear)" />
    <path d="M74.7249 11.6463L76.591 12.7468L85.1077 7.96209C85.7775 7.57931 86.7823 7.53147 87.3565 7.91424L96.5909 13.3688L98.2655 12.4118C98.9354 12.0291 99.0311 11.4549 98.4569 11.12L87.3565 4.51712C86.7823 4.1822 85.7775 4.23004 85.1077 4.56497L74.9163 10.3066C74.1986 10.6893 74.1508 11.2635 74.7249 11.6463Z" fill="url(#paint17_linear)" />
    <path d="M103.624 23.0337L90.9928 30.3064V28.3447L103.624 21.072V23.0337Z" fill="url(#paint18_linear)" />
    <path d="M103.624 26.8135L99.7488 29.0623V27.1006L103.624 24.8518V26.8135Z" fill="url(#paint19_linear)" />
    <path d="M98.2655 29.8758L90.9928 34.0863V32.1246L98.2655 27.9141V29.8758Z" fill="url(#paint20_linear)" />
    <path d="M103.624 30.45L94.6292 35.6653V33.7036L103.624 28.4883V30.45Z" fill="url(#paint21_linear)" />
    <path d="M93.1459 36.4786L90.9928 37.7226V35.7609L93.1459 34.5168V36.4786Z" fill="url(#paint22_linear)" />
    <path d="M103.624 34.2298L90.9928 41.5503V39.5886L103.624 32.2681V34.2298Z" fill="url(#paint23_linear)" />
    <path d="M103.624 38.0098L99.7488 40.2586V38.2969L103.624 36.0481V38.0098Z" fill="url(#paint24_linear)" />
    <path d="M98.2655 41.1197L90.9928 45.3302V43.3685L98.2655 39.158V41.1197Z" fill="url(#paint25_linear)" />
    <path d="M103.624 41.646L94.6292 46.8613V44.8996L103.624 39.6843V41.646Z" fill="url(#paint26_linear)" />
    <path d="M93.1459 47.7227L90.9928 48.9667V47.005L93.1459 45.761V47.7227Z" fill="url(#paint27_linear)" />
    <path d="M103.624 45.2347L90.9928 52.5552V50.5935L103.624 43.2729V45.2347Z" fill="url(#paint28_linear)" />
    <path d="M103.624 49.0144L99.7488 51.2632V49.3015L103.624 47.0527V49.0144Z" fill="url(#paint29_linear)" />
    <path d="M98.2655 52.1246L90.9928 56.3351V54.3733L98.2655 50.1628V52.1246Z" fill="url(#paint30_linear)" />
    <path d="M103.624 52.6509L94.6292 57.8662V55.9045L103.624 50.6892V52.6509Z" fill="url(#paint31_linear)" />
    <path d="M93.1459 58.7273L90.9928 59.9714V58.0096L93.1459 56.7656V58.7273Z" fill="url(#paint32_linear)" />
    <path d="M103.624 56.4786L90.9928 63.7513V61.7895L103.624 54.5168V56.4786Z" fill="url(#paint33_linear)" />
    <path d="M56.4475 85.7608V58.0097L32.572 57.5312V85.0909C32.4763 85.378 32.6199 85.6173 32.9069 85.8086L43.7681 92.2201C44.3423 92.555 45.2992 92.5072 45.9691 92.1722L55.9212 86.5742C56.304 86.335 56.4953 86.0479 56.4475 85.7608Z" fill="url(#paint34_linear)" />
    <path d="M43.7203 64.3255L32.8591 57.914C32.2849 57.5791 32.3806 57.0049 33.0504 56.6221L43.0026 51.0241C43.6724 50.6413 44.6293 50.6413 45.2035 50.9762L56.0647 57.3877C56.6389 57.7226 56.5432 58.2968 55.8733 58.6795L45.9212 64.2776C45.2992 64.6604 44.2944 64.6604 43.7203 64.3255Z" fill="url(#paint35_linear)" />
    <path d="M53.816 92.3158L50.3232 90.3062V88.2488L53.816 90.2583V92.3158Z" fill="#3B3BC4" />
    <path d="M57.1652 90.4019L53.8159 88.488L57.1652 88.2966V90.4019Z" fill="#3B3BC4" />
    <path d="M54.4858 83.2727V87.2918L58.2179 85.9999L60.5145 82.3157L58.4093 80.5454L54.4858 83.2727Z" fill="url(#paint36_linear)" />
    <path d="M54.5337 77.2917V80.7846L58.9356 78.775L59.6533 75.33L57.5002 74.8516L54.5337 77.2917Z" fill="url(#paint37_linear)" />
    <path d="M48.7443 74.0861L50.3232 73.177L53.816 75.1866L52.2371 76.0957L48.7443 74.0861Z" fill="#DFD2FF" />
    <path d="M57.1652 73.2249L53.6724 71.2632L52.0457 72.2201L55.5385 74.1818L57.1652 73.2249Z" fill="#DFD2FF" />
    <path d="M59.9404 72.4114L56.5911 70.4976L55.5385 71.6459L59.127 73.1291L59.9404 72.4114Z" fill="#DFD2FF" />
    <path d="M50.3232 71.1196L53.816 73.1292V75.1866L50.3232 73.177V71.1196Z" fill="#554FC6" />
    <path d="M53.6725 69.2058L57.1653 71.1675V73.2249L53.6725 71.2632V69.2058Z" fill="#554FC6" />
    <path d="M55.395 68.2009L58.8878 70.2105L57.1653 71.1674L53.6725 69.2057L55.395 68.2009Z" fill="#DFD2FF" />
    <path d="M52.0457 70.1626L55.5385 72.1243L53.816 73.1291L50.3232 71.1195L52.0457 70.1626Z" fill="#DFD2FF" />
    <path d="M57.1653 90.4018V88.3443C56.8303 88.5357 56.4954 88.7271 56.0648 88.9664L55.5385 89.3013V91.3587L53.816 92.3635V90.3061L52.2371 91.2151V76.1434L53.816 75.2344V73.1769L55.5385 72.1722V74.2296L56.7346 73.5119C56.8782 73.4162 57.0217 73.3683 57.1653 73.2726V71.2152L58.8877 70.2104V72.4592C59.2705 72.3635 59.6055 72.3635 59.9404 72.4592C60.2753 72.5549 60.5624 72.842 60.8016 73.2248C61.0408 73.6076 61.1365 74.1339 61.1365 74.8037C61.1365 75.5214 60.993 76.2391 60.7059 77.0047C60.4188 77.7702 60.0361 78.4401 59.5576 79.0142C60.2275 78.8707 60.7538 79.0142 61.1365 79.4449C61.5193 79.8755 61.7107 80.5453 61.7107 81.4066C61.7107 82.0764 61.5672 82.842 61.3279 83.6075C61.0887 84.3731 60.7538 85.0908 60.3231 85.7128C59.9404 86.3348 59.4141 86.8611 58.8399 87.2917V89.4448L57.1653 90.4018ZM54.4858 83.2726V87.2917L56.5911 86.0955C57.4045 85.6171 57.9308 85.2822 58.17 85.0908C58.505 84.8037 58.7921 84.4688 58.9834 83.9903C59.1748 83.5597 59.3184 83.0812 59.3184 82.507C59.3184 82.0764 59.2227 81.7415 59.0791 81.5023C58.9356 81.263 58.6964 81.1673 58.3614 81.2152C58.0265 81.263 57.3567 81.5501 56.3519 82.1721L54.4858 83.2726ZM54.5337 77.2918V80.7846L56.0169 79.9233C56.926 79.397 57.4523 79.0621 57.6916 78.9185C58.0744 78.6315 58.4093 78.2965 58.6007 77.8659C58.8399 77.4353 58.9356 77.0047 58.9356 76.4784C58.9356 75.9999 58.8399 75.665 58.6485 75.5214C58.4571 75.33 58.1701 75.33 57.7873 75.4736C57.548 75.5693 56.926 75.9042 55.8256 76.5262L54.5337 77.2918Z" fill="#B590FF" />
    <path d="M48.7443 74.0862L52.2371 76.0957V91.1674L48.7443 89.2057V74.0862Z" fill="#554FC6" />
    <path d="M35.6821 66.4785L34.0553 65.5694C33.9596 65.5215 33.8639 65.3301 33.8639 65.1866V62.6029C33.8639 62.4593 33.9596 62.4115 34.0553 62.4593L35.6821 63.3684C35.7778 63.4163 35.8734 63.6077 35.8734 63.7512V66.3349C35.8734 66.4785 35.7778 66.5742 35.6821 66.4785Z" fill="#F5E9E6" />
    <path d="M38.5528 68.153L36.926 67.2439C36.8303 67.1961 36.7346 67.0047 36.7346 66.8612V64.2775C36.7346 64.1339 36.8303 64.0861 36.926 64.1339L38.5528 65.043C38.6485 65.0908 38.7442 65.2822 38.7442 65.4258V68.0095C38.7442 68.153 38.6485 68.2009 38.5528 68.153Z" fill="#F5E9E6" />
    <path d="M41.4236 69.8278L39.7969 68.9187C39.7012 68.8709 39.6055 68.6795 39.6055 68.536V65.9523C39.6055 65.8087 39.7012 65.7609 39.7969 65.8087L41.4236 66.7178C41.5193 66.7656 41.615 66.957 41.615 67.1006V69.6843C41.615 69.8278 41.5193 69.8757 41.4236 69.8278Z" fill="#F5E9E6" />
    <path d="M35.6821 70.5932L34.0553 69.6841C33.9596 69.6363 33.8639 69.4449 33.8639 69.3014V66.7176C33.8639 66.5741 33.9596 66.5262 34.0553 66.5741L35.6821 67.4832C35.7778 67.531 35.8734 67.7224 35.8734 67.866V70.4497C35.8734 70.5932 35.7778 70.6411 35.6821 70.5932Z" fill="#F5E9E6" />
    <path d="M38.5528 72.268L36.926 71.3589C36.8303 71.3111 36.7346 71.1197 36.7346 70.9762V68.3924C36.7346 68.2489 36.8303 68.2011 36.926 68.2489L38.5528 69.158C38.6485 69.2058 38.7442 69.3972 38.7442 69.5408V72.1245C38.7442 72.268 38.6485 72.3159 38.5528 72.268Z" fill="#F5E9E6" />
    <path d="M41.4236 73.8947L39.7969 72.9856C39.7012 72.9378 39.6055 72.7464 39.6055 72.6029V70.0191C39.6055 69.8756 39.7012 69.8278 39.7969 69.8756L41.4236 70.7847C41.5193 70.8325 41.615 71.0239 41.615 71.1675V73.7512C41.615 73.8947 41.5193 73.9904 41.4236 73.8947Z" fill="#F5E9E6" />
    <path d="M38.5528 76.3349L36.926 75.4258C36.8303 75.378 36.7346 75.1866 36.7346 75.0431V72.4593C36.7346 72.3158 36.8303 72.2679 36.926 72.3158L38.5528 73.2249C38.6485 73.2727 38.7442 73.4641 38.7442 73.6077V76.1914C38.7442 76.3349 38.6485 76.4306 38.5528 76.3349Z" fill="#F5E9E6" />
    <path d="M35.6821 78.7751L34.0553 77.866C33.9596 77.8182 33.8639 77.6268 33.8639 77.4832V74.8995C33.8639 74.756 33.9596 74.7081 34.0553 74.756L35.6821 75.6651C35.7778 75.7129 35.8734 75.9043 35.8734 76.0478V78.6316C35.8734 78.7751 35.7778 78.8229 35.6821 78.7751Z" fill="#F5E9E6" />
    <path d="M41.4236 86.1914L39.7969 85.2823C39.7012 85.2344 39.6055 85.043 39.6055 84.8995V82.3158C39.6055 82.1722 39.7012 82.1244 39.7969 82.1722L41.4236 83.0813C41.5193 83.1292 41.615 83.3206 41.615 83.4641V86.0478C41.615 86.1914 41.5193 86.2392 41.4236 86.1914Z" fill="#F5E9E6" />
    <path d="M143.241 82.1725V38.01L119.366 37.5315V81.5026C119.27 81.7897 119.414 82.0289 119.701 82.2203L130.562 88.6318C131.136 88.9667 132.093 88.9188 132.763 88.5839L142.715 82.9858C143.098 82.6988 143.289 82.4117 143.241 82.1725Z" fill="url(#paint38_linear)" />
    <path d="M130.562 44.3257L119.701 37.9142C119.127 37.5793 119.222 37.0052 119.892 36.6224L129.844 31.0243C130.514 30.6415 131.471 30.6415 132.045 30.9765L142.906 37.3879C143.481 37.7228 143.385 38.297 142.715 38.6798L132.763 44.2779C132.093 44.6128 131.088 44.6606 130.562 44.3257Z" fill="url(#paint39_linear)" />
    <path d="M143.672 92.1244L138.84 89.3493V86.4785L143.672 89.2536V92.1244Z" fill="#3B3BC4" />
    <path d="M148.361 89.3972L143.672 86.8134L148.361 86.5264V89.3972Z" fill="#3B3BC4" />
    <path d="M144.677 79.5409L144.629 85.1389L149.796 83.3686L153.002 78.2012L150.036 75.761L144.677 79.5409Z" fill="url(#paint40_linear)" />
    <path d="M144.677 71.2155V76.048L150.801 73.2729L151.806 68.4882L148.84 67.7705L144.677 71.2155Z" fill="url(#paint41_linear)" />
    <path d="M136.639 66.7657L138.84 65.5217L143.672 68.249L141.471 69.5409L136.639 66.7657Z" fill="#DFD2FF" />
    <path d="M148.361 65.5695L143.481 62.8423L141.232 64.1341L146.064 66.8614L148.361 65.5695Z" fill="#DFD2FF" />
    <path d="M152.189 64.4214L147.548 61.7419L146.112 63.3209L151.088 65.3783L152.189 64.4214Z" fill="#DFD2FF" />
    <path d="M138.84 62.6509L143.672 65.3781V68.2489L138.84 65.5217V62.6509Z" fill="#554FC6" />
    <path d="M143.481 59.9714L148.361 62.6987V65.5695L143.481 62.8422V59.9714Z" fill="#554FC6" />
    <path d="M145.873 58.584L150.753 61.3112L148.361 62.6988L143.481 59.9715L145.873 58.584Z" fill="#DFD2FF" />
    <path d="M141.232 61.2634L146.112 63.9907L143.672 65.3782L138.84 62.651L141.232 61.2634Z" fill="#DFD2FF" />
    <path d="M148.361 89.3973V86.5265C147.931 86.8136 147.404 87.1006 146.83 87.4356L146.064 87.8662V90.737L143.672 92.1245V89.2537L141.471 90.5456V69.5409L143.672 68.249V65.3782L146.064 63.9907V66.8615L147.739 65.9046C147.931 65.8089 148.122 65.6653 148.313 65.5696V62.6988L150.706 61.3113V64.4213C151.232 64.2778 151.71 64.2778 152.141 64.4213C152.619 64.5649 153.05 64.9476 153.337 65.4739C153.672 66.0003 153.816 66.7658 153.816 67.627C153.816 68.6318 153.624 69.6366 153.194 70.6892C152.811 71.7419 152.237 72.6509 151.567 73.4643C152.524 73.273 153.241 73.5122 153.768 74.0864C154.294 74.7084 154.533 75.5696 154.533 76.8136C154.533 77.7705 154.342 78.7753 154.055 79.8758C153.72 80.9763 153.241 81.9332 152.667 82.8423C152.093 83.7035 151.423 84.4212 150.61 84.9954V88.0097L148.361 89.3973ZM144.677 79.5409V85.1389L147.596 83.4643C148.744 82.7944 149.462 82.3638 149.797 82.0767C150.275 81.694 150.658 81.1677 150.945 80.5935C151.232 79.9715 151.375 79.3016 151.375 78.5361C151.375 77.9141 151.28 77.4356 151.041 77.1485C150.801 76.8615 150.466 76.7179 150.036 76.7658C149.605 76.8136 148.648 77.2442 147.213 78.1055L144.677 79.5409ZM144.677 71.2155V76.0481L146.782 74.8519C148.026 74.1342 148.792 73.6557 149.079 73.4643C149.605 73.0816 150.036 72.5553 150.371 71.9811C150.706 71.4069 150.849 70.7371 150.849 70.0672C150.849 69.3974 150.706 68.9667 150.466 68.7275C150.179 68.4883 149.797 68.4404 149.27 68.6797C148.935 68.8232 148.026 69.3017 146.543 70.1629L144.677 71.2155Z" fill="#B590FF" />
    <path d="M136.639 66.7659L141.471 69.541V90.4978L136.591 87.7706L136.639 66.7659Z" fill="#554FC6" />
    <path d="M122.476 46.9572L120.849 46.0481C120.754 46.0003 120.658 45.8089 120.658 45.6654V43.0816C120.658 42.9381 120.754 42.8903 120.849 42.9381L122.476 43.8472C122.572 43.895 122.667 44.0864 122.667 44.23V46.8137C122.667 46.9572 122.572 47.0051 122.476 46.9572Z" fill="#F5E9E6" />
    <path d="M125.347 48.6318L123.72 47.7227C123.624 47.6749 123.529 47.4835 123.529 47.3399V44.7562C123.529 44.6127 123.624 44.5648 123.72 44.6127L125.347 45.5218C125.442 45.5696 125.538 45.761 125.538 45.9045V48.4883C125.538 48.6318 125.442 48.6796 125.347 48.6318Z" fill="#F5E9E6" />
    <path d="M128.218 50.2587L126.591 49.3497C126.495 49.3018 126.399 49.1104 126.399 48.9669V46.3832C126.399 46.2396 126.495 46.1918 126.591 46.2396L128.218 47.1487C128.313 47.1966 128.409 47.3879 128.409 47.5315V50.1152C128.409 50.2587 128.313 50.3544 128.218 50.2587Z" fill="#F5E9E6" />
    <path d="M122.476 51.0241L120.849 50.115C120.754 50.0672 120.658 49.8758 120.658 49.7323V47.1485C120.658 47.005 120.754 46.9572 120.849 47.005L122.476 47.9141C122.572 47.9619 122.667 48.1533 122.667 48.2969V50.8806C122.667 51.0241 122.572 51.1198 122.476 51.0241Z" fill="#F5E9E6" />
    <path d="M125.347 52.6989L123.72 51.7898C123.624 51.742 123.529 51.5506 123.529 51.4071V48.8233C123.529 48.6798 123.624 48.632 123.72 48.6798L125.347 49.5889C125.442 49.6367 125.538 49.8281 125.538 49.9717V52.5554C125.538 52.6989 125.442 52.7468 125.347 52.6989Z" fill="#F5E9E6" />
    <path d="M128.218 54.3735L126.591 53.4644C126.495 53.4166 126.399 53.2252 126.399 53.0816V50.4979C126.399 50.3544 126.495 50.3065 126.591 50.3544L128.218 51.2635C128.313 51.3113 128.409 51.5027 128.409 51.6462V54.23C128.409 54.3735 128.313 54.4213 128.218 54.3735Z" fill="#F5E9E6" />
    <path d="M125.347 56.8137L123.72 55.8567C123.624 55.8089 123.529 55.6175 123.529 55.474V52.8902C123.529 52.7467 123.624 52.6989 123.72 52.7467L125.347 53.6558C125.442 53.7036 125.538 53.895 125.538 54.0386V56.6223C125.538 56.8137 125.442 56.8615 125.347 56.8137Z" fill="#F5E9E6" />
    <path d="M128.218 62.5554L126.591 61.6463C126.495 61.5984 126.399 61.4071 126.399 61.2635V58.6798C126.399 58.5362 126.495 58.4884 126.591 58.5363L128.218 59.4453C128.313 59.4932 128.409 59.6846 128.409 59.8281V62.4118C128.409 62.5554 128.313 62.6032 128.218 62.5554Z" fill="#F5E9E6" />
    <path d="M122.476 67.4355L120.849 66.5264C120.754 66.4786 120.658 66.2872 120.658 66.1436V63.5599C120.658 63.4164 120.754 63.3685 120.849 63.4164L122.476 64.3255C122.572 64.3733 122.667 64.5647 122.667 64.7082V67.292C122.667 67.4355 122.572 67.4833 122.476 67.4355Z" fill="#F5E9E6" />
    <path d="M75.5383 149.636V115.282L41.1843 114.612V148.679C41.0408 149.062 41.2322 149.445 41.6628 149.732L57.2608 158.966C58.0742 159.445 59.5096 159.397 60.4187 158.87L74.7249 150.784C75.3469 150.449 75.5861 150.067 75.5383 149.636Z" fill="url(#paint42_linear)" />
    <path d="M57.3095 124.421L41.7114 115.187C40.8981 114.708 40.9937 113.895 41.9507 113.368L56.2568 105.282C57.2138 104.756 58.6013 104.708 59.4147 105.187L75.0127 114.421C75.8261 114.899 75.7304 115.713 74.7735 116.239L60.4673 124.325C59.5104 124.852 58.1229 124.899 57.3095 124.421Z" fill="url(#paint43_linear)" />
    <path d="M57.7872 120.545L48.2657 114.899C47.7872 114.612 47.8351 114.086 48.4092 113.799L57.1652 108.871C57.7393 108.536 58.6006 108.536 59.1269 108.823L68.6484 114.469C69.1268 114.756 69.079 115.282 68.5048 115.569L59.7489 120.45C59.1747 120.785 58.2656 120.832 57.7872 120.545Z" fill="url(#paint44_linear)" />
    <path d="M54.5815 146.239C54.5815 145.617 54.1987 144.852 53.7203 144.565L42.1414 137.675C41.9021 137.531 41.7107 137.531 41.5672 137.627L41.5194 137.675L38.6486 139.397L51.2801 148.679L54.1987 146.957C54.2466 146.957 54.3423 146.909 54.3901 146.861C54.4858 146.718 54.5815 146.526 54.5815 146.239Z" fill="url(#paint45_linear)" />
    <path d="M50.7537 148.632L39.1748 141.789C38.6964 141.502 38.3136 140.737 38.3136 140.115C38.3136 139.493 38.7442 139.206 39.2227 139.493L50.8016 146.335C51.2801 146.622 51.6628 147.388 51.6628 148.01C51.6628 148.632 51.2322 148.919 50.7537 148.632Z" fill="url(#paint46_linear)" />
    <path d="M54.5815 141.12C54.5815 140.498 54.1987 139.732 53.7203 139.445L42.1414 132.603C41.9021 132.459 41.7107 132.459 41.5672 132.555L41.5194 132.603L38.6486 134.325L51.2801 143.608L54.1987 141.885C54.2466 141.885 54.3423 141.837 54.3901 141.789C54.4858 141.598 54.5815 141.407 54.5815 141.12Z" fill="url(#paint47_linear)" />
    <path d="M50.7537 143.512L39.1748 136.67C38.6964 136.383 38.3136 135.617 38.3136 134.995C38.3136 134.373 38.7442 134.086 39.2227 134.373L50.8016 141.215C51.2801 141.502 51.6628 142.268 51.6628 142.89C51.615 143.512 51.2322 143.799 50.7537 143.512Z" fill="url(#paint48_linear)" />
    <path d="M54.6292 136C54.6292 135.378 54.2465 134.612 53.768 134.325L42.1891 127.483C41.9499 127.34 41.7585 127.34 41.6149 127.435L41.5671 127.483L38.6963 129.206L51.3278 138.488L54.2465 136.765C54.2943 136.765 54.39 136.718 54.4379 136.67C54.5336 136.526 54.6292 136.287 54.6292 136Z" fill="url(#paint49_linear)" />
    <path d="M50.7537 138.392L39.1748 131.55C38.6964 131.263 38.3136 130.498 38.3136 129.876C38.3136 129.254 38.7442 128.966 39.2227 129.254L50.8016 136.096C51.2801 136.383 51.6628 137.148 51.6628 137.77C51.6628 138.392 51.2322 138.679 50.7537 138.392Z" fill="url(#paint50_linear)" />
    <path d="M54.6292 130.928C54.6292 130.306 54.2465 129.541 53.768 129.253L42.1891 122.411C41.9499 122.268 41.7585 122.268 41.6149 122.364L41.5671 122.411L38.6963 124.134L51.3278 133.416L54.2465 131.694C54.2943 131.694 54.39 131.646 54.4379 131.598C54.5336 131.407 54.6292 131.215 54.6292 130.928Z" fill="url(#paint51_linear)" />
    <path d="M50.7537 133.321L39.1748 126.478C38.6964 126.191 38.3136 125.426 38.3136 124.804C38.3136 124.182 38.7442 123.895 39.2227 124.182L50.8016 131.024C51.2801 131.311 51.6628 132.076 51.6628 132.699C51.6628 133.321 51.2322 133.608 50.7537 133.321Z" fill="url(#paint52_linear)" />
    <path d="M48.2657 114.899L49.8446 115.856L57.1652 111.742C57.7393 111.407 58.6006 111.407 59.1269 111.694L67.0694 116.383L68.5048 115.569C69.079 115.234 69.1268 114.756 68.6484 114.469L59.1269 108.775C58.6484 108.488 57.7872 108.488 57.1652 108.823L48.4092 113.751C47.8351 114.086 47.7394 114.612 48.2657 114.899Z" fill="url(#paint53_linear)" />
    <path d="M73.0981 124.708L62.2369 130.976V129.301L73.0981 123.033V124.708Z" fill="url(#paint54_linear)" />
    <path d="M73.098 127.962L69.7488 129.876V128.201L73.098 126.239V127.962Z" fill="url(#paint55_linear)" />
    <path d="M68.5049 130.593L62.2369 134.23V132.507L68.5049 128.919V130.593Z" fill="url(#paint56_linear)" />
    <path d="M73.0981 131.072L65.3469 135.521V133.847L73.0981 129.397V131.072Z" fill="url(#paint57_linear)" />
    <path d="M64.103 136.287L62.2369 137.34V135.665L64.103 134.564V136.287Z" fill="url(#paint58_linear)" />
    <path d="M73.0981 134.325L62.2369 140.593V138.919L73.0981 132.651V134.325Z" fill="url(#paint59_linear)" />
    <path d="M73.098 137.579L69.7488 139.493V137.818L73.098 135.904V137.579Z" fill="url(#paint60_linear)" />
    <path d="M68.5049 140.258L62.2369 143.847V142.172L68.5049 138.536V140.258Z" fill="url(#paint61_linear)" />
    <path d="M73.0981 140.689L65.3469 145.186V143.512L73.0981 139.014V140.689Z" fill="url(#paint62_linear)" />
    <path d="M64.103 145.904L62.2369 146.957V145.282L64.103 144.229V145.904Z" fill="url(#paint63_linear)" />
    <path d="M73.0981 143.799L62.2369 150.067V148.392L73.0981 142.124V143.799Z" fill="url(#paint64_linear)" />
    <path d="M86.7346 163.129V148.392L74.0552 148.153V162.794C74.0074 162.938 74.0552 163.081 74.2466 163.177L79.9882 166.574C80.2753 166.765 80.8016 166.718 81.1365 166.526L86.4475 163.56C86.6867 163.416 86.7824 163.273 86.7346 163.129Z" fill="url(#paint65_linear)" />
    <path d="M79.9885 151.741L74.2469 148.344C73.9598 148.153 74.0076 147.866 74.3425 147.675L79.6535 144.708C79.9885 144.517 80.5148 144.517 80.8018 144.66L86.5434 148.057C86.8305 148.249 86.7827 148.536 86.4478 148.727L81.1368 151.694C80.8018 151.933 80.3234 151.933 79.9885 151.741Z" fill="url(#paint66_linear)" />
    <path d="M77.6915 155.761L76.0647 154.852C75.969 154.804 75.8733 154.612 75.8733 154.469V151.885C75.8733 151.742 75.969 151.694 76.0647 151.742L77.6915 152.651C77.7872 152.699 77.8829 152.89 77.8829 153.033V155.617C77.8829 155.761 77.7872 155.809 77.6915 155.761Z" fill="#F5E9E6" />
    <path d="M126.926 103.799L126.974 76.5742C126.974 75.7608 126.495 74.8517 125.873 74.5168L94.8206 56.6221C94.5814 56.4786 94.3421 56.4786 94.1508 56.5743L92.9546 57.292L93.768 58.4403V84.8517C93.768 85.6651 94.2465 86.5741 94.8685 86.9091L125.155 105.474L125.442 105.33L126.591 104.708C126.782 104.517 126.926 104.23 126.926 103.799Z" fill="url(#paint67_linear)" />
    <path d="M124.773 105.426L93.7201 87.531C93.0981 87.196 92.6196 86.2391 92.6196 85.4735V58.1052C92.6196 57.2918 93.0981 56.9569 93.7201 57.2918L124.773 75.1865C125.395 75.5214 125.873 76.4784 125.873 77.2439V103.799C125.873 104.564 125.395 105.761 124.773 105.426Z" fill="url(#paint68_linear)" />
    <path d="M124.007 102.603L94.4378 85.5692C94.1507 85.4257 93.9594 84.9951 93.9594 84.6601V59.6842C93.9594 59.3492 94.1507 59.1579 94.4378 59.3014L124.007 76.3348C124.294 76.4784 124.486 76.909 124.486 77.2439V102.22C124.486 102.555 124.294 102.746 124.007 102.603Z" fill="url(#paint69_linear)" />
    <path d="M109.27 67.4354L109.127 67.3876C108.983 67.2919 108.84 67.0526 108.84 66.8612C108.84 66.6699 108.983 66.5742 109.127 66.6699L109.27 66.7177C109.414 66.8134 109.557 67.0526 109.557 67.244C109.557 67.4354 109.414 67.5311 109.27 67.4354Z" fill="url(#paint70_linear)" />
    <path d="M124.533 105.33L122.763 105.186L93.098 88.1051C92.476 87.7702 91.4712 87.818 90.7535 88.2008L69.1268 100.737L67.9785 100.641V101.789C67.9785 102.028 68.0742 102.22 68.3613 102.411L99.4138 120.306C100.036 120.641 101.041 120.593 101.758 120.21L123.959 107.339C124.342 107.1 124.533 106.861 124.533 106.574V105.33Z" fill="url(#paint71_linear)" />
    <path d="M99.4138 119.062L68.3613 101.167C67.7393 100.832 67.835 100.21 68.5048 99.8276L90.7057 86.9569C91.3755 86.5741 92.4282 86.5263 93.0502 86.8612L124.103 104.756C124.725 105.091 124.629 105.713 123.959 106.096L101.758 118.966C101.041 119.349 99.9879 119.397 99.4138 119.062Z" fill="url(#paint72_linear)" />
    <path d="M108.313 113.033L78.744 96C78.457 95.8564 78.5048 95.5693 78.8397 95.4258L91.2799 88.2009C91.567 88.0096 92.0454 88.0096 92.3325 88.1531L121.902 105.187C122.189 105.33 122.141 105.617 121.806 105.761L109.366 112.986C109.031 113.177 108.6 113.177 108.313 113.033Z" fill="url(#paint73_linear)" />
    <path d="M89.1746 111.742L80.9928 107.005C80.8493 106.909 80.8493 106.765 81.0407 106.67L87.6435 102.842C87.7871 102.746 88.0741 102.746 88.2177 102.842L96.3995 107.579C96.543 107.675 96.543 107.818 96.3516 107.914L89.7488 111.742C89.6052 111.837 89.3181 111.837 89.1746 111.742Z" fill="url(#paint74_linear)" />
    <path d="M78.7919 96L78.9354 96.0957L91.2799 88.9186C91.567 88.7273 92.0454 88.7273 92.3325 88.8708L121.758 105.809L121.854 105.761C122.141 105.569 122.189 105.33 121.95 105.187L92.3804 88.1531C92.0933 88.0096 91.6627 88.0096 91.3277 88.2009L78.8876 95.4258C78.5527 95.6172 78.5048 95.8564 78.7919 96Z" fill="url(#paint75_linear)" />
    <path d="M80.9928 107.052L81.232 107.196L87.6435 103.464C87.787 103.368 88.0741 103.368 88.2176 103.464L96.1602 108.057L96.3516 107.961C96.4951 107.866 96.543 107.722 96.3994 107.627L88.2176 102.89C88.0741 102.794 87.8349 102.842 87.6435 102.89L81.0406 106.717C80.8971 106.813 80.8493 106.957 80.9928 107.052Z" fill="url(#paint76_linear)" />
    <path d="M89.2224 111.789L81.3756 107.244C81.232 107.148 81.1842 107.1 81.1842 107.052L82.811 106.095C82.8588 106.095 83.0024 106.095 83.1459 106.191L90.9928 110.737C91.1363 110.832 91.1842 110.88 91.1842 110.928L89.5574 111.885C89.5095 111.885 89.366 111.837 89.2224 111.789Z" fill="url(#paint77_linear)" />
    <path d="M91.2798 91.0717L90.4664 90.5932C90.275 90.4497 90.275 90.2583 90.5143 90.1148L90.8492 89.9234C91.0884 89.7798 91.4712 89.7798 91.6626 89.8755L92.476 90.354C92.6674 90.4975 92.6674 90.6889 92.4281 90.8325L92.0932 91.0238C91.854 91.1674 91.4712 91.1674 91.2798 91.0717Z" fill="#7146CE" />
    <path d="M93.3372 92.268L92.5238 91.7895C92.3324 91.646 92.3324 91.4546 92.5716 91.3111L92.9066 91.1197C93.1458 90.9761 93.5286 90.9761 93.72 91.0718L94.5334 91.5503C94.7248 91.6938 94.7248 91.8852 94.4855 92.0288L94.1506 92.2201C93.9114 92.3637 93.5286 92.3637 93.3372 92.268Z" fill="#7146CE" />
    <path d="M95.3946 93.464L94.5812 92.9856C94.3898 92.842 94.3898 92.6506 94.629 92.5071L94.9639 92.3157C95.2032 92.1722 95.586 92.1722 95.7773 92.2679L96.5907 92.7463C96.7821 92.8899 96.7821 93.0813 96.5429 93.2248L96.208 93.4162C95.9687 93.5597 95.6338 93.5597 95.3946 93.464Z" fill="#7146CE" />
    <path d="M97.4521 94.6603L96.6387 94.1819C96.4473 94.0383 96.4473 93.8469 96.6865 93.7034L97.0214 93.512C97.2607 93.3685 97.6434 93.3685 97.8348 93.4642L98.6482 93.9426C98.8396 94.0862 98.8396 94.2775 98.6004 94.4211L98.2655 94.6125C98.0262 94.756 97.6913 94.756 97.4521 94.6603Z" fill="#7146CE" />
    <path d="M99.5573 95.8085L98.7439 95.33C98.5525 95.1865 98.5525 94.9951 98.7917 94.8516L99.1267 94.6602C99.3659 94.5167 99.7487 94.5167 99.9401 94.6123L100.753 95.0908C100.945 95.2344 100.945 95.4257 100.706 95.5693L100.371 95.7607C100.131 95.9521 99.7487 95.9521 99.5573 95.8085Z" fill="#7146CE" />
    <path d="M101.615 97.0048L100.801 96.5263C100.61 96.3828 100.61 96.1914 100.849 96.0479L101.184 95.8565C101.423 95.7129 101.806 95.7129 101.997 95.8086L102.811 96.2871C103.002 96.4306 103.002 96.622 102.763 96.7656L102.428 96.957C102.189 97.1483 101.806 97.1483 101.615 97.0048Z" fill="#7146CE" />
    <path d="M103.672 98.2008L102.859 97.7224C102.667 97.5788 102.667 97.3875 102.906 97.2439L103.241 97.0525C103.481 96.909 103.863 96.909 104.055 97.0047L104.868 97.4831C105.06 97.6267 105.06 97.8181 104.82 97.9616L104.485 98.153C104.246 98.2965 103.863 98.3444 103.672 98.2008Z" fill="#7146CE" />
    <path d="M105.73 99.3971L104.916 98.9187C104.725 98.7751 104.725 98.5837 104.964 98.4402L105.299 98.2488C105.538 98.1053 105.921 98.1053 106.112 98.201L106.926 98.6794C107.117 98.823 107.117 99.0144 106.878 99.1579L106.543 99.3493C106.304 99.4928 105.969 99.5407 105.73 99.3971Z" fill="#7146CE" />
    <path d="M107.787 100.593L106.974 100.115C106.782 99.9712 106.782 99.7798 107.021 99.6362L107.356 99.4449C107.596 99.3013 107.978 99.3013 108.17 99.397L108.983 99.8755C109.174 100.019 109.174 100.21 108.935 100.354L108.6 100.545C108.361 100.689 108.026 100.689 107.787 100.593Z" fill="#7146CE" />
    <path d="M109.892 101.789L109.079 101.311C108.887 101.167 108.887 100.976 109.127 100.833L109.462 100.641C109.701 100.498 110.084 100.498 110.275 100.593L111.088 101.072C111.28 101.215 111.28 101.407 111.04 101.55L110.706 101.742C110.466 101.885 110.084 101.885 109.892 101.789Z" fill="#7146CE" />
    <path d="M111.997 102.986L111.184 102.507C110.993 102.364 110.993 102.172 111.232 102.029L111.567 101.837C111.806 101.694 112.189 101.694 112.38 101.789L113.194 102.268C113.385 102.411 113.385 102.603 113.146 102.746L112.811 102.938C112.572 103.129 112.189 103.129 111.997 102.986Z" fill="#7146CE" />
    <path d="M114.055 104.182L113.241 103.703C113.05 103.56 113.05 103.368 113.289 103.225L113.624 103.033C113.863 102.89 114.246 102.89 114.438 102.986L115.251 103.464C115.442 103.608 115.442 103.799 115.203 103.943L114.868 104.134C114.629 104.325 114.294 104.325 114.055 104.182Z" fill="#7146CE" />
    <path d="M116.112 105.378L115.299 104.899C115.107 104.756 115.107 104.564 115.347 104.421L115.682 104.23C115.921 104.086 116.304 104.086 116.495 104.182L117.308 104.66C117.5 104.804 117.5 104.995 117.261 105.139L116.926 105.33C116.686 105.474 116.351 105.521 116.112 105.378Z" fill="#7146CE" />
    <path d="M118.217 106.574L117.404 106.096C117.213 105.952 117.213 105.761 117.452 105.617L117.787 105.426C118.026 105.282 118.409 105.282 118.6 105.378L119.414 105.856C119.605 106 119.605 106.191 119.366 106.335L119.031 106.526C118.792 106.67 118.409 106.718 118.217 106.574Z" fill="#7146CE" />
    <path d="M88.8875 92.4594L88.0262 91.9809C87.8349 91.8373 87.8349 91.646 88.0741 91.5024L89.0789 90.9283C89.3181 90.7847 89.7009 90.7847 89.8923 90.8804L90.7535 91.3589C90.9449 91.5024 90.9449 91.6938 90.7057 91.8374L89.7009 92.4115C89.4616 92.555 89.1267 92.6029 88.8875 92.4594Z" fill="#7146CE" />
    <path d="M90.945 93.6554L90.0837 93.1769C89.8924 93.0334 89.8923 92.842 90.1316 92.6985L91.1364 92.1243C91.3756 91.9808 91.7584 91.9808 91.9498 92.0765L92.811 92.5549C93.0024 92.6985 93.0024 92.8899 92.7631 93.0334L91.7584 93.6075C91.5191 93.7511 91.1842 93.7989 90.945 93.6554Z" fill="#7146CE" />
    <path d="M93.0502 94.8517L92.189 94.3732C91.9976 94.2297 91.9976 94.0383 92.2368 93.8948L93.2416 93.3206C93.4808 93.177 93.8636 93.1771 94.055 93.2728L94.9162 93.7512C95.1076 93.8948 95.1076 94.0861 94.8684 94.2297L93.8636 94.8038C93.6244 94.9474 93.2416 94.9952 93.0502 94.8517Z" fill="#7146CE" />
    <path d="M95.1076 96.048L94.2463 95.5695C94.0549 95.426 94.0549 95.2346 94.2942 95.091L95.299 94.5169C95.5382 94.3733 95.921 94.3733 96.1124 94.469L96.9736 94.9475C97.165 95.091 97.165 95.2824 96.9258 95.426L95.921 96.0001C95.6817 96.1437 95.3468 96.1915 95.1076 96.048Z" fill="#7146CE" />
    <path d="M97.1649 97.244L96.3037 96.7656C96.1123 96.622 96.1123 96.4306 96.3515 96.2871L97.3563 95.7129C97.5956 95.5694 97.9783 95.5694 98.1697 95.6651L99.031 96.1436C99.2224 96.2871 99.2224 96.4785 98.9831 96.622L97.9783 97.1962C97.7391 97.3397 97.4042 97.3397 97.1649 97.244Z" fill="#7146CE" />
    <path d="M99.2703 98.4403L98.4091 97.9618C98.2177 97.8183 98.2177 97.6269 98.4569 97.4834L99.4617 96.9092C99.7009 96.7657 100.084 96.7657 100.275 96.8614L101.136 97.3398C101.328 97.4834 101.328 97.6748 101.088 97.8183L100.084 98.3925C99.8445 98.536 99.4617 98.5839 99.2703 98.4403Z" fill="#7146CE" />
    <path d="M101.328 99.6364L100.466 99.1579C100.275 99.0143 100.275 98.823 100.514 98.6794L101.519 98.1053C101.758 97.9617 102.141 97.9617 102.332 98.0574L103.194 98.5359C103.385 98.6794 103.385 98.8708 103.146 99.0144L102.141 99.5885C101.902 99.732 101.567 99.732 101.328 99.6364Z" fill="#7146CE" />
    <path d="M103.433 100.833L102.572 100.354C102.38 100.211 102.38 100.019 102.62 99.8757L103.624 99.3015C103.864 99.158 104.246 99.158 104.438 99.2537L105.299 99.7322C105.49 99.8757 105.49 100.067 105.251 100.211L104.246 100.785C104.007 100.928 103.624 100.976 103.433 100.833Z" fill="#7146CE" />
    <path d="M105.49 102.029L104.629 101.55C104.438 101.407 104.438 101.215 104.677 101.072L105.682 100.498C105.921 100.354 106.304 100.354 106.495 100.45L107.356 100.928C107.548 101.072 107.548 101.263 107.309 101.407L106.304 101.981C106.065 102.124 105.682 102.124 105.49 102.029Z" fill="#7146CE" />
    <path d="M107.548 103.225L106.687 102.747C106.495 102.603 106.495 102.412 106.734 102.268L107.739 101.694C107.978 101.55 108.361 101.55 108.553 101.646L109.414 102.125C109.605 102.268 109.605 102.459 109.366 102.603L108.361 103.177C108.122 103.321 107.787 103.321 107.548 103.225Z" fill="#7146CE" />
    <path d="M109.653 104.421L108.792 103.943C108.6 103.799 108.6 103.608 108.84 103.464L109.844 102.89C110.084 102.746 110.466 102.746 110.658 102.842L111.519 103.321C111.71 103.464 111.71 103.655 111.471 103.799L110.466 104.373C110.227 104.517 109.844 104.517 109.653 104.421Z" fill="#7146CE" />
    <path d="M111.71 105.617L110.849 105.139C110.658 104.995 110.658 104.804 110.897 104.66L111.902 104.086C112.141 103.943 112.524 103.943 112.715 104.038L113.576 104.517C113.768 104.66 113.768 104.852 113.529 104.995L112.524 105.569C112.285 105.713 111.902 105.713 111.71 105.617Z" fill="#7146CE" />
    <path d="M113.768 106.813L112.906 106.335C112.715 106.191 112.715 106 112.954 105.856L113.959 105.282C114.198 105.139 114.581 105.139 114.773 105.234L115.634 105.713C115.825 105.856 115.825 106.048 115.586 106.191L114.581 106.766C114.342 106.909 114.007 106.909 113.768 106.813Z" fill="#7146CE" />
    <path d="M115.873 108.01L115.012 107.531C114.82 107.388 114.82 107.196 115.06 107.053L116.064 106.479C116.304 106.335 116.686 106.335 116.878 106.431L117.739 106.909C117.93 107.053 117.93 107.244 117.691 107.388L116.686 107.962C116.447 108.105 116.064 108.105 115.873 108.01Z" fill="#7146CE" />
    <path d="M86.4952 93.8947L85.6339 93.4162C85.4425 93.2726 85.4425 93.0813 85.6818 92.9377L86.6865 92.3636C86.9258 92.22 87.3085 92.22 87.4999 92.3157L88.3612 92.7942C88.5526 92.9377 88.5526 93.1291 88.3133 93.2727L87.3085 93.8468C87.0693 93.9903 86.7344 93.9903 86.4952 93.8947Z" fill="#7146CE" />
    <path d="M88.6004 95.0907L87.7391 94.6122C87.5477 94.4687 87.5477 94.2773 87.787 94.1338L88.7918 93.5596C89.031 93.4161 89.4138 93.4161 89.6052 93.5118L90.4664 93.9902C90.6578 94.1338 90.6578 94.3252 90.4185 94.4687L89.4138 95.0429C89.1745 95.1864 88.7918 95.1864 88.6004 95.0907Z" fill="#7146CE" />
    <path d="M90.6579 96.287L89.7966 95.8085C89.6052 95.665 89.6052 95.4736 89.8445 95.3301L90.8492 94.7559C91.0885 94.6123 91.4713 94.6124 91.6626 94.7081L92.5239 95.1865C92.7153 95.3301 92.7153 95.5214 92.476 95.665L91.4713 96.2391C91.232 96.3827 90.8971 96.3827 90.6579 96.287Z" fill="#7146CE" />
    <path d="M92.7152 97.483L91.854 97.0046C91.6626 96.861 91.6626 96.6696 91.9018 96.5261L92.9066 95.9519C93.1459 95.8084 93.5286 95.8084 93.72 95.9041L94.5813 96.3826C94.7727 96.5261 94.7727 96.7175 94.5334 96.861L93.5286 97.4352C93.2894 97.5787 92.9545 97.5787 92.7152 97.483Z" fill="#7146CE" />
    <path d="M94.8205 98.6315L93.9592 98.153C93.7678 98.0095 93.7678 97.8181 94.0071 97.6745L95.0119 97.1004C95.2511 96.9568 95.6339 96.9568 95.8253 97.0525L96.6865 97.531C96.8779 97.6745 96.8779 97.8659 96.6386 98.0095L95.6339 98.5836C95.3946 98.775 95.0119 98.775 94.8205 98.6315Z" fill="#7146CE" />
    <path d="M96.878 99.8754L96.0167 99.3969C95.8253 99.2534 95.8253 99.062 96.0646 98.9184L97.0693 98.3443C97.3086 98.2007 97.6914 98.2007 97.8827 98.2964L98.744 98.7749C98.9354 98.9184 98.9354 99.1098 98.6961 99.2534L97.6914 99.8275C97.4521 99.9711 97.1172 99.9711 96.878 99.8754Z" fill="#7146CE" />
    <path d="M98.9353 101.024L98.0741 100.545C97.8827 100.402 97.8827 100.21 98.1219 100.067L99.1267 99.4927C99.366 99.3492 99.7487 99.3492 99.9401 99.4449L100.801 99.9233C100.993 100.067 100.993 100.258 100.754 100.402L99.7487 100.976C99.5095 101.167 99.1746 101.167 98.9353 101.024Z" fill="#7146CE" />
    <path d="M101.041 102.268L100.179 101.789C99.9879 101.646 99.9879 101.454 100.227 101.311L101.232 100.737C101.471 100.593 101.854 100.593 102.045 100.689L102.907 101.167C103.098 101.311 103.098 101.502 102.859 101.646L101.854 102.22C101.615 102.363 101.232 102.363 101.041 102.268Z" fill="#7146CE" />
    <path d="M103.098 103.416L102.237 102.938C102.045 102.794 102.045 102.603 102.285 102.459L103.289 101.885C103.529 101.742 103.911 101.742 104.103 101.837L104.964 102.316C105.155 102.459 105.155 102.651 104.916 102.794L103.911 103.368C103.672 103.56 103.337 103.56 103.098 103.416Z" fill="#7146CE" />
    <path d="M105.155 104.612L104.294 104.134C104.103 103.99 104.103 103.799 104.342 103.655L105.347 103.081C105.586 102.938 105.969 102.938 106.16 103.033L107.021 103.512C107.213 103.655 107.213 103.847 106.974 103.99L105.969 104.565C105.73 104.756 105.395 104.756 105.155 104.612Z" fill="#7146CE" />
    <path d="M107.261 105.808L106.399 105.33C106.208 105.186 106.208 104.995 106.447 104.852L107.452 104.277C107.691 104.134 108.074 104.134 108.265 104.23L109.127 104.708C109.318 104.852 109.318 105.043 109.079 105.186L108.074 105.761C107.835 105.952 107.452 105.952 107.261 105.808Z" fill="#7146CE" />
    <path d="M109.318 107.005L108.457 106.526C108.265 106.383 108.265 106.191 108.505 106.048L109.509 105.474C109.749 105.33 110.131 105.33 110.323 105.426L111.184 105.904C111.375 106.048 111.375 106.239 111.136 106.383L110.131 106.957C109.892 107.148 109.557 107.148 109.318 107.005Z" fill="#7146CE" />
    <path d="M111.375 108.201L110.514 107.722C110.323 107.579 110.323 107.387 110.562 107.244L111.567 106.67C111.806 106.526 112.189 106.526 112.38 106.622L113.241 107.1C113.433 107.244 113.433 107.435 113.194 107.579L112.189 108.153C111.95 108.296 111.615 108.344 111.375 108.201Z" fill="#7146CE" />
    <path d="M113.481 109.397L112.619 108.919C112.428 108.775 112.428 108.584 112.667 108.44L113.672 107.866C113.911 107.722 114.294 107.722 114.486 107.818L115.347 108.297C115.538 108.44 115.538 108.632 115.299 108.775L114.294 109.349C114.055 109.541 113.672 109.541 113.481 109.397Z" fill="#7146CE" />
    <path d="M86.0645 96.4783L83.1459 94.8036C82.9545 94.6601 82.9545 94.4687 83.1937 94.3252L84.1985 93.751C84.4377 93.6075 84.8205 93.6075 85.0119 93.7032L87.9305 95.3778C88.1219 95.5213 88.1219 95.7127 87.8827 95.8563L86.8779 96.4304C86.6387 96.574 86.2559 96.574 86.0645 96.4783Z" fill="#7146CE" />
    <path d="M88.1697 97.6744L87.3085 97.196C87.1171 97.0524 87.1171 96.861 87.3563 96.7175L88.3611 96.1433C88.6003 95.9998 88.9831 95.9998 89.1745 96.0955L90.0357 96.574C90.2271 96.7175 90.2271 96.9089 89.9879 97.0524L88.9831 97.6266C88.7439 97.7701 88.3611 97.818 88.1697 97.6744Z" fill="#7146CE" />
    <path d="M90.2272 98.8705L89.366 98.392C89.1746 98.2485 89.1746 98.0571 89.4138 97.9135L90.4186 97.3394C90.6578 97.1959 91.0406 97.1959 91.232 97.2915L92.0932 97.77C92.2846 97.9136 92.2846 98.1049 92.0454 98.2485L91.0406 98.8226C90.8014 98.9662 90.4664 99.014 90.2272 98.8705Z" fill="#7146CE" />
    <path d="M92.2846 100.067L91.4233 99.5883C91.2319 99.4448 91.232 99.2534 91.4712 99.1098L92.476 98.5357C92.7152 98.3921 93.098 98.3921 93.2894 98.4878L94.1506 98.9663C94.342 99.1098 94.342 99.3012 94.1028 99.4448L93.098 100.019C92.8587 100.162 92.5238 100.162 92.2846 100.067Z" fill="#7146CE" />
    <path d="M94.3898 101.263L93.5286 100.784C93.3372 100.641 93.3372 100.449 93.5764 100.306L94.5812 99.7317C94.8204 99.5882 95.2032 99.5882 95.3946 99.6839L96.2558 100.162C96.4472 100.306 96.4472 100.497 96.208 100.641L95.2032 101.215C94.964 101.359 94.5812 101.406 94.3898 101.263Z" fill="#7146CE" />
    <path d="M96.4472 102.459L95.5859 101.981C95.3946 101.837 95.3945 101.646 95.6338 101.502L96.6386 100.928C96.8778 100.784 97.2606 100.784 97.452 100.88L98.3132 101.359C98.5046 101.502 98.5046 101.694 98.2653 101.837L97.2606 102.411C97.0213 102.555 96.6864 102.555 96.4472 102.459Z" fill="#7146CE" />
    <path d="M98.5525 103.655L97.6913 103.177C97.4999 103.033 97.4999 102.842 97.7391 102.698L98.7439 102.124C98.9831 101.981 99.3659 101.981 99.5573 102.076L100.419 102.555C100.61 102.698 100.61 102.89 100.371 103.033L99.3659 103.608C99.1267 103.751 98.7439 103.799 98.5525 103.655Z" fill="#7146CE" />
    <path d="M100.61 104.851L99.7487 104.373C99.5573 104.229 99.5573 104.038 99.7965 103.895L100.801 103.32C101.041 103.177 101.423 103.177 101.615 103.273L102.476 103.751C102.667 103.895 102.667 104.086 102.428 104.229L101.423 104.804C101.184 104.947 100.801 104.947 100.61 104.851Z" fill="#7146CE" />
    <path d="M102.667 106.048L101.806 105.569C101.615 105.426 101.615 105.234 101.854 105.091L102.859 104.517C103.098 104.373 103.481 104.373 103.672 104.469L104.533 104.947C104.725 105.091 104.725 105.282 104.486 105.426L103.481 106C103.242 106.143 102.907 106.143 102.667 106.048Z" fill="#7146CE" />
    <path d="M104.773 107.244L103.911 106.765C103.72 106.622 103.72 106.43 103.959 106.287L104.964 105.713C105.203 105.569 105.586 105.569 105.777 105.665L106.639 106.143C106.83 106.287 106.83 106.478 106.591 106.622L105.586 107.196C105.347 107.339 104.964 107.339 104.773 107.244Z" fill="#7146CE" />
    <path d="M106.83 108.44L105.969 107.962C105.777 107.818 105.777 107.627 106.017 107.483L107.021 106.909C107.261 106.765 107.643 106.765 107.835 106.861L108.696 107.34C108.887 107.483 108.887 107.675 108.648 107.818L107.643 108.392C107.404 108.536 107.021 108.536 106.83 108.44Z" fill="#7146CE" />
    <path d="M108.887 109.636L108.026 109.158C107.835 109.014 107.835 108.823 108.074 108.679L109.079 108.105C109.318 107.961 109.701 107.961 109.892 108.057L110.753 108.536C110.945 108.679 110.945 108.871 110.706 109.014L109.701 109.588C109.462 109.732 109.127 109.732 108.887 109.636Z" fill="#7146CE" />
    <path d="M108.6 112.22L107.739 111.741C107.548 111.598 107.548 111.407 107.787 111.263L111.184 109.301C111.423 109.158 111.806 109.158 111.997 109.253L112.859 109.732C113.05 109.875 113.05 110.067 112.811 110.21L109.414 112.172C109.127 112.316 108.792 112.363 108.6 112.22Z" fill="#7146CE" />
    <path d="M81.6148 96.6698L80.7535 96.1913C80.5622 96.0478 80.5621 95.8564 80.8014 95.7129L81.8062 95.1387C82.0454 94.9952 82.4282 94.9952 82.6196 95.0909L83.4808 95.5693C83.6722 95.7129 83.6722 95.9043 83.4329 96.0478L82.4282 96.622C82.1889 96.8133 81.8062 96.8133 81.6148 96.6698Z" fill="#7146CE" />
    <path d="M83.6721 97.8658L82.8109 97.3874C82.6195 97.2438 82.6195 97.0524 82.8588 96.9089L83.8635 96.3348C84.1028 96.1912 84.4855 96.1912 84.6769 96.2869L85.5382 96.7654C85.7296 96.9089 85.7296 97.1003 85.4903 97.2438L84.4855 97.818C84.2463 97.9615 83.8635 98.0094 83.6721 97.8658Z" fill="#7146CE" />
    <path d="M85.7774 99.0621L84.9161 98.5837C84.7247 98.4401 84.7247 98.2487 84.964 98.1052L85.9688 97.531C86.208 97.3875 86.5908 97.3875 86.7822 97.4832L87.6434 97.9617C87.8348 98.1052 87.8348 98.2966 87.5956 98.4401L86.5908 99.0143C86.3515 99.2057 85.9688 99.2057 85.7774 99.0621Z" fill="#7146CE" />
    <path d="M87.8349 100.258L86.9736 99.7797C86.7822 99.6362 86.7822 99.4448 87.0215 99.3012L88.0263 98.7271C88.2655 98.5835 88.6483 98.5835 88.8397 98.6792L89.7009 99.1577C89.8923 99.3012 89.8923 99.4926 89.653 99.6362L88.6483 100.21C88.409 100.354 88.0263 100.402 87.8349 100.258Z" fill="#7146CE" />
    <path d="M96.1602 105.043L89.031 100.928C88.8396 100.785 88.8396 100.593 89.0789 100.45L90.0836 99.8755C90.3229 99.732 90.7056 99.732 90.897 99.8277L98.0262 103.942C98.2176 104.086 98.2176 104.277 97.9783 104.421L96.9736 104.995C96.6865 105.139 96.3515 105.187 96.1602 105.043Z" fill="#7146CE" />
    <path d="M98.2176 106.239L97.3563 105.761C97.1649 105.617 97.1649 105.426 97.4042 105.282L98.409 104.708C98.6482 104.565 99.031 104.565 99.2223 104.66L100.084 105.139C100.275 105.282 100.275 105.474 100.036 105.617L99.031 106.191C98.7917 106.335 98.409 106.383 98.2176 106.239Z" fill="#7146CE" />
    <path d="M100.275 107.435L99.4137 106.957C99.2223 106.813 99.2223 106.622 99.4615 106.478L100.466 105.904C100.706 105.761 101.088 105.761 101.28 105.856L102.141 106.335C102.332 106.478 102.332 106.67 102.093 106.813L101.088 107.387C100.849 107.531 100.466 107.579 100.275 107.435Z" fill="#7146CE" />
    <path d="M102.38 108.631L101.519 108.153C101.328 108.009 101.328 107.818 101.567 107.675L102.572 107.1C102.811 106.957 103.194 106.957 103.385 107.053L104.246 107.531C104.438 107.675 104.438 107.866 104.198 108.009L103.194 108.584C102.907 108.727 102.572 108.775 102.38 108.631Z" fill="#7146CE" />
    <path d="M104.438 109.828L103.576 109.349C103.385 109.206 103.385 109.014 103.624 108.871L104.629 108.297C104.868 108.153 105.251 108.153 105.442 108.249L106.304 108.727C106.495 108.871 106.495 109.062 106.256 109.206L105.251 109.78C105.012 109.923 104.629 109.971 104.438 109.828Z" fill="#7146CE" />
    <path d="M106.495 111.024L105.634 110.545C105.442 110.402 105.442 110.21 105.682 110.067L106.686 109.493C106.926 109.349 107.308 109.349 107.5 109.445L108.361 109.923C108.552 110.067 108.552 110.258 108.313 110.402L107.308 110.976C107.069 111.12 106.686 111.119 106.495 111.024Z" fill="#7146CE" />
    <path d="M91.232 90.7367L90.4186 90.2583C90.2272 90.1147 90.2272 89.9233 90.4664 89.7798L90.8014 89.5884C91.0406 89.4449 91.4234 89.4449 91.6147 89.5406L92.4281 90.019C92.6195 90.1626 92.6195 90.354 92.3803 90.4975L92.0454 90.6889C91.8061 90.8324 91.4234 90.8803 91.232 90.7367Z" fill="#EFE1EE" />
    <path d="M93.2893 91.9328L92.476 91.4543C92.2846 91.3108 92.2846 91.1194 92.5238 90.9758L92.8587 90.7845C93.098 90.6409 93.4807 90.6409 93.6721 90.7366L94.4855 91.2151C94.6769 91.3586 94.6769 91.55 94.4377 91.6935L94.1027 91.8849C93.8635 92.0285 93.5286 92.0285 93.2893 91.9328Z" fill="#EFE1EE" />
    <path d="M95.3467 93.1291L94.5333 92.6506C94.3419 92.5071 94.3419 92.3157 94.5812 92.1721L94.9161 91.9808C95.1553 91.8372 95.5381 91.8372 95.7295 91.9329L96.5429 92.4114C96.7343 92.5549 96.7343 92.7463 96.495 92.8898L96.1601 93.0812C95.9209 93.2248 95.586 93.2248 95.3467 93.1291Z" fill="#EFE1EE" />
    <path d="M97.4521 94.3254L96.6387 93.8469C96.4473 93.7034 96.4473 93.512 96.6865 93.3684L97.0214 93.177C97.2607 93.0335 97.6434 93.0335 97.8348 93.1292L98.6482 93.6077C98.8396 93.7512 98.8396 93.9426 98.6004 94.0861L98.2655 94.2775C98.0262 94.421 97.6435 94.4211 97.4521 94.3254Z" fill="#EFE1EE" />
    <path d="M99.5094 95.5214L98.696 95.0429C98.5047 94.8994 98.5046 94.708 98.7439 94.5645L99.0788 94.3731C99.318 94.2295 99.7008 94.2295 99.8922 94.3252L100.706 94.8037C100.897 94.9472 100.897 95.1386 100.658 95.2822L100.323 95.4736C100.084 95.6171 99.7008 95.6171 99.5094 95.5214Z" fill="#EFE1EE" />
    <path d="M101.567 96.6698L100.753 96.1914C100.562 96.0478 100.562 95.8564 100.801 95.7129L101.136 95.5215C101.375 95.378 101.758 95.378 101.95 95.4737L102.763 95.9521C102.954 96.0957 102.954 96.2871 102.715 96.4306L102.38 96.622C102.141 96.8134 101.758 96.8134 101.567 96.6698Z" fill="#EFE1EE" />
    <path d="M103.624 97.8659L102.811 97.3874C102.619 97.2439 102.619 97.0525 102.859 96.909L103.194 96.7176C103.433 96.574 103.816 96.574 104.007 96.6697L104.82 97.1482C105.012 97.2917 105.012 97.4831 104.773 97.6267L104.438 97.8181C104.198 98.0094 103.863 98.0094 103.624 97.8659Z" fill="#EFE1EE" />
    <path d="M105.682 99.0622L104.868 98.5837C104.677 98.4402 104.677 98.2488 104.916 98.1052L105.251 97.9139C105.49 97.7703 105.873 97.7703 106.064 97.866L106.878 98.3445C107.069 98.488 107.069 98.6794 106.83 98.8229L106.495 99.0143C106.256 99.1579 105.921 99.2057 105.682 99.0622Z" fill="#EFE1EE" />
    <path d="M107.787 100.258L106.974 99.7798C106.782 99.6362 106.782 99.4448 107.021 99.3013L107.356 99.1099C107.596 98.9664 107.978 98.9664 108.17 99.0621L108.983 99.5405C109.174 99.6841 109.174 99.8754 108.935 100.019L108.6 100.21C108.361 100.354 107.978 100.402 107.787 100.258Z" fill="#EFE1EE" />
    <path d="M109.844 101.455L109.031 100.976C108.839 100.833 108.839 100.641 109.079 100.498L109.414 100.306C109.653 100.163 110.036 100.163 110.227 100.258L111.04 100.737C111.232 100.88 111.232 101.072 110.993 101.215L110.658 101.407C110.418 101.55 110.036 101.55 109.844 101.455Z" fill="#EFE1EE" />
    <path d="M111.95 102.698L111.136 102.22C110.945 102.076 110.945 101.885 111.184 101.741L111.519 101.55C111.758 101.407 112.141 101.407 112.332 101.502L113.146 101.981C113.337 102.124 113.337 102.316 113.098 102.459L112.763 102.651C112.524 102.794 112.189 102.794 111.95 102.698Z" fill="#EFE1EE" />
    <path d="M114.007 103.847L113.194 103.368C113.002 103.225 113.002 103.033 113.241 102.89L113.576 102.699C113.816 102.555 114.198 102.555 114.39 102.651L115.203 103.129C115.395 103.273 115.395 103.464 115.155 103.608L114.82 103.799C114.581 103.99 114.246 103.99 114.007 103.847Z" fill="#EFE1EE" />
    <path d="M116.112 105.043L115.299 104.564C115.107 104.421 115.107 104.229 115.347 104.086L115.682 103.895C115.921 103.751 116.304 103.751 116.495 103.847L117.308 104.325C117.5 104.469 117.5 104.66 117.261 104.804L116.926 104.995C116.686 105.186 116.304 105.186 116.112 105.043Z" fill="#EFE1EE" />
    <path d="M118.17 106.239L117.356 105.761C117.165 105.617 117.165 105.426 117.404 105.282L117.739 105.091C117.978 104.947 118.361 104.947 118.552 105.043L119.366 105.521C119.557 105.665 119.557 105.856 119.318 106L118.983 106.191C118.744 106.335 118.361 106.383 118.17 106.239Z" fill="#EFE1EE" />
    <path d="M88.8396 92.1244L87.9784 91.6459C87.787 91.5024 87.787 91.311 88.0262 91.1675L89.031 90.5933C89.2703 90.4498 89.653 90.4498 89.8444 90.5455L90.7057 91.0239C90.897 91.1675 90.897 91.3588 90.6578 91.5024L89.653 92.0766C89.4138 92.2201 89.0789 92.2679 88.8396 92.1244Z" fill="#EFE1EE" />
    <path d="M90.9449 93.3207L90.0836 92.8422C89.8922 92.6987 89.8922 92.5073 90.1315 92.3637L91.1362 91.7896C91.3755 91.646 91.7583 91.646 91.9496 91.7417L92.8109 92.2202C93.0023 92.3637 93.0023 92.5551 92.763 92.6987L91.7583 93.2728C91.519 93.4164 91.1362 93.4642 90.9449 93.3207Z" fill="#EFE1EE" />
    <path d="M93.0023 94.5167L92.1411 94.0383C91.9497 93.8947 91.9497 93.7033 92.189 93.5598L93.1937 92.9856C93.433 92.8421 93.8157 92.8421 94.0071 92.9378L94.8684 93.4163C95.0598 93.5598 95.0598 93.7512 94.8205 93.8947L93.8157 94.4689C93.5765 94.6124 93.2416 94.6603 93.0023 94.5167Z" fill="#EFE1EE" />
    <path d="M95.0596 95.713L94.1984 95.2345C94.007 95.091 94.007 94.8996 94.2462 94.7561L95.251 94.1819C95.4902 94.0384 95.873 94.0384 96.0644 94.1341L96.9256 94.6125C97.117 94.7561 97.117 94.9475 96.8778 95.091L95.873 95.6652C95.6338 95.8087 95.2988 95.8565 95.0596 95.713Z" fill="#EFE1EE" />
    <path d="M97.1649 96.9091L96.3037 96.4306C96.1123 96.287 96.1123 96.0957 96.3515 95.9521L97.3563 95.378C97.5956 95.2344 97.9783 95.2344 98.1697 95.3301L99.031 95.8086C99.2224 95.9521 99.2224 96.1435 98.9831 96.2871L97.9783 96.8612C97.7391 97.0048 97.3563 97.0526 97.1649 96.9091Z" fill="#EFE1EE" />
    <path d="M99.2223 98.1053L98.3611 97.6269C98.1697 97.4833 98.1697 97.2919 98.4089 97.1484L99.4137 96.5743C99.6529 96.4307 100.036 96.4307 100.227 96.5264L101.088 97.0049C101.28 97.1484 101.28 97.3398 101.04 97.4833L100.036 98.0575C99.7965 98.201 99.4615 98.2489 99.2223 98.1053Z" fill="#EFE1EE" />
    <path d="M101.28 99.3014L100.418 98.8229C100.227 98.6794 100.227 98.488 100.466 98.3445L101.471 97.7703C101.71 97.6268 102.093 97.6268 102.284 97.7225L103.146 98.2009C103.337 98.3445 103.337 98.5358 103.098 98.6794L102.093 99.2536C101.854 99.3971 101.519 99.4449 101.28 99.3014Z" fill="#EFE1EE" />
    <path d="M103.385 100.498L102.524 100.019C102.332 99.8757 102.332 99.6843 102.572 99.5407L103.576 98.9666C103.816 98.8231 104.198 98.823 104.39 98.9187L105.251 99.3972C105.442 99.5407 105.442 99.7321 105.203 99.8757L104.198 100.45C103.959 100.593 103.576 100.641 103.385 100.498Z" fill="#EFE1EE" />
    <path d="M105.442 101.694L104.581 101.215C104.39 101.072 104.39 100.88 104.629 100.737L105.634 100.163C105.873 100.019 106.256 100.019 106.447 100.115L107.308 100.593C107.5 100.737 107.5 100.928 107.261 101.072L106.256 101.646C106.017 101.789 105.682 101.837 105.442 101.694Z" fill="#EFE1EE" />
    <path d="M107.5 102.89L106.639 102.412C106.447 102.268 106.447 102.077 106.686 101.933L107.691 101.359C107.93 101.215 108.313 101.215 108.505 101.311L109.366 101.79C109.557 101.933 109.557 102.124 109.318 102.268L108.313 102.842C108.074 102.986 107.739 102.986 107.5 102.89Z" fill="#EFE1EE" />
    <path d="M109.605 104.086L108.744 103.608C108.553 103.464 108.553 103.273 108.792 103.129L109.797 102.555C110.036 102.411 110.419 102.411 110.61 102.507L111.471 102.986C111.663 103.129 111.663 103.321 111.423 103.464L110.419 104.038C110.179 104.182 109.797 104.23 109.605 104.086Z" fill="#EFE1EE" />
    <path d="M111.663 105.282L110.801 104.804C110.61 104.66 110.61 104.469 110.849 104.325L111.854 103.751C112.093 103.608 112.476 103.608 112.667 103.703L113.529 104.182C113.72 104.325 113.72 104.517 113.481 104.66L112.476 105.235C112.237 105.378 111.902 105.378 111.663 105.282Z" fill="#EFE1EE" />
    <path d="M113.72 106.478L112.859 106C112.667 105.856 112.667 105.665 112.906 105.521L113.911 104.947C114.15 104.804 114.533 104.804 114.725 104.899L115.586 105.378C115.777 105.521 115.777 105.713 115.538 105.856L114.533 106.431C114.294 106.574 113.959 106.574 113.72 106.478Z" fill="#EFE1EE" />
    <path d="M115.825 107.675L114.964 107.196C114.772 107.053 114.772 106.861 115.012 106.718L116.017 106.144C116.256 106 116.638 106 116.83 106.096L117.691 106.574C117.883 106.718 117.883 106.909 117.643 107.053L116.639 107.627C116.399 107.77 116.016 107.77 115.825 107.675Z" fill="#EFE1EE" />
    <path d="M86.4952 93.5597L85.6339 93.0812C85.4425 92.9377 85.4425 92.7463 85.6818 92.6028L86.6865 92.0286C86.9258 91.8851 87.3085 91.8851 87.4999 91.9808L88.3612 92.4592C88.5526 92.6028 88.5526 92.7941 88.3133 92.9377L87.3085 93.5119C87.0693 93.6554 86.6865 93.6554 86.4952 93.5597Z" fill="#EFE1EE" />
    <path d="M88.5526 94.756L87.6914 94.2775C87.5 94.134 87.5 93.9426 87.7392 93.799L88.744 93.2249C88.9833 93.0814 89.366 93.0813 89.5574 93.177L90.4187 93.6555C90.61 93.799 90.61 93.9904 90.3708 94.134L89.366 94.7081C89.1268 94.8517 88.744 94.8517 88.5526 94.756Z" fill="#EFE1EE" />
    <path d="M90.61 95.952L89.7488 95.4736C89.5574 95.33 89.5574 95.1386 89.7966 94.9951L90.8014 94.4209C91.0406 94.2774 91.4234 94.2774 91.6148 94.3731L92.476 94.8516C92.6674 94.9951 92.6674 95.1865 92.4282 95.33L91.4234 95.9042C91.1842 96.0477 90.8492 96.0477 90.61 95.952Z" fill="#EFE1EE" />
    <path d="M92.7152 97.1483L91.854 96.6698C91.6626 96.5263 91.6626 96.3349 91.9018 96.1914L92.9066 95.6172C93.1459 95.4737 93.5286 95.4737 93.72 95.5694L94.5813 96.0478C94.7727 96.1914 94.7727 96.3828 94.5334 96.5263L93.5286 97.1005C93.2894 97.244 92.9066 97.244 92.7152 97.1483Z" fill="#EFE1EE" />
    <path d="M94.7727 98.3444L93.9115 97.8659C93.7201 97.7223 93.7201 97.531 93.9593 97.3874L94.9641 96.8133C95.2034 96.6697 95.5861 96.6697 95.7775 96.7654L96.6388 97.2439C96.8301 97.3874 96.8301 97.5788 96.5909 97.7224L95.5861 98.2965C95.3469 98.4401 94.9641 98.4401 94.7727 98.3444Z" fill="#EFE1EE" />
    <path d="M96.8301 99.5406L95.9689 99.0622C95.7775 98.9186 95.7775 98.7273 96.0167 98.5837L97.0215 98.0096C97.2607 97.866 97.6435 97.866 97.8349 97.9617L98.6961 98.4402C98.8875 98.5837 98.8875 98.7751 98.6483 98.9186L97.6435 99.4928C97.4043 99.6364 97.0693 99.6363 96.8301 99.5406Z" fill="#EFE1EE" />
    <path d="M98.9353 100.737L98.0741 100.258C97.8827 100.115 97.8827 99.9233 98.1219 99.7798L99.1267 99.2056C99.366 99.0621 99.7487 99.0621 99.9401 99.1578L100.801 99.6362C100.993 99.7798 100.993 99.9711 100.754 100.115L99.7487 100.689C99.5095 100.832 99.1267 100.832 98.9353 100.737Z" fill="#EFE1EE" />
    <path d="M100.993 101.933L100.131 101.455C99.9401 101.311 99.9401 101.12 100.179 100.976L101.184 100.402C101.423 100.258 101.806 100.258 101.997 100.354L102.859 100.833C103.05 100.976 103.05 101.167 102.811 101.311L101.806 101.885C101.567 102.029 101.232 102.029 100.993 101.933Z" fill="#EFE1EE" />
    <path d="M103.05 103.129L102.189 102.651C101.997 102.507 101.997 102.316 102.237 102.172L103.241 101.598C103.481 101.454 103.863 101.454 104.055 101.55L104.916 102.029C105.107 102.172 105.107 102.363 104.868 102.507L103.863 103.081C103.624 103.225 103.289 103.225 103.05 103.129Z" fill="#EFE1EE" />
    <path d="M105.155 104.277L104.294 103.799C104.103 103.655 104.103 103.464 104.342 103.321L105.347 102.746C105.586 102.603 105.969 102.603 106.16 102.699L107.021 103.177C107.213 103.321 107.213 103.512 106.974 103.655L105.969 104.23C105.73 104.421 105.347 104.421 105.155 104.277Z" fill="#EFE1EE" />
    <path d="M107.213 105.521L106.352 105.043C106.16 104.899 106.16 104.708 106.399 104.564L107.404 103.99C107.643 103.847 108.026 103.847 108.218 103.942L109.079 104.421C109.27 104.564 109.27 104.756 109.031 104.899L108.026 105.474C107.787 105.617 107.452 105.617 107.213 105.521Z" fill="#EFE1EE" />
    <path d="M109.27 106.67L108.409 106.191C108.218 106.048 108.218 105.856 108.457 105.713L109.462 105.139C109.701 104.995 110.084 104.995 110.275 105.091L111.136 105.569C111.328 105.713 111.328 105.904 111.088 106.048L110.084 106.622C109.844 106.813 109.509 106.813 109.27 106.67Z" fill="#EFE1EE" />
    <path d="M111.375 107.866L110.514 107.387C110.323 107.244 110.323 107.052 110.562 106.909L111.567 106.335C111.806 106.191 112.189 106.191 112.38 106.287L113.241 106.765C113.433 106.909 113.433 107.1 113.194 107.244L112.189 107.818C111.95 108.009 111.567 108.009 111.375 107.866Z" fill="#EFE1EE" />
    <path d="M113.433 109.062L112.572 108.584C112.38 108.44 112.38 108.249 112.619 108.105L113.624 107.531C113.864 107.388 114.246 107.388 114.438 107.483L115.299 107.962C115.49 108.105 115.49 108.297 115.251 108.44L114.246 109.014C114.007 109.206 113.672 109.206 113.433 109.062Z" fill="#EFE1EE" />
    <path d="M86.0167 96.1433L83.098 94.4687C82.9066 94.3251 82.9066 94.1338 83.1459 93.9902L84.1506 93.4161C84.3899 93.2725 84.7726 93.2725 84.964 93.3682L87.8827 95.0428C88.0741 95.1864 88.0741 95.3778 87.8348 95.5213L86.8301 96.0955C86.5908 96.239 86.2559 96.239 86.0167 96.1433Z" fill="#EFE1EE" />
    <path d="M88.1219 97.3395L87.2606 96.861C87.0692 96.7175 87.0692 96.5261 87.3085 96.3826L88.3132 95.8084C88.5525 95.6648 88.9353 95.6648 89.1266 95.7605L89.9879 96.239C90.1793 96.3825 90.1793 96.5739 89.94 96.7175L88.9353 97.2916C88.696 97.4352 88.3611 97.483 88.1219 97.3395Z" fill="#EFE1EE" />
    <path d="M90.2272 98.5358L89.366 98.0573C89.1746 97.9138 89.1746 97.7224 89.4138 97.5788L90.4186 97.0047C90.6578 96.8611 91.0406 96.8611 91.232 96.9568L92.0932 97.4353C92.2846 97.5788 92.2846 97.7702 92.0454 97.9138L91.0406 98.4879C90.7535 98.6315 90.4186 98.6793 90.2272 98.5358Z" fill="#EFE1EE" />
    <path d="M92.2846 99.7318L91.4233 99.2534C91.2319 99.1098 91.232 98.9184 91.4712 98.7749L92.476 98.2007C92.7152 98.0572 93.098 98.0572 93.2894 98.1529L94.1506 98.6313C94.342 98.7749 94.342 98.9663 94.1028 99.1098L93.098 99.684C92.8587 99.8275 92.476 99.8754 92.2846 99.7318Z" fill="#EFE1EE" />
    <path d="M94.342 100.928L93.4807 100.45C93.2893 100.306 93.2893 100.115 93.5286 99.9712L94.5333 99.397C94.7726 99.2535 95.1554 99.2535 95.3467 99.3492L96.208 99.8276C96.3994 99.9712 96.3994 100.163 96.1601 100.306L95.1553 100.88C94.9161 101.024 94.5812 101.072 94.342 100.928Z" fill="#EFE1EE" />
    <path d="M96.4472 102.124L95.5859 101.646C95.3946 101.502 95.3945 101.311 95.6338 101.167L96.6386 100.593C96.8778 100.45 97.2606 100.45 97.452 100.545L98.3132 101.024C98.5046 101.167 98.5046 101.359 98.2653 101.502L97.2606 102.076C97.0213 102.22 96.6386 102.268 96.4472 102.124Z" fill="#EFE1EE" />
    <path d="M98.5047 103.32L97.6434 102.842C97.452 102.698 97.452 102.507 97.6913 102.364L98.6961 101.789C98.9353 101.646 99.3181 101.646 99.5094 101.741L100.371 102.22C100.562 102.364 100.562 102.555 100.323 102.698L99.3181 103.273C99.0788 103.416 98.7439 103.464 98.5047 103.32Z" fill="#EFE1EE" />
    <path d="M100.562 104.516L99.7008 104.038C99.5094 103.894 99.5094 103.703 99.7487 103.56L100.753 102.985C100.993 102.842 101.375 102.842 101.567 102.938L102.428 103.416C102.619 103.56 102.619 103.751 102.38 103.894L101.375 104.469C101.136 104.612 100.801 104.66 100.562 104.516Z" fill="#EFE1EE" />
    <path d="M102.667 105.713L101.806 105.234C101.615 105.091 101.615 104.899 101.854 104.756L102.859 104.182C103.098 104.038 103.481 104.038 103.672 104.134L104.533 104.612C104.725 104.756 104.725 104.947 104.485 105.091L103.481 105.665C103.241 105.808 102.859 105.808 102.667 105.713Z" fill="#EFE1EE" />
    <path d="M104.725 106.909L103.864 106.43C103.672 106.287 103.672 106.095 103.911 105.952L104.916 105.378C105.155 105.234 105.538 105.234 105.73 105.33L106.591 105.808C106.782 105.952 106.782 106.143 106.543 106.287L105.538 106.861C105.299 107.005 104.964 107.052 104.725 106.909Z" fill="#EFE1EE" />
    <path d="M106.782 108.105L105.921 107.627C105.73 107.483 105.73 107.292 105.969 107.148L106.974 106.574C107.213 106.43 107.596 106.43 107.787 106.526L108.648 107.005C108.84 107.148 108.84 107.34 108.6 107.483L107.596 108.057C107.356 108.201 107.021 108.201 106.782 108.105Z" fill="#EFE1EE" />
    <path d="M108.887 109.301L108.026 108.823C107.835 108.679 107.835 108.488 108.074 108.344L109.079 107.77C109.318 107.627 109.701 107.627 109.892 107.722L110.753 108.201C110.945 108.344 110.945 108.536 110.706 108.679L109.701 109.253C109.462 109.397 109.079 109.397 108.887 109.301Z" fill="#EFE1EE" />
    <path d="M108.552 111.885L107.691 111.407C107.5 111.263 107.5 111.072 107.739 110.928L111.136 108.966C111.375 108.823 111.758 108.823 111.95 108.918L112.811 109.397C113.002 109.541 113.002 109.732 112.763 109.875L109.366 111.837C109.127 111.981 108.744 112.029 108.552 111.885Z" fill="#EFE1EE" />
    <path d="M81.5669 96.3348L80.7057 95.8564C80.5143 95.7128 80.5143 95.5215 80.7535 95.3779L81.7583 94.8037C81.9975 94.6602 82.3803 94.6602 82.5717 94.7559L83.4329 95.2344C83.6243 95.3779 83.6243 95.5693 83.3851 95.7128L82.3803 96.287C82.1411 96.4784 81.7583 96.4784 81.5669 96.3348Z" fill="#EFE1EE" />
    <path d="M83.6243 97.5311L82.7631 97.0527C82.5717 96.9091 82.5717 96.7177 82.8109 96.5742L83.8157 96C84.0549 95.8565 84.4377 95.8565 84.6291 95.9522L85.4903 96.4307C85.6817 96.5742 85.6817 96.7656 85.4425 96.9091L84.4377 97.4833C84.1985 97.6747 83.8635 97.6747 83.6243 97.5311Z" fill="#EFE1EE" />
    <path d="M85.7297 98.7272L84.8684 98.2487C84.677 98.1052 84.677 97.9138 84.9163 97.7702L85.921 97.1961C86.1603 97.0525 86.543 97.0525 86.7344 97.1482L87.5957 97.6267C87.7871 97.7702 87.7871 97.9616 87.5478 98.1052L86.543 98.6793C86.3038 98.8707 85.921 98.8707 85.7297 98.7272Z" fill="#EFE1EE" />
    <path d="M87.787 99.9235L86.9258 99.445C86.7344 99.3015 86.7344 99.1101 86.9736 98.9665L87.9784 98.3924C88.2176 98.2488 88.6004 98.2488 88.7918 98.3445L89.653 98.823C89.8444 98.9665 89.8444 99.1579 89.6052 99.3015L88.6004 99.8756C88.3612 100.067 87.9784 100.067 87.787 99.9235Z" fill="#EFE1EE" />
    <path d="M96.1123 104.708L88.9832 100.593C88.7918 100.45 88.7918 100.258 89.031 100.115L90.0358 99.5406C90.275 99.397 90.6578 99.397 90.8492 99.4927L97.9783 103.608C98.1697 103.751 98.1697 103.942 97.9305 104.086L96.9257 104.66C96.6865 104.852 96.3037 104.852 96.1123 104.708Z" fill="#EFE1EE" />
    <path d="M98.1697 105.904L97.3085 105.426C97.1171 105.282 97.1171 105.091 97.3563 104.947L98.3611 104.373C98.6003 104.23 98.9831 104.23 99.1745 104.325L100.036 104.804C100.227 104.947 100.227 105.139 99.9879 105.282L98.9831 105.856C98.7439 106 98.3611 106.048 98.1697 105.904Z" fill="#EFE1EE" />
    <path d="M100.227 107.1L99.3658 106.622C99.1745 106.478 99.1745 106.287 99.4137 106.144L100.418 105.569C100.658 105.426 101.04 105.426 101.232 105.522L102.093 106C102.284 106.144 102.284 106.335 102.045 106.478L101.04 107.053C100.801 107.196 100.418 107.244 100.227 107.1Z" fill="#EFE1EE" />
    <path d="M102.332 108.297L101.471 107.818C101.28 107.675 101.28 107.483 101.519 107.34L102.524 106.765C102.763 106.622 103.146 106.622 103.337 106.718L104.198 107.196C104.39 107.34 104.39 107.531 104.15 107.675L103.146 108.249C102.906 108.392 102.524 108.44 102.332 108.297Z" fill="#EFE1EE" />
    <path d="M104.39 109.493L103.529 109.014C103.337 108.871 103.337 108.679 103.576 108.536L104.581 107.962C104.82 107.818 105.203 107.818 105.395 107.914L106.256 108.392C106.447 108.536 106.447 108.727 106.208 108.871L105.203 109.445C104.964 109.588 104.581 109.636 104.39 109.493Z" fill="#EFE1EE" />
    <path d="M106.447 110.689L105.586 110.21C105.395 110.067 105.395 109.875 105.634 109.732L106.639 109.158C106.878 109.014 107.261 109.014 107.452 109.11L108.313 109.588C108.505 109.732 108.505 109.923 108.265 110.067L107.261 110.641C107.021 110.785 106.639 110.832 106.447 110.689Z" fill="#EFE1EE" />
    <path d="M103.385 77.7225L106.017 74.0383C105.299 72.9378 104.438 72.0766 103.481 71.5024C100.945 69.9713 98.7918 71.5024 98.744 74.9474C98.6962 78.3924 100.706 82.4115 103.289 83.9426C105.825 85.4737 107.883 83.9426 108.026 80.5933L103.385 77.7225Z" fill="white" />
    <path d="M107.5 73.6555L104.916 77.3397L109.557 80.2105C109.557 80.1627 109.557 80.1148 109.557 80.067C109.557 77.9139 108.744 75.5215 107.5 73.6555Z" fill="white" />
    <path d="M113.72 89.2058V76.9092L115.73 78.201V90.4498L113.72 89.2058Z" fill="white" />
    <path d="M116.352 90.8326V82.3159L118.409 83.5599V92.0766L116.352 90.8326Z" fill="white" />
    <path d="M110.993 87.5312V82.6509L113.002 83.9427V88.7753L110.993 87.5312Z" fill="white" />
    <path d="M105.06 117.579C105.06 117.244 105.251 116.861 105.538 116.718L108.648 114.947C108.744 114.899 108.888 114.852 108.983 114.899H109.031L110.61 115.809L106.926 118.871L105.347 117.962C105.299 117.962 105.299 117.914 105.251 117.914C105.108 117.818 105.06 117.722 105.06 117.579Z" fill="url(#paint78_linear)" />
    <path d="M107.117 118.823L110.227 117.053C110.514 116.909 110.706 116.526 110.706 116.191C110.706 115.856 110.466 115.713 110.227 115.856L107.117 117.627C106.83 117.77 106.639 118.153 106.639 118.488C106.639 118.823 106.878 118.966 107.117 118.823Z" fill="#1800C2" />
    <defs>
      <linearGradient id="paint0_linear" x1="116.613" y1="96.15" x2="73.666" y2="96.15" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BA83FF" />
        <stop offset="1" stopColor="#B0A4E2" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="324.16" y1="221.739" x2="-39.1657" y2="9.87228" gradientUnits="userSpaceOnUse">
        <stop stopColor="#9C74FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="89.9492" y1="43.8098" x2="84.5548" y2="43.8098" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3D00E7" />
        <stop offset="1" stopColor="#6031FF" />
      </linearGradient>
      <linearGradient id="paint3_linear" x1="154.435" y1="60.8824" x2="41.3042" y2="-21.2966" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint4_linear" x1="133.891" y1="45.6946" x2="47.9677" y2="-16.7206" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4500FF" />
        <stop offset="1" stopColor="#6A31FF" />
      </linearGradient>
      <linearGradient id="paint5_linear" x1="81.6525" y1="61.8209" x2="58.5492" y2="45.268" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A43DFF" />
        <stop offset="1" stopColor="#EAD6E2" />
      </linearGradient>
      <linearGradient id="paint6_linear" x1="115.688" y1="116.383" x2="46.2602" y2="106.56" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BF9FFF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint7_linear" x1="81.6038" y1="55.8886" x2="58.5004" y2="39.3355" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A43DFF" />
        <stop offset="1" stopColor="#EAD6E2" />
      </linearGradient>
      <linearGradient id="paint8_linear" x1="116.151" y1="111.123" x2="46.7226" y2="101.301" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BF9FFF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint9_linear" x1="81.6426" y1="49.9559" x2="58.5393" y2="33.4029" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A43DFF" />
        <stop offset="1" stopColor="#EAD6E2" />
      </linearGradient>
      <linearGradient id="paint10_linear" x1="115.679" y1="104.518" x2="46.2504" y2="94.6955" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BF9FFF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint11_linear" x1="81.6232" y1="44.0067" x2="58.5198" y2="27.4537" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A43DFF" />
        <stop offset="1" stopColor="#EAD6E2" />
      </linearGradient>
      <linearGradient id="paint12_linear" x1="116.17" y1="99.2415" x2="46.742" y2="89.4194" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BF9FFF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint13_linear" x1="81.6622" y1="38.0741" x2="58.5589" y2="21.5211" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A43DFF" />
        <stop offset="1" stopColor="#EAD6E2" />
      </linearGradient>
      <linearGradient id="paint14_linear" x1="115.698" y1="92.6357" x2="46.27" y2="82.8136" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BF9FFF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint15_linear" x1="81.6622" y1="32.1415" x2="58.5588" y2="15.5884" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A43DFF" />
        <stop offset="1" stopColor="#EAD6E2" />
      </linearGradient>
      <linearGradient id="paint16_linear" x1="116.814" y1="88.1734" x2="47.3862" y2="78.3513" gradientUnits="userSpaceOnUse">
        <stop stopColor="#BF9FFF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint17_linear" x1="87.5201" y1="8.79108" x2="84.4285" y2="8.79108" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3600F4" />
        <stop offset="1" stopColor="#330EE0" />
      </linearGradient>
      <linearGradient id="paint18_linear" x1="119.948" y1="34.5543" x2="82.564" y2="19.0613" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint19_linear" x1="109.861" y1="30.1397" x2="96.3704" y2="24.5489" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint20_linear" x1="108.436" y1="36.4196" x2="85.6417" y2="26.9731" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint21_linear" x1="115.741" y1="38.5589" x2="88.314" y2="27.1925" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint22_linear" x1="97.4359" y1="38.2353" x2="88.578" y2="34.5643" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint23_linear" x1="119.948" y1="45.7609" x2="82.5639" y2="30.268" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint24_linear" x1="109.861" y1="41.361" x2="96.3703" y2="35.7701" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint25_linear" x1="108.436" y1="47.6406" x2="85.6416" y2="38.1941" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint26_linear" x1="115.74" y1="49.7799" x2="88.3139" y2="38.4135" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint27_linear" x1="97.4358" y1="49.4565" x2="88.5779" y2="45.7855" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint28_linear" x1="119.947" y1="56.7825" x2="82.5638" y2="41.2896" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint29_linear" x1="109.861" y1="52.368" x2="96.3703" y2="46.7772" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint30_linear" x1="108.436" y1="58.6479" x2="85.6416" y2="49.2014" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint31_linear" x1="115.74" y1="60.7872" x2="88.3139" y2="49.4208" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint32_linear" x1="97.4358" y1="60.4635" x2="88.578" y2="56.7926" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint33_linear" x1="119.947" y1="67.9892" x2="82.5639" y2="52.4962" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint34_linear" x1="46.1764" y1="75.0031" x2="42.8706" y2="75.0031" gradientUnits="userSpaceOnUse">
        <stop stopColor="#867CE2" />
        <stop offset="1" stopColor="#B590FF" />
      </linearGradient>
      <linearGradient id="paint35_linear" x1="92.3602" y1="92.4416" x2="24.727" y2="43.3124" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint36_linear" x1="56.183" y1="84.8078" x2="57.9708" y2="83.205" gradientUnits="userSpaceOnUse">
        <stop stopColor="#796DE8" />
        <stop offset="1" stopColor="#554FC6" />
      </linearGradient>
      <linearGradient id="paint37_linear" x1="55.8699" y1="78.7946" x2="57.6292" y2="77.2173" gradientUnits="userSpaceOnUse">
        <stop stopColor="#796DE8" />
        <stop offset="1" stopColor="#554FC6" />
      </linearGradient>
      <linearGradient id="paint38_linear" x1="132.986" y1="63.1877" x2="129.68" y2="63.1877" gradientUnits="userSpaceOnUse">
        <stop stopColor="#867CE2" />
        <stop offset="1" stopColor="#B590FF" />
      </linearGradient>
      <linearGradient id="paint39_linear" x1="179.17" y1="72.4234" x2="111.537" y2="23.2942" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint40_linear" x1="146.982" y1="81.6667" x2="149.469" y2="79.4368" gradientUnits="userSpaceOnUse">
        <stop stopColor="#796DE8" />
        <stop offset="1" stopColor="#554FC6" />
      </linearGradient>
      <linearGradient id="paint41_linear" x1="146.546" y1="73.3009" x2="148.994" y2="71.1065" gradientUnits="userSpaceOnUse">
        <stop stopColor="#796DE8" />
        <stop offset="1" stopColor="#554FC6" />
      </linearGradient>
      <linearGradient id="paint42_linear" x1="61.3468" y1="136.96" x2="56.7121" y2="136.96" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3D00E7" />
        <stop offset="1" stopColor="#6031FF" />
      </linearGradient>
      <linearGradient id="paint43_linear" x1="116.752" y1="157.221" x2="19.5524" y2="86.6147" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint44_linear" x1="99.0997" y1="144.172" x2="25.2768" y2="90.5464" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4500FF" />
        <stop offset="1" stopColor="#6A31FF" />
      </linearGradient>
      <linearGradient id="paint45_linear" x1="54.2095" y1="147.833" x2="34.3597" y2="133.611" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A43DFF" />
        <stop offset="1" stopColor="#EAD6E2" />
      </linearGradient>
      <linearGradient id="paint46_linear" x1="83.4522" y1="194.711" x2="23.8013" y2="186.272" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint47_linear" x1="54.1928" y1="142.722" x2="34.343" y2="128.5" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A43DFF" />
        <stop offset="1" stopColor="#EAD6E2" />
      </linearGradient>
      <linearGradient id="paint48_linear" x1="83.8745" y1="190.178" x2="24.2237" y2="181.739" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint49_linear" x1="54.2261" y1="137.625" x2="34.3763" y2="123.403" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A43DFF" />
        <stop offset="1" stopColor="#EAD6E2" />
      </linearGradient>
      <linearGradient id="paint50_linear" x1="83.469" y1="184.503" x2="23.8181" y2="176.064" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint51_linear" x1="54.2261" y1="132.528" x2="34.3763" y2="118.306" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A43DFF" />
        <stop offset="1" stopColor="#EAD6E2" />
      </linearGradient>
      <linearGradient id="paint52_linear" x1="84.4281" y1="180.669" x2="24.7771" y2="172.23" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint53_linear" x1="59.2591" y1="112.465" x2="56.6029" y2="112.465" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3600F4" />
        <stop offset="1" stopColor="#330EE0" />
      </linearGradient>
      <linearGradient id="paint54_linear" x1="87.1202" y1="134.601" x2="55.001" y2="121.289" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint55_linear" x1="78.4536" y1="130.808" x2="66.863" y2="126.004" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint56_linear" x1="77.2293" y1="136.203" x2="57.6453" y2="128.087" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint57_linear" x1="83.5055" y1="138.041" x2="59.9412" y2="128.275" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint58_linear" x1="67.7785" y1="137.763" x2="60.1681" y2="134.609" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint59_linear" x1="87.1199" y1="144.229" x2="55.001" y2="130.918" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint60_linear" x1="78.4535" y1="140.449" x2="66.8629" y2="135.645" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint61_linear" x1="77.2293" y1="145.844" x2="57.6452" y2="137.728" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint62_linear" x1="83.5053" y1="147.682" x2="59.9411" y2="137.916" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint63_linear" x1="67.7785" y1="147.404" x2="60.168" y2="144.25" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint64_linear" x1="87.12" y1="153.699" x2="55.0008" y2="140.387" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#13FFE2" />
      </linearGradient>
      <linearGradient id="paint65_linear" x1="81.2968" y1="157.423" x2="79.5405" y2="157.423" gradientUnits="userSpaceOnUse">
        <stop stopColor="#867CE2" />
        <stop offset="1" stopColor="#B590FF" />
      </linearGradient>
      <linearGradient id="paint66_linear" x1="105.833" y1="166.688" x2="69.9015" y2="140.587" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint67_linear" x1="112.667" y1="85.5175" x2="110.871" y2="81.7184" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3D00E7" />
        <stop offset="1" stopColor="#6031FF" />
      </linearGradient>
      <linearGradient id="paint68_linear" x1="113.42" y1="90.2137" x2="89.0067" y2="38.3342" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1800C2" />
        <stop offset="1" stopColor="#240ED6" />
      </linearGradient>
      <linearGradient id="paint69_linear" x1="225.635" y1="263.887" x2="26.3945" y2="101.535" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint70_linear" x1="111.71" y1="70.9481" x2="107.348" y2="67.3938" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint71_linear" x1="101.893" y1="104.17" x2="99.3448" y2="104.17" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1800C2" />
        <stop offset="1" stopColor="#240ED6" />
      </linearGradient>
      <linearGradient id="paint72_linear" x1="98.673" y1="86.9637" x2="98.1436" y2="120.12" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3D00E7" />
        <stop offset="1" stopColor="#6031FF" />
      </linearGradient>
      <linearGradient id="paint73_linear" x1="82.6379" y1="150.129" x2="88.0695" y2="2.96832" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint74_linear" x1="82.2411" y1="125.445" x2="84.2304" y2="71.5484" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint75_linear" x1="91.5528" y1="97.5605" x2="92.8104" y2="97.5102" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1800C2" />
        <stop offset="1" stopColor="#240ED6" />
      </linearGradient>
      <linearGradient id="paint76_linear" x1="87.6064" y1="105.404" x2="88.4784" y2="105.404" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1800C2" />
        <stop offset="1" stopColor="#240ED6" />
      </linearGradient>
      <linearGradient id="paint77_linear" x1="77.1439" y1="134.521" x2="78.5086" y2="101.494" gradientUnits="userSpaceOnUse">
        <stop stopColor="#6318FF" />
        <stop offset="1" stopColor="#FFF6E2" />
      </linearGradient>
      <linearGradient id="paint78_linear" x1="106.474" y1="118.522" x2="107.238" y2="117.375" gradientUnits="userSpaceOnUse">
        <stop stopColor="#3D00E7" />
        <stop offset="1" stopColor="#6031FF" />
      </linearGradient>
    </defs>
  </svg>

);