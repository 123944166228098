import React from 'react';
import { useDispatch } from 'react-redux';
import { setCartState } from 'store/actions';
import CartFooter from './CartFooter';
import { Diamond } from 'components/Parts/Parts';
import { CloseCross } from 'svg/svg';

const CartDT = ({ items }) => {
  const dispatch = useDispatch();

  let header = 'Review your cart';
  let content = (
    <>
      <div className="cart__body">
        <div className="cart__content">
          <ul className="item-list">{items}</ul>
        </div>
      </div>
      <CartFooter />
    </>
  );

  if (!items.length) {
    header = 'Your cart is empty';
    content = null;
  };

  return (
    <div className="cart-dt">
      <div className="cart__inner">
        <div className="cart__header">
          <h4>{header}</h4>
          <div
            className="close-cross"
            onClick={() => dispatch(setCartState(false))}>
            {CloseCross}
          </div>
        </div>
        {content}
      </div>
      <Diamond />
    </div>
  );
};

export default CartDT;
