import React from 'react';
import { LogoIcon } from 'svg/svgHeader';
import { ButtonMain } from 'components/BlocksUI/Buttons/Buttons';
import { Link } from "react-router-dom";

const PageNotFound = () => {
    return (
        <div className="page-not-found">
            <div className="logo">{LogoIcon}</div>
            <p>The page you are looking for can’t be found.</p>
            <Link to="/">
                <ButtonMain
                    text={'Home'} />
            </Link>
        </div>
    )
}
export default PageNotFound