import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCloseCrossLeft, setCarouselData, setSidebarState } from 'store/actions';
import CarouselMenu from 'components/CarouselMenu';
import { onTouchEnd } from 'mod/PageTop/scrollProcess';
import MenuItems from 'mod/MainMenu/MenuItems';
import AccountMenu from 'pages/Account/modules/AccountMenu';
import CartFooter from './CartFooter';
import CartEmpty from './CartEmpty';

const CartMB = ({ items }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const content = React.createRef();

  const isSidebar = useSelector((state) => state.sidebarState);
  const carouselStyle = useSelector((state) => state.cartMenuState);

  useEffect(() => {
    async function checkSidebar() {
      if (isSidebar) await dispatch(setSidebarState(false));
      dispatch(setCloseCrossLeft(true));
    }
    checkSidebar();

    dispatch(setCarouselData(CartMenu));
    content.current.classList.add('page-top_hide')

    return () => {
      dispatch(setCloseCrossLeft(false));
      const currentPath = history.location.pathname;
      if (currentPath === '/log-in' || currentPath === '/sign-up') dispatch(setCarouselData(AccountMenu));
      else dispatch(setCarouselData(MenuItems));
    }
  }, []);

  return (
    <div className="cart-mob">
      <div
        className="cart__inner"
        onTouchEnd={onTouchEnd}>
        <div
          ref={content}
          className={`page-top ${carouselStyle}`}>
          <CarouselMenu />
        </div>
        <div className="cart__content">
          {(items.length) ?
            (<ul className="item-list">{items}</ul>) :
            <CartEmpty />
          }
        </div>
        <CartFooter />
      </div>
    </div>
  );
};

export default CartMB;

const CartMenu = React.forwardRef((props, ref) => (
  <nav>
    <ul className="main-menu__items" ref={ref}>
      <li>cart</li>
      <li>payment success</li>
    </ul>
  </nav>
));
