import React from 'react';
import { topSellers } from 'data/users/users';
import {
  VerifiedIcon,
  CommonIcon
} from 'svg/svgProductDetails';

const SellerInfo = () => {
  return (
    <div className="seller-info">
      <div className="seller-info__person">
        <div className="seller-img-holder">
          <div className="seller-img-holder__ava" style={{ backgroundImage: `url(${topSellers[0].img})` }} />
          <div className="seller-img-holder__chat">{CommonIcon}</div>
        </div>
        <div className="seller-rating-holder">
          <div className="seller-rating-holder__name">
            <span className="name">Nick Jones</span>
            <div className="verified">{VerifiedIcon}</div>
          </div>
          <div className="seller-rating-holder__stars" />
        </div>
      </div>
      <div className="seller-info__data">
        <ul className="seller-info__data-wrapper">
          <li className="seller-info__data-item">
            <span className="item__value">256</span>
            <span className="item__caption">Total sold</span>
          </li>
          <li className="seller-info__data-item">
            <span className="item__value">13</span>
            <span className="item__caption">This week sold</span>
          </li>
          <li className="seller-info__data-item">
            <span className="item__value">117</span>
            <span className="item__caption">Feedback</span>
          </li>
          <li className="seller-info__data-item">
            <span className="item__value">4.83</span>
            <span className="item__caption">Rating</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SellerInfo;

