import React from 'react';

export const FacebookIcon = (
  <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.66169 0.00332907L6.50301 0C4.07779 0 2.51051 1.54551 2.51051 3.93762V5.75313H0.340044C0.15249 5.75313 0.000610352 5.89928 0.000610352 6.07954V8.71001C0.000610352 8.89027 0.152663 9.03626 0.340044 9.03626H2.51051V15.6737C2.51051 15.854 2.66238 16 2.84994 16H5.68179C5.86934 16 6.02122 15.8539 6.02122 15.6737V9.03626H8.559C8.74656 9.03626 8.89843 8.89027 8.89843 8.71001L8.89948 6.07954C8.89948 5.99299 8.86363 5.91009 8.80007 5.84884C8.73652 5.78758 8.64992 5.75313 8.55987 5.75313H6.02122V4.2141C6.02122 3.47438 6.20462 3.09886 7.20715 3.09886L8.66135 3.09837C8.84874 3.09837 9.00061 2.95221 9.00061 2.77212V0.329578C9.00061 0.149642 8.8489 0.00366197 8.66169 0.00332907Z" fill="white" />
  </svg>
);

export const TwitterIcon = (
  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.0006 1.89415C19.2569 2.21538 18.4644 2.42831 17.6381 2.53169C18.4881 2.032 19.1369 1.24677 19.4419 0.300308C18.6494 0.765538 17.7744 1.09415 16.8419 1.27754C16.0894 0.488615 15.0169 0 13.8469 0C11.5769 0 9.74936 1.81415 9.74936 4.03815C9.74936 4.35815 9.77686 4.66585 9.84436 4.95877C6.43561 4.79508 3.41936 3.18646 1.39311 0.736C1.03936 1.34031 0.83186 2.032 0.83186 2.77662C0.83186 4.17477 1.56311 5.41415 2.65311 6.13169C1.99436 6.11938 1.34811 5.93108 0.80061 5.63446C0.80061 5.64677 0.80061 5.66277 0.80061 5.67877C0.80061 7.64062 2.22186 9.27015 4.08561 9.64554C3.75186 9.73538 3.38811 9.77846 3.01061 9.77846C2.74811 9.77846 2.48311 9.76369 2.23436 9.70954C2.76561 11.3083 4.27311 12.4837 6.06561 12.5218C4.67061 13.5963 2.89936 14.2437 0.98186 14.2437C0.64561 14.2437 0.32311 14.2289 0.000610352 14.1883C1.81686 15.3415 3.96936 16 6.29061 16C13.8356 16 17.9606 9.84615 17.9606 4.512C17.9606 4.33354 17.9544 4.16123 17.9456 3.99015C18.7594 3.42154 19.4431 2.71138 20.0006 1.89415Z" fill="white" />
  </svg>
);

export const GoogleIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.3493 7.06064C15.3493 6.89271 15.2119 6.75659 15.0423 6.75659H8.49359C8.32405 6.75659 8.18662 6.89272 8.18662 7.06064V8.81739C8.18662 8.9853 8.32405 9.12144 8.49359 9.12144H12.1384C12.3458 9.12144 12.4932 9.32092 12.415 9.51108C11.6537 11.3632 9.81843 12.6687 7.67493 12.6687C4.84926 12.6687 2.55873 10.3998 2.55873 7.60119C2.55873 4.80243 4.84934 2.53372 7.67493 2.53372C8.98212 2.53372 10.1746 3.0194 11.0789 3.81836C11.2096 3.93388 11.4114 3.93097 11.5301 3.80326L12.8439 2.3888C12.9561 2.26796 12.9517 2.08019 12.8289 1.96987C11.4667 0.745991 9.65895 3.57464e-10 7.67493 3.57464e-10C3.43653 -4.02743e-05 0.000610352 3.40316 0.000610352 7.60119C0.000610352 11.7992 3.43653 15.2025 7.67497 15.2025C11.3769 15.2025 14.6195 12.606 15.3431 9.15149C15.3473 9.13159 15.3494 9.11124 15.3494 9.09092L15.3493 7.06064Z" fill="white" />
  </svg>
);

export const TelegramIcon = (
  <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.5546 15.8233C14.8095 16.0057 15.138 16.0513 15.431 15.9393C15.7239 15.8265 15.9392 15.5737 16.0041 15.2673C16.692 12.0001 18.3608 3.73052 18.987 0.758519C19.0345 0.534519 18.9554 0.301719 18.7812 0.152119C18.607 0.00251863 18.3656 -0.0406815 18.1503 0.0401186C14.8309 1.28172 4.60832 5.15772 0.429999 6.72013C0.164797 6.81933 -0.00778282 7.07693 0.000925312 7.35933C0.0104251 7.64253 0.198837 7.88813 0.470373 7.97053C2.34421 8.53693 4.80386 9.32493 4.80386 9.32493C4.80386 9.32493 5.95333 12.8329 6.55261 14.6169C6.62782 14.8409 6.80119 15.0169 7.02997 15.0777C7.25797 15.1377 7.5018 15.0745 7.672 14.9121C8.63465 13.9937 10.1229 12.5737 10.1229 12.5737C10.1229 12.5737 12.9507 14.6689 14.5546 15.8233ZM5.83854 8.88173L7.16772 13.3121L7.46301 10.5065C7.46301 10.5065 12.5984 5.82573 15.5259 3.15772C15.6114 3.07932 15.6233 2.94812 15.5521 2.85612C15.4816 2.76412 15.3518 2.74252 15.2544 2.80492C11.8614 4.99452 5.83854 8.88173 5.83854 8.88173Z" fill="white" />
  </svg>
);

export const RedditIcon = (
  <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.42 10.2634C10.6878 10.2634 10.0907 9.67239 10.0907 8.94732C10.0907 8.22235 10.6878 7.63124 11.42 7.63124C12.1523 7.63124 12.7494 8.22235 12.7494 8.94732C12.7494 9.67239 12.1523 10.2634 11.42 10.2634ZM11.6548 12.4233C10.7465 13.32 9.00894 13.3908 8.49876 13.3908C7.98846 13.3908 6.24841 13.32 5.34259 12.4233C5.2075 12.2895 5.2075 12.0747 5.34259 11.9408C5.47789 11.8069 5.69475 11.8069 5.83005 11.9408C6.40146 12.5067 7.62365 12.7088 8.49876 12.7088C9.37137 12.7088 10.5959 12.5067 11.1676 11.9408C11.3027 11.8069 11.5195 11.8069 11.6548 11.9408C11.7875 12.0747 11.7875 12.2895 11.6548 12.4233ZM4.24294 8.94742C4.24294 8.22245 4.83998 7.63134 5.57231 7.63134C6.30455 7.63134 6.90158 8.22245 6.90158 8.94742C6.90158 9.67239 6.30455 10.2636 5.57231 10.2636C4.83998 10.2634 4.24294 9.67239 4.24294 8.94742ZM17 7.63134C17 6.61328 16.1683 5.78979 15.14 5.78979C14.6374 5.78979 14.1831 5.98673 13.849 6.30766C12.5784 5.39829 10.8257 4.81221 8.87374 4.744L9.72084 0.795648L12.4891 1.37926C12.5224 2.07649 13.0988 2.63227 13.8108 2.63227C14.5456 2.63227 15.1401 2.04362 15.1401 1.31608C15.1401 0.588646 14.5456 0 13.8108 0C13.2877 0 12.8412 0.30059 12.6243 0.735037L9.53199 0.0833145C9.44525 0.0656448 9.35602 0.0809517 9.28193 0.128824C9.20806 0.176799 9.1569 0.250046 9.13916 0.335929C9.13916 0.335929 8.21041 4.71626 8.21041 4.74153C6.22289 4.79197 4.43687 5.38062 3.14589 6.30262C2.81168 5.98436 2.36002 5.78979 1.86 5.78979C0.831738 5.78979 0 6.61575 0 7.63134C0 8.37901 0.451563 9.02324 1.09965 9.31109C1.07163 9.49313 1.05627 9.67989 1.05627 9.86943C1.05627 12.7037 4.38842 15 8.49876 15C12.609 15 15.9411 12.7037 15.9411 9.86943C15.9411 9.68246 15.9259 9.49806 15.8979 9.31623C16.5434 9.02827 17 8.38415 17 7.63134Z" fill="white" />
  </svg>
);

export const YoutubeIcon = (
  <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.18215 9.29276L11.8735 6.50047L7.18215 3.70817V9.29276ZM17.6205 2.00673C17.7332 2.44326 17.817 3.0315 17.8729 3.77332C17.9297 4.51514 17.9585 5.16202 17.9585 5.7149L18 6.50047C18 8.53698 17.8738 10.0346 17.6196 10.9942C17.3942 11.8375 16.8731 12.3764 16.0564 12.6091C15.6336 12.7254 14.8385 12.812 13.6693 12.8697C12.5925 12.9258 11.5146 12.9553 10.4365 12.9581L8.99955 13C5.22412 13 2.87214 12.8697 1.94271 12.6072C1.12596 12.3745 0.604898 11.8365 0.379526 10.9933C0.265939 10.5567 0.181199 9.9685 0.125307 9.22668C0.0729299 8.58085 0.0446625 7.93318 0.040567 7.2851L0 6.50047C0 4.46395 0.126208 2.96635 0.379526 2.00673C0.604898 1.16346 1.12686 0.625475 1.94361 0.392783C2.3664 0.276438 3.16152 0.188015 4.33075 0.130307C5.4075 0.0748102 6.48539 0.0459483 7.56348 0.0437464L8.99955 0C12.775 0 15.127 0.130307 16.0564 0.391852C16.8731 0.624544 17.3951 1.16346 17.6205 2.00673Z" fill="white" />
  </svg>
);