import React from 'react';

export const ManufacturerIcon = (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2983 6.92358V13.7305C13.2983 13.9713 13.1029 14.1684 12.864 14.1684H2.41522C2.17638 14.1684 1.98096 13.9713 1.98096 13.7305V6.92358" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" />
    <path d="M12.3774 2.74023L13.9998 5.88795C14.3289 6.52862 14.0141 7.0528 13.2976 7.0528H11.2917C10.576 7.0528 9.88642 6.47169 9.75831 5.76096L9.24023 2.86723" stroke="white" strokeWidth="0.5" strokeMiterlimit="10" />
    <path d="M9.2395 2.86719L9.75758 5.7609C9.88569 6.47163 9.40452 7.05274 8.68799 7.05274H6.68169C5.96603 7.05274 5.47183 6.469 5.58301 5.75652L6.03377 2.87244" stroke="white" strokeWidth="0.5" strokeMiterlimit="10" />
    <path d="M6.04088 2.83031L5.48199 6.40369C5.42597 6.76015 5.08724 7.05223 4.72898 7.05223H1.42294C1.06467 7.05223 0.901819 6.78773 1.0612 6.46368L2.90551 2.71338" stroke="white" strokeWidth="0.5" strokeMiterlimit="10" />
    <mask id="path-5-inside-1" fill="white">
      <path d="M12.1454 0.879075V2.55759H3.10711V0.879075H12.1454ZM12.1454 0.441162H3.10711C2.86827 0.441162 2.67285 0.638223 2.67285 0.879075V2.55759C2.67285 2.79845 2.86827 2.99551 3.10711 2.99551H12.1454C12.3843 2.99551 12.5797 2.79845 12.5797 2.55759V0.879075C12.5797 0.638223 12.3838 0.441162 12.1454 0.441162Z" />
    </mask>
    <path d="M12.1454 0.879075H12.8454V0.179075H12.1454V0.879075ZM12.1454 2.55759V3.25759H12.8454V2.55759H12.1454ZM3.10711 2.55759H2.40711V3.25759H3.10711V2.55759ZM3.10711 0.879075V0.179075H2.40711V0.879075H3.10711ZM11.4454 0.879075V2.55759H12.8454V0.879075H11.4454ZM12.1454 1.85759H3.10711V3.25759H12.1454V1.85759ZM3.80711 2.55759V0.879075H2.40711V2.55759H3.80711ZM3.10711 1.57907H12.1454V0.179075H3.10711V1.57907ZM12.1454 -0.258838H3.10711V1.14116H12.1454V-0.258838ZM3.10711 -0.258838C2.47616 -0.258838 1.97285 0.257158 1.97285 0.879075H3.37285C3.37285 1.01929 3.26038 1.14116 3.10711 1.14116V-0.258838ZM1.97285 0.879075V2.55759H3.37285V0.879075H1.97285ZM1.97285 2.55759C1.97285 3.17951 2.47616 3.69551 3.10711 3.69551V2.29551C3.26038 2.29551 3.37285 2.41738 3.37285 2.55759H1.97285ZM3.10711 3.69551H12.1454V2.29551H3.10711V3.69551ZM12.1454 3.69551C12.7764 3.69551 13.2797 3.17951 13.2797 2.55759H11.8797C11.8797 2.41738 11.9922 2.29551 12.1454 2.29551V3.69551ZM13.2797 2.55759V0.879075H11.8797V2.55759H13.2797ZM13.2797 0.879075C13.2797 0.256701 12.7755 -0.258838 12.1454 -0.258838V1.14116C11.9922 1.14116 11.8797 1.01974 11.8797 0.879075H13.2797Z" fill="white" mask="url(#path-5-inside-1)" />
    <path d="M9.57677 8.62883V14.0033H5.70184V8.62883H9.57677ZM9.57677 8.19092H5.70184C5.463 8.19092 5.26758 8.38798 5.26758 8.62883V14.0033C5.26758 14.2442 5.463 14.4412 5.70184 14.4412H9.57677C9.81562 14.4412 10.011 14.2442 10.011 14.0033V8.62883C10.011 8.38798 9.81518 8.19092 9.57677 8.19092Z" fill="white" stroke="white" strokeWidth="0.2" />
  </svg>
);

export const AlgorithmIcon = (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.248 4.06787L14.1516 7.00109L11.248 9.93091" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3.90606 4.06787L1 7.00109L3.90606 9.93091" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.31777 0.424316L6.15674 13.5758" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const EfficiencyIcon = (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 10.1646V13.4957" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.33301 8.06689V13.4955" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.66553 5.83081V13.4959" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14 1.23218V13.4956" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.25635 6.45988C1.25635 6.45988 7.04137 7.79441 9.07919 1.23218" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.49951 1.37102L9.27573 0.466797L10.0828 2.46812" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const FansIcon = (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.84131 2.26392L7.58856 7.0116" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.78857 3.90029L4.3493 3.77344" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.47682 1.21289L4.34912 3.77319" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.0593 11.4833L7.61914 7.04102" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.1114 9.84692L10.5503 9.97462" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.4224 12.5341L10.5501 9.97461" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.0593 2.26562L7.61914 6.70531" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.4224 1.21289L10.5501 3.77319" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.1114 3.90029L10.5503 3.77344" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.84131 11.4833L7.28099 7.04102" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.47682 12.5341L4.34912 9.97461" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.78857 9.84692L4.3493 9.97462" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.44971 0.424316V13.3221" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.8995 6.87402H1" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const HashIcon = (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.46875 0.46875V14.4188H14.4692" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.39944 8.75049H2.04004V12.765H4.39944V8.75049Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.99026 1.75488H6.63086V12.7645H8.99026V1.75488Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5806 4.73486H11.2222V12.7644H13.5806V4.73486Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const HumidityIcon = (
  <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1216 0.466797C9.83933 0.466797 8.70449 1.0893 7.99942 2.04396C7.29294 1.0893 6.16044 0.466797 4.87721 0.466797C2.73539 0.466797 1 2.1954 1 4.32678C1 7.84345 4.19267 9.52142 7.99942 13.1914C11.9093 9.22922 14.9998 7.30921 14.9998 4.32678C14.9998 2.19494 13.2634 0.466797 11.1216 0.466797Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" />
    <path d="M15 7.61344H10.8684L9.39203 2.03833L8.15826 10.4607L6.5022 4.25565L5.92918 7.61344H2.70898" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const InterfaceIcon = (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.57576 13.5758C11.2074 13.5758 14.1515 10.6318 14.1515 7.00007C14.1515 3.36839 11.2074 0.424316 7.57576 0.424316C3.94407 0.424316 1 3.36839 1 7.00007C1 10.6318 3.94407 13.5758 7.57576 13.5758Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.1427 7.00007C11.1427 10.6312 9.54582 13.5758 7.57564 13.5758C5.60419 13.5758 4.00732 10.6312 4.00732 7.00007C4.00732 3.36856 5.60419 0.424316 7.57564 0.424316C9.54625 0.424316 11.1427 3.36856 11.1427 7.00007Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.57568 0.717041V13.2818" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.1515 7H1" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.3194 3.81104H1.83008" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.3194 10.2053H1.83008" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const ModelIcon = (
  <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.14291 0.419434L8.66228 4.93001L13.2866 5.02689L9.60113 7.91205L10.9397 12.4826L7.14291 9.75511L3.34609 12.4826L4.6855 7.91205L1 5.02689L5.62353 4.93001L7.14291 0.419434Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const NoiseIcon = (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 13.4131H4.15624" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 11.2788H4.15624" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 9.1438H4.15624" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 7.00928H4.15624" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 4.87354H4.15624" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.92139 13.4131H9.07942" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.92139 11.2788H9.07942" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.92139 9.1438H9.07942" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.92139 7.00928H9.07942" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.92139 4.87354H9.07942" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.92139 2.66309H9.07942" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.92139 0.45166H9.07942" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.8457 13.4131H14.0001" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.8457 11.2788H14.0001" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.8457 9.1438H14.0001" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const PowerIcon = (
  <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.6356 5.8351C11.6356 2.85947 9.11264 0.424316 6.03378 0.424316C2.9528 0.424316 0.430658 2.85947 0.430658 5.8351C0.430658 5.8351 0.213802 12.5517 4.35721 13.3663C3.51937 11.5607 3.57294 8.19007 3.57294 8.19007C3.57294 5.72989 4.68121 3.72068 6.03378 3.72068C7.3885 3.72068 8.49678 5.72989 8.49678 8.19007C8.49678 8.19007 8.58893 11.5883 7.78707 13.3887C11.9909 12.757 11.6356 5.8351 11.6356 5.8351Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.31995 1.97686C6.31995 2.11861 6.19965 2.2457 6.03595 2.2457C5.87229 2.2457 5.75195 2.1186 5.75195 1.97686C5.75195 1.83512 5.87229 1.70801 6.03595 1.70801C6.19965 1.70801 6.31995 1.8351 6.31995 1.97686Z" fill="white" stroke="white" strokeWidth="0.5" />
    <path d="M3.60267 3.20085C3.60267 3.34345 3.48196 3.47055 3.31867 3.47055C3.15538 3.47055 3.03467 3.34345 3.03467 3.20085C3.03467 3.05826 3.15538 2.93115 3.31867 2.93115C3.48196 2.93115 3.60267 3.05826 3.60267 3.20085Z" fill="white" stroke="white" strokeWidth="0.5" />
    <path d="M2.53455 5.60198C2.53455 5.74324 2.41462 5.86998 2.2514 5.86998C2.0882 5.86998 1.96826 5.74324 1.96826 5.60198C1.96826 5.4607 2.08818 5.33398 2.2514 5.33398C2.41463 5.33398 2.53455 5.46069 2.53455 5.60198Z" fill="white" stroke="white" strokeWidth="0.5" />
    <path d="M2.53455 8.70745C2.53455 8.8494 2.41461 8.9763 2.2514 8.9763C2.0882 8.9763 1.96826 8.8494 1.96826 8.70745C1.96826 8.56661 2.08793 8.43945 2.2514 8.43945C2.41488 8.43945 2.53455 8.56661 2.53455 8.70745Z" fill="white" stroke="white" strokeWidth="0.5" />
    <path d="M9.0354 3.20085C9.0354 3.34363 8.9147 3.47055 8.75183 3.47055C8.58897 3.47055 8.46826 3.34363 8.46826 3.20085C8.46826 3.05807 8.58897 2.93115 8.75183 2.93115C8.9147 2.93115 9.0354 3.05807 9.0354 3.20085Z" fill="white" stroke="white" strokeWidth="0.5" />
    <path d="M9.82001 5.86998C9.6552 5.86998 9.53516 5.74238 9.53516 5.60198C9.53516 5.46159 9.6552 5.33398 9.82001 5.33398C9.98311 5.33398 10.104 5.46159 10.104 5.60198C10.104 5.74251 9.9834 5.86998 9.82001 5.86998Z" fill="white" stroke="white" strokeWidth="0.5" />
    <path d="M9.82001 8.9763C9.65574 8.9763 9.53516 8.84837 9.53516 8.70745C9.53516 8.56765 9.65548 8.43945 9.82001 8.43945C9.98284 8.43945 10.104 8.56765 10.104 8.70745C10.104 8.8485 9.98286 8.9763 9.82001 8.9763Z" fill="white" stroke="white" strokeWidth="0.5" />
  </svg>
);

export const ReleaseIcon = (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.57576 0.424316L8.98 2.28674L11.167 1.47941L11.3422 3.8051L13.6195 4.30783L12.5076 6.35862L14.1515 8.01359L12.1079 9.13783L12.5962 11.4181L10.2693 11.2595L9.44794 13.4426L7.57576 12.0511L5.70358 13.4426L4.88182 11.2595L2.55527 11.4181L3.04358 9.13783L1 8.01359L2.64394 6.35862L1.53242 4.30783L3.80891 3.8051L3.98455 1.47941L6.17152 2.28674L7.57576 0.424316Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M4.34521 8.43877V5.90308H4.702L5.51486 7.18599C5.70322 7.48338 5.84958 7.75023 5.96964 8.00944L5.97728 8.00605C5.94758 7.66708 5.93952 7.35908 5.93952 6.96411V5.90308H6.24837V8.43877H5.91789L5.1131 7.15205C4.93661 6.8695 4.76649 6.58059 4.63964 6.30568L4.62691 6.30908C4.64558 6.62896 4.65364 6.93398 4.65364 7.35483V8.43877H4.34521Z" fill="white" stroke="white" strokeWidth="0.2" />
    <path d="M8.13243 7.2498H7.14692V8.16362H8.24528V8.43853H6.81982V5.90283H8.1897V6.17774H7.14692V6.97871H8.13243V7.2498Z" fill="white" stroke="white" strokeWidth="0.2" />
    <path d="M9.1177 8.43877L8.47412 5.90308H8.81945L9.12109 7.18599C9.19576 7.50205 9.26321 7.81811 9.30861 8.06247H9.31624C9.35824 7.81005 9.4363 7.50883 9.52285 7.18259L9.86182 5.90308H10.2042L10.5126 7.18938C10.5839 7.49059 10.6518 7.7918 10.6895 8.05865H10.6972C10.7498 7.78035 10.8219 7.49865 10.9004 7.18302L11.2355 5.9035H11.5694L10.852 8.4392H10.5096L10.1889 7.11853C10.1104 6.79526 10.0578 6.54665 10.0239 6.29126H10.0162C9.97127 6.54283 9.91527 6.79144 9.82067 7.11853L9.45964 8.4392H9.1177V8.43877Z" fill="white" stroke="white" strokeWidth="0.2" />
  </svg>
);

export const SizeIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.57374 6.53458L1.53418 1.49414" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.13574 1.05103C6.08471 1.07677 1 1 1 1L1.09077 6.09691" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.42725 9.46533L14.4668 14.5031" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.86523 14.946C9.91717 14.9221 15.0001 14.9988 15.0001 14.9988L14.9111 9.90283" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const TemperatureIcon = (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.11471 3.4151C6.11826 3.58536 5.98271 3.72717 5.80582 3.73078C5.62893 3.7362 5.48271 3.60117 5.4796 3.4291C5.47515 3.25975 5.61515 3.11839 5.79204 3.11433C5.96848 3.11072 6.11026 3.24485 6.11471 3.4151Z" fill="white" stroke="white" strokeWidth="0.2" />
    <path d="M7.58626 3.4151C7.58893 3.58536 7.45337 3.72717 7.27648 3.73078C7.10048 3.7362 6.95337 3.60117 6.95026 3.4291C6.94671 3.25975 7.08671 3.11839 7.26271 3.11433C7.43871 3.11072 7.58182 3.24485 7.58626 3.4151Z" fill="white" stroke="white" strokeWidth="0.2" />
    <path d="M6.8505 7.56084C6.85406 7.732 6.71495 7.87291 6.54073 7.87652C6.36206 7.88104 6.21673 7.74691 6.2145 7.57575C6.20917 7.40459 6.34917 7.26504 6.52517 7.26188C6.70206 7.25646 6.84695 7.39149 6.8505 7.56084Z" fill="white" stroke="white" strokeWidth="0.2" />
    <path d="M6.85086 9.23416C6.85441 9.40442 6.7153 9.54532 6.54108 9.54984C6.3633 9.55345 6.21708 9.41932 6.21397 9.24816C6.20953 9.0779 6.34953 8.937 6.52641 8.93248C6.70241 8.92797 6.8473 9.063 6.85086 9.23416Z" fill="white" stroke="white" strokeWidth="0.2" />
    <path d="M6.85086 10.9056C6.85441 11.0759 6.7153 11.2168 6.54019 11.2213C6.36241 11.2258 6.21708 11.0917 6.21397 10.9205C6.20953 10.7494 6.34953 10.6094 6.52641 10.6048C6.70153 10.6003 6.84641 10.7336 6.85086 10.9056Z" fill="white" stroke="white" strokeWidth="0.2" />
    <path d="M7.83517 5.57612C8.43162 5.17644 8.82406 4.51165 8.82406 3.75656C8.82406 2.53314 7.79695 1.54004 6.53162 1.54004C5.26628 1.54004 4.24097 2.53314 4.24097 3.75656C4.24097 4.51165 4.63252 5.17419 5.22806 5.57612C3.52497 6.10812 2.29297 7.65173 2.29297 9.4767C2.29297 11.7393 4.19075 13.5755 6.53162 13.5755C8.87251 13.5755 10.773 11.7393 10.773 9.4767C10.7725 7.65173 9.5374 6.10812 7.83517 5.57612Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M4.2041 1.54004H8.86011" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.72412 0.45166H7.34012" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.204 5.90264C4.204 5.90264 1.73422 4.79573 1 7.04567" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.86035 5.90264C8.86035 5.90264 11.3292 4.79573 12.0644 7.04567" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const VoltageIcon = (
  <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3195 5.80611C13.3195 6.82345 12.4682 7.65589 11.4286 7.65589H2.89182C1.85045 7.65589 1 6.82345 1 5.80611V2.29411C1 1.27767 1.85045 0.444336 2.89182 0.444336H11.4286C12.4682 0.444336 13.3195 1.27767 13.3195 2.29411V5.80611Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.3193 2.3999H15.0898V5.70035H13.3193" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.09912 1.85889V6.20955" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.17383 1.85889V6.20955" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const WeightIcon = (
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.1263 10.5174C4.30018 10.5174 5.25218 9.56626 5.25218 8.39111H1C1 9.56584 1.95158 10.5174 3.1263 10.5174Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 8.39114L3.1263 2.78394L5.25218 8.31393" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M11.7617 10.5174C12.9364 10.5174 13.888 9.56626 13.888 8.39111H9.63623C9.63623 9.56584 10.5878 10.5174 11.7617 10.5174Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.63623 8.39114L11.7617 2.78394L13.8876 8.31393" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.44434 0.424316V13.3476" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.7915 13.3477H9.09635" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.54346 2.92901L7.44424 1.4624" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.3439 2.92901L7.44434 1.4624" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const InfoIcon = (
  <svg width="75" height="17" viewBox="0 0 75 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.781738 15.4735L9.79386 6.21019L16.8036 11.2631L24.8147 3.68405L28.8201 10.4209L32.8255 1.99976L43.8405 4.52619L46.8449 12.947L53.8546 7.89447L61.8653 12.1049L73.9992 9.99975" stroke="#FFD700" strokeWidth="2" />
  </svg>
);

export const BtnLine = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.68812 9.71194C2.40471 9.71194 2.98562 9.13103 2.98562 8.41444C2.98562 7.69785 2.40471 7.11694 1.68812 7.11694C0.971535 7.11694 0.390625 7.69785 0.390625 8.41444C0.390625 9.13103 0.971535 9.71194 1.68812 9.71194Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.36684 15.481C7.08343 15.481 7.66434 14.9001 7.66434 14.1835C7.66434 13.4669 7.08343 12.886 6.36684 12.886C5.65025 12.886 5.06934 13.4669 5.06934 14.1835C5.06934 14.9001 5.65025 15.481 6.36684 15.481Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.43422 3.11355C10.1508 3.11355 10.7317 2.53264 10.7317 1.81605C10.7317 1.09947 10.1508 0.518555 9.43422 0.518555C8.71763 0.518555 8.13672 1.09947 8.13672 1.81605C8.13672 2.53264 8.71763 3.11355 9.43422 3.11355Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M14.3121 10.47C15.0287 10.47 15.6096 9.88909 15.6096 9.1725C15.6096 8.45591 15.0287 7.875 14.3121 7.875C13.5956 7.875 13.0146 8.45591 13.0146 9.1725C13.0146 9.88909 13.5956 10.47 14.3121 10.47Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.66504 9.71362L5.40654 13.3141" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.0127 12.8855L9.25819 3.11401" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.2954 3.11401L13.4554 8.20051" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const BtnChart = (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4998 8.27256H14.4998V13.7273H10.4998V8.27256Z" stroke="#C4C4C4" />
    <path d="M12.4998 17.4999V14.5V17.4999ZM12.4998 17.4999H11.9998V14.5L12.4998 17.4999ZM12.4998 17.4999V14.5L12.9998 17.4999H12.4998Z" stroke="#C4C4C4" />
    <path d="M4.49933 17.4999V15.4997V17.4999ZM4.49933 17.4999H3.99934V15.4997L4.49933 17.4999ZM4.49934 17.4999V15.4997L4.99933 17.4999H4.49934Z" stroke="#C4C4C4" />
    <path fillRule="evenodd" clipRule="evenodd" d="M1.99983 4.99996H5.99933V14.9997H1.99983V4.99996Z" stroke="#C4C4C4" />
    <path d="M4.49933 4.49996V2.49978V4.49996ZM4.49933 4.49996H3.99934V2.49978L4.49933 4.49996ZM4.49934 4.49996V2.49978L4.99933 4.49996H4.49934Z" stroke="#C4C4C4" />
    <path d="M12.4998 7.49987V2.49978V7.49987ZM12.4998 7.49987H11.9998V2.49978L12.4998 7.49987ZM12.4998 7.49987V2.49978L12.9998 7.49987H12.4998Z" stroke="#C4C4C4" />
  </svg>
);

export const VerifiedIcon = (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 0C2.23875 0 0 2.21051 0 4.93692C0 7.66333 2.23875 9.87384 5 9.87384C7.76125 9.87384 10 7.66333 10 4.93692C10 2.21051 7.76125 0 5 0ZM4.225 7.215L1.92438 4.94309L2.80813 4.07049L4.22469 5.4698L7.23 2.5024L8.11375 3.375L4.225 7.215Z" fill="white" />
  </svg>
);

export const StarsBlockIcon = (
  <svg width="66" height="15" viewBox="0 0 66 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.1011 12.4704C4.0738 12.4906 4.04165 12.5009 4.00996 12.5009C3.97683 12.5009 3.94369 12.4898 3.91591 12.4685C3.86085 12.4265 3.83697 12.3523 3.85645 12.2838L4.87936 8.72878L2.06553 6.48417C2.01095 6.44075 1.98853 6.36608 2.00998 6.29804C2.03045 6.22974 2.09087 6.18327 2.15862 6.18161L5.68882 6.10638L6.84865 2.59815C6.89349 2.46263 7.10644 2.46263 7.15128 2.59815L8.3116 6.10638L11.8418 6.18161C11.9095 6.18327 11.97 6.22974 11.9909 6.29804C12.0119 6.36608 11.99 6.44075 11.9349 6.48417L9.12106 8.72878L10.1435 12.2838C10.163 12.3523 10.1391 12.4265 10.084 12.4685C10.029 12.5105 9.95537 12.5116 9.89884 12.4704L6.99972 10.3492" fill="#FFFF66" />
    <path fillRule="evenodd" clipRule="evenodd" d="M17.101 12.4704C17.0738 12.4906 17.0416 12.5009 17.0099 12.5009C16.9768 12.5009 16.9436 12.4898 16.9159 12.4685C16.8608 12.4265 16.8369 12.3523 16.8564 12.2838L17.8793 8.72878L15.0655 6.48417C15.0109 6.44075 14.9885 6.36608 15.0099 6.29804C15.0309 6.22974 15.0908 6.18327 15.1586 6.18161L18.6888 6.10638L19.8486 2.59815C19.8939 2.46263 20.1064 2.46263 20.1512 2.59815L21.3116 6.10638L24.8418 6.18161C24.9095 6.18327 24.9699 6.22974 24.9909 6.29804C25.0118 6.36608 24.9894 6.44075 24.9348 6.48417L22.121 8.72878L23.1434 12.2838C23.1629 12.3523 23.139 12.4265 23.084 12.4685C23.0289 12.5105 22.9553 12.5116 22.8993 12.4704L19.9997 10.3492" fill="#FFFF66" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.101 12.4704C30.0737 12.4906 30.0415 12.5009 30.0094 12.5009C29.9767 12.5009 29.9441 12.4898 29.9153 12.4685C29.8608 12.4265 29.8369 12.3523 29.8568 12.2838L30.8793 8.72878L28.0654 6.48417C28.0108 6.44075 27.9889 6.36608 28.0099 6.29804C28.0308 6.22974 28.0908 6.18327 28.1585 6.18161L31.6887 6.10638L32.8485 2.59815C32.8939 2.46263 33.1063 2.46263 33.1512 2.59815L34.3115 6.10638L37.8417 6.18161C37.9099 6.18327 37.9699 6.22974 37.9908 6.29804C38.0118 6.36608 37.9894 6.44075 37.9353 6.48417L35.121 8.72878L36.1434 12.2838C36.1629 12.3523 36.1395 12.4265 36.0839 12.4685C36.0289 12.5105 35.9548 12.5116 35.8992 12.4704L32.9996 10.3492" fill="#FFFF66" />
    <path fillRule="evenodd" clipRule="evenodd" d="M43.1009 12.4704C43.0737 12.4906 43.0415 12.5009 43.0098 12.5009C42.9767 12.5009 42.944 12.4898 42.9158 12.4685C42.8607 12.4265 42.8368 12.3523 42.8568 12.2838L43.8792 8.72878L41.0654 6.48417C41.0108 6.44075 40.9889 6.36608 41.0098 6.29804C41.0308 6.22974 41.0907 6.18327 41.159 6.18161L44.6887 6.10638L45.849 2.59815C45.8938 2.46263 46.1063 2.46263 46.1511 2.59815L47.3115 6.10638L50.8417 6.18161C50.9099 6.18327 50.9698 6.22974 50.9908 6.29804C51.0117 6.36608 50.9893 6.44075 50.9352 6.48417L48.1214 8.72878L49.1433 12.2838C49.1633 12.3523 49.1394 12.4265 49.0844 12.4685C49.0288 12.5105 48.9552 12.5116 48.8992 12.4704L46.0001 10.3492" fill="#FFFF66" />
    <path fillRule="evenodd" clipRule="evenodd" d="M56.1009 12.4704C56.0736 12.4906 56.0419 12.5009 56.0098 12.5009C55.9766 12.5009 55.944 12.4898 55.9157 12.4685C55.8607 12.4265 55.8373 12.3523 55.8567 12.2838L56.8792 8.72878L54.0653 6.48417C54.0112 6.44075 53.9888 6.36608 54.0098 6.29804C54.0307 6.22974 54.0907 6.18327 54.1589 6.18161L57.6886 6.10638L58.8489 2.59815C58.8938 2.46263 59.1062 2.46263 59.1511 2.59815L60.3114 6.10638L63.8416 6.18161C63.9098 6.18327 63.9698 6.22974 63.9907 6.29804C64.0117 6.36608 63.9893 6.44075 63.9352 6.48417L61.1214 8.72878L62.1433 12.2838C62.1633 12.3523 62.1394 12.4265 62.0843 12.4685C62.0292 12.5105 61.9552 12.5116 61.8986 12.4704L59 10.3492" fill="#C4C4C4" />
  </svg>
);

export const CommonIcon = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#17182E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.64491 5C12.2905 5 14.4348 7.14393 14.4348 9.79022C14.4348 10.6761 14.1897 11.5031 13.7701 12.2153L15 15L12.1282 13.8794C11.4033 14.3206 10.555 14.5795 9.64491 14.5795C6.99928 14.5795 4.85469 12.4355 4.85469 9.78989C4.85469 7.1436 6.99928 5 9.64491 5Z" stroke="white" strokeWidth="0.7" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const SpecificIcon = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99951 3.18164V7.8757" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.23622 9.94275C7.23622 10.0744 7.12987 10.1805 6.99939 10.1805C6.86709 10.1805 6.76074 10.0735 6.76074 9.94275C6.76074 9.81136 6.8677 9.7041 6.99939 9.7041C7.12927 9.7041 7.23622 9.81045 7.23622 9.94275Z" fill="white" stroke="white" strokeWidth="0.5" />
    <path d="M13.6501 6.99998C13.6501 10.6727 10.6728 13.65 7.0001 13.65C3.32741 13.65 0.350098 10.6727 0.350098 6.99998C0.350098 3.32729 3.32741 0.349976 7.0001 0.349976C10.6728 0.349976 13.6501 3.32729 13.6501 6.99998Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export const ShippingIcon = (
  <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.64307 10.9775H9.43026" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" />
    <path d="M1 10.9775H3.36719" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12.7153 10.9775H13.1611C14.115 10.9775 14.8959 10.2049 14.8959 9.26013V6.08766H8.40933V0.464233H2.75293" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.10254 1.25867H11.4279L14.8675 6.08954" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M5.01063 12.3774C5.91829 12.3774 6.65404 11.6486 6.65404 10.7496C6.65404 9.85062 5.91829 9.12183 5.01063 9.12183C4.10298 9.12183 3.36719 9.85062 3.36719 10.7496C3.36719 11.6486 4.10298 12.3774 5.01063 12.3774Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M11.0717 12.3774C11.9793 12.3774 12.7151 11.6486 12.7151 10.7496C12.7151 9.85062 11.9793 9.12183 11.0717 9.12183C10.164 9.12183 9.42822 9.85062 9.42822 10.7496C9.42822 11.6486 10.164 12.3774 11.0717 12.3774Z" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinejoin="round" />
    <path d="M2.55322 2.67371H6.17668" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2 4H5.62345" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.11768 7.17871H4.74065" stroke="white" strokeWidth="0.7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

// export const CommonIcon = (

//     );

export const Close = (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.00024 16.9714C13.4027 16.9714 16.9717 13.4025 16.9717 8.99999C16.9717 4.5975 13.4027 1.02856 9.00024 1.02856C4.59774 1.02856 1.02881 4.5975 1.02881 8.99999C1.02881 13.4025 4.59774 16.9714 9.00024 16.9714Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.07129 7.07251L10.9264 10.9256" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10.9264 7.07251L7.07129 10.9256" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
