import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Select from 'components/Select';
import { ButtonMain } from 'components/BlocksUI/Buttons/Buttons';
import { BoxDecor } from 'components/Parts/BoxDecor';
import { addToCart } from 'mod/Cart/logic';
import { ProductStar, ProductHalfStar, ArrowBack } from 'svg/svg';
import SelectModal from '../../Select/SelectModal/SelectModal';

const ProductTileMobile = ({ item, itemHash, itemPrice, hashArr, itemPath }) => {
  const [price, setPrice] = useState(hashArr[0].price);
  const [hash, setHash] = useState(hashArr[0].h);

  const [activeItemId, setActiveItemId] = useState();
  const [isModal, setIsModal] = useState(false);
  const productItem = React.createRef();
  const itemInner = React.createRef();
  const orderBlock = React.createRef();
  const closeOrder = React.createRef();
  const productImg = React.createRef();
  const itemHeader = React.createRef();
  const pdoductParam_PSU = React.createRef();
  const openOrderArrow = React.createRef();

  const boxDecor = React.createRef();
  const selectRef = React.createRef();

  const onHashListClick = (el) => {
    setPrice(el.price);
    setHash(el.h);
    setActiveItemId(el.id)
    setIsModal(false)
    document.body.classList.remove('body-block')
  }

  const toggleOrder = (param) => {
    productImg.current.classList.toggle('item-img_hover-on');
    itemHeader.current.classList.toggle('d-none');
    pdoductParam_PSU.current.classList.toggle('d-none');
    openOrderArrow.current.classList.toggle('d-none');
    boxDecor.current.classList.toggle('d-none')
    productItem.current.classList.toggle('products__item_active');

    if (param) {
      const orderBlockWidth = orderBlock.current.clientWidth;

      itemInner.current.style.right = `${orderBlockWidth + 1}px`;
      closeOrder.current.style.display = 'block';
    } else {
      closeOrder.current.style = '';
      itemInner.current.style = '';
    }
  }

  if (item.title.length > 21) {
    item.title = item.title.slice(0, 23) + '...';
  };
  const root = document.getElementById('root');
  const onClickHandler = (event) => {
    const target = event.target;
    if (target.tagName === 'LI' ||
      target.closest('.close') || hashArr.length < 2) return;
    setIsModal(true)
    root.classList.add('stop-scroll-y');
    document.body.classList.add('body-block')
  }

  const closeModal = () => {
    setIsModal(false)
    root.classList.remove('stop-scroll-y');
    document.body.classList.remove('body-block')
  }

  const onDetailsHandler = () => {
    window.scrollTo(0, 0);
    toggleOrder(false)
  }

  const selectDiv = (
    <div className="select-wrapper" onClick={onClickHandler}>
      <Select
        closeModal={closeModal}
        isModal={isModal}
        activeItemId={activeItemId}
        item={item}
        displayHash={hash}
        hashArr={hashArr}
        hashOpt={item.hash.option}
        hashClick={onHashListClick}
        ref={selectRef} />
    </div>
  )
  return (
    <li className="products__item-wrapper products__item-wrapper_mobile">
      <div className="item-holder p-relative">
        <div
          className="products__item"
          ref={productItem}>
          <div
            className="products__item-inner"
            ref={itemInner}>
            <div className="products__item-show-block">
              <div className="products__item-header d-flex justify-content-between" ref={itemHeader}>
                <div className="products__item-header-text">
                  <h5 className="poducts__item-title main-font">{item.title}</h5>
                  <span className="main-font">{itemHash} {item.hash.option}</span>
                </div>
                <div className="products__item-star">
                  {(!item.star) ? '' : (item.star === 'full') ? ProductStar : ProductHalfStar}
                </div>
              </div>
              <div
                className="products__item-img"
                style={{ backgroundImage: `url("${item.img}")` }}
                ref={productImg}>
              </div>
              <div className="products__item-footer">
                <span className="item-price">{itemPrice}</span>
                <span className="item-psu main-font" ref={pdoductParam_PSU}>{item.psu && 'psu'}</span>
              </div>
              <div
                className="products__item-open-order"
                onClick={() => toggleOrder(true)}>
                <span ref={openOrderArrow}>{ArrowBack}</span>
              </div>
              <div
                className="products__item-close-order"
                onClick={() => toggleOrder(false)}
                ref={closeOrder}>
                {ArrowBack}
              </div>
            </div>
            <div className="order-mob" ref={orderBlock}>
              <div className="order-mob__price-shown">
                <span className="order-mob__price-symb">$</span>
                <span className="order-mob__price">{price}</span>

              </div>
              {isModal ?
                <SelectModal>
                  {selectDiv}
                </SelectModal>
                :
                selectDiv
              }
              <div className="order-mob__btns-wrapper">
                <ButtonMain style={{ marginBottom: '15px' }}>
                  <Link to={itemPath} onClick={onDetailsHandler} className="link-to">Details</Link>
                </ButtonMain>
                <ButtonMain text={'Add to cart'} func={() => addToCart(item)} />
              </div>
              <div className="corner-bottom-right" />
              <div className="corner-top-right" />
            </div>
          </div>
        </div>
        <BoxDecor ref={boxDecor} />
      </div>
    </li >
  );
};

export default ProductTileMobile;
