import React, { useEffect, useRef } from 'react';
import { setPreSelectItem } from 'mod/Cart/logic';
import { BoxDecor } from 'components/Parts/BoxDecor';
import { ArrowDots, CloseSelect } from 'svg/svg';

const Select = React.forwardRef(({ item, hashArr, hashOpt, expandFunc, hashClick, activeItemId, displayHash, isModal, closeModal }, refObj) => {
  const listRef = useRef()
  const wrapperRef = useRef()

  useEffect(() => {
    if (!activeItemId) listRef.current.firstChild.classList.add('active')
    if (hashArr.length < 3) wrapperRef.current.classList.add('min-items')
  }, [])

  const onHashClick = (hashElem) => {
    hashClick(hashElem);
    setPreSelectItem(item, hashElem, hashOpt);
  };

  const onClickHandler = () => {
    if (isModal) closeModal();
  }

  const addActive = (e) => {
    const parent = e.target.closest('.select__list');
    const target = e.target.closest('li')
    if (target.tagName === 'LI') {
      parent.childNodes.forEach(item => item.classList.remove('active'))
      target.classList.add('active');
    }
  }

  const hashList = hashArr.map((el) => {
    return (
      <li
        className={el.id === activeItemId ? 'active' : null}
        key={el.id}
        onClick={() => onHashClick(el)}>
        <span className="modal-hide">Hash: &nbsp;</span>{el.h} {hashOpt}
      </li>
    )
  });

  return (
    <div className="select" ref={refObj ? refObj.select : null}>
      <div className="dot">
        <div className="select__shown-area">
          <span className="main-font cursor-default">Hash<span className="modal-hide">: {displayHash} {hashOpt}</span></span>
          {(hashArr.length > 1) &&
            <div className="select__show-btn"
              ref={refObj ? refObj.arrow : null}
              onClick={expandFunc ? expandFunc : null}>
              <span
                className={isModal ? 'close' : 'arrow'}
                onClick={onClickHandler}>
                {isModal ? CloseSelect : ArrowDots}
              </span>
            </div>}
        </div>
        <BoxDecor />
      </div>
      <div className="select__wrapper" ref={wrapperRef}>
        <ul className="select__list main-font"
          onClick={addActive}
          ref={listRef}>
          {hashList}
        </ul>
        <BoxDecor />
      </div>
    </div>
  )
});

export default Select;