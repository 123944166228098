import React, { useEffect, useRef } from 'react';
import { BoxDecor } from 'components/Parts/BoxDecor';

const DropList = ({ contentArr, activeItemId }) => {
  const listRef = useRef()
  useEffect(() => {
    if (!activeItemId) listRef.current.firstChild.classList.add('active')
  }, [])

  const addActive = (e) => {
    const parent = e.target.closest('.drop-list__wrapper');
    const target = e.target.closest('li')
    if (target.tagName === 'LI') {
      parent.childNodes.forEach(item => item.classList.remove('active'))
      target.classList.add('active');
    }
  }

  const renderList = () => {
    const items = contentArr.map((el, idx) => {
      return (
        <li
          className={el.id === activeItemId ? 'active drop-list__item' : 'drop-list__item'}
          key={idx}
          onClick={el.func}>
          {el.text}
        </li>
      );
    });
    return items;
  };

  return (
    <div className="drop-list">
      <ul className="drop-list__inner main-font">
        <div className="drop-list__wrapper"
          ref={listRef}
          onClick={addActive}>
          {renderList()}
        </div>
        <BoxDecor />
      </ul>
    </div>
  );
};

export default DropList;